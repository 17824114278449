import React, { useState, useEffect, useRef } from "react";
import Proptypes from "prop-types";

import MappingTutorialPrompt from "./mapping_tutorial_prompt";
import ReviewSubmissionPrompt from "./review_submission_prompt";
import SubmissionTutorial from "./submission_tutorial";
import SubmissionTutorialIntro from "./submission_tutorial_intro";
import ColumnMappingTutorial from "./column_mapping_tutorial";

const Tutorials = ({
  setActiveTab,
  displaySubmissionTutorialIntro,
  setDisplaySubmissionTutorialIntro,
  mappings,
}) => {
  const [displayMappingTutorialPrompt, setDisplayMappingTutorialPrompt] = useState(false);
  const [displayReviewSubmissionPrompt, setDisplayReviewSubmissionPrompt] = useState(false);
  const [displaySubmissionTutorial, setDisplaySubmissionTutorial] = useState(false);
  const [displayMappingTutorial, setDisplayMappingTutorial] = useState(false);

  const initialMappings = useRef();

  useEffect(() => {
    function trackInitialMappings() {
      initialMappings.current = mappings;
    }

    function renderModalOnPageLoad() {
      if (initialMappings.current && !initialMappings.current.length) {
        setTimeout(() => setDisplayMappingTutorialPrompt(true), 700);
      }
    }

    trackInitialMappings();
    renderModalOnPageLoad();
  }, []);

  useEffect(() => {
    function showReviewSubmissionPrompt() {
      if (!initialMappings.current?.length && mappings.length) {
        setTimeout(() => setDisplayReviewSubmissionPrompt(true), 700);
      }
    }

    showReviewSubmissionPrompt();
  }, [mappings]);

  return (
    <>
      {displayMappingTutorialPrompt && (
        <MappingTutorialPrompt
          handleClose={() => setDisplayMappingTutorialPrompt(false)}
          goToMapping={() => {
            setActiveTab("mappings");
            setTimeout(() => setDisplayMappingTutorial(true), 700);
          }}
        />
      )}
      {displayMappingTutorial && (
        <ColumnMappingTutorial handleClose={() => setDisplayMappingTutorial(false)} />
      )}
      {displayReviewSubmissionPrompt && (
        <ReviewSubmissionPrompt
          handleClose={() => setDisplayReviewSubmissionPrompt(false)}
          goToMapping={() => {
            setActiveTab("submission");
            setTimeout(() => setDisplaySubmissionTutorial(true), 700);
          }}
        />
      )}
      {displaySubmissionTutorial && (
        <SubmissionTutorial handleClose={() => setDisplaySubmissionTutorial(false)} />
      )}
      {displaySubmissionTutorialIntro && (
        <SubmissionTutorialIntro
          handleClose={() => setDisplaySubmissionTutorialIntro(false)}
          handleConfirm={() => {
            setDisplaySubmissionTutorialIntro(false);
            setDisplaySubmissionTutorial(true);
          }}
        />
      )}
    </>
  );
};

export default Tutorials;
