import React from "react";
import useEffect from "react";
import { withStyles, makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { FormControl } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { truncate } from "lodash";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    margin: "8px 0 8px",
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const labelStyles = {
  fontFamily: "sans-serif",
  fontSize: "0.8rem",
  fontWeight: 400,
  color: "#757575",
  marginTop: "12px",
};

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "8px 0 8px",
  }),
  control: (base) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DEE7EE",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#ffffff",
    zIndex: 9999,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    backgroundColor: "#ffffff",
  }),
};

const CustomOutlinedInput = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3D71FF",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEE7EE",
    },
  },
  notchedOutline: {
    borderColor: "#DEE7EE",
  },
})(OutlinedInput);

const CustomInputLabel = withStyles({
  root: {
    "&.Mui-focused": {
      color: "#3D71FF",
    },
    "&.Mui-disabled": {
      color: "#DEE7EE",
    },
  },
})(InputLabel);

const CustomFormControl = withStyles({
  root: {
    borderRadius: 8,
    margin: "8px 0 8px",
    "&.Mui-disabled .MuiInputLabel-root": {
      color: "#DEE7EE",
    },
  },
})(FormControl);

const datePickerTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#ffffff",
        borderRadius: 8,
        "&:hover:not(.Mui-disabled) $notchedOutline": {
          borderColor: "#DEE7EE",
        },
        "&.Mui-focused $notchedOutline": {
          borderColor: "#3D71FF",
        },
      },
      notchedOutline: {
        borderColor: "#DEE7EE",
      },
    },
    MuiInputLabel: {
      root: {
        "&.Mui-focused": {
          color: "#3D71FF",
        },
      },
    },
    MuiPickersDay: {
      day: {
        "&:hover": {
          backgroundColor: "#DEE7EE",
        },
      },
      daySelected: {
        backgroundColor: "#3D71FF",
        "&:hover": {
          backgroundColor: "#3D71FF",
        },
      },
    },
  },
});

const SupportsForm = (props) => {
  const manufacturers = [
    { value: "AbbVie", label: "AbbVie" },
    { value: "Boehringer Ingelheim", label: "Boehringer Ingelheim" },
    { value: "GlaxoSmithKline", label: "GlaxoSmithKline" },
    { value: "Merck", label: "Merck" },
    { value: "Novartis", label: "Novartis" },
    { value: "Sanofi", label: "Sanofi" },
    { value: "UCB", label: "UCB" },
    { value: "Amgen", label: "Amgen" },
    { value: "Bristol Myers Squibb", label: "Bristol Myers Squibb" },
    { value: "Exelixis", label: "Exelixis" },
    { value: "Pfizer", label: "Pfizer" },
    { value: "Pharmacyclics", label: "Pharmacyclics" },
    { value: "Gilead", label: "Gilead" },
    { value: "Johnson & Johnson", label: "Johnson & Johnson" },
    { value: "Bausch", label: "Bausch" },
    { value: "Biogen", label: "Biogen" },
    { value: "Novo Nordisk", label: "Novo Nordisk" },
    { value: "Bayer", label: "Bayer" },
    { value: "Emd Serono", label: "Emd Serono" },
  ];

  const [type, setType] = React.useState("Data Variance");
  const [subInquiry, setReason] = React.useState("");
  const [selectedEntity, setSelectedEntity] = React.useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = React.useState(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [manufacturer, setManufacturer] = React.useState("");

  const handleStartDateChange = (date) => {
    validateDate(selectedStartDate, date);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    validateDate(selectedStartDate, date);
    setSelectedEndDate(date);
  };

  const validateDate = (start, end) => {
    if (start && end) {
      if (start > end) {
        setError(true);
        setErrorMessage("End date must be after start date");
      } else {
        setError(false);
        setErrorMessage("");
      }
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleSubInquiryChange = (event) => {
    setReason(event.target.value);
  };

  const handleManufacturerChange = (event) => {
    setManufacturer(event.target.value);
  };

  const loadOptions = (query) => fetchSearchTerm(query);
  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  const fetchSearchTerm = async (query) => {
    let url = new URL("/api/entities", window.location.origin);
    url.search = new URLSearchParams({
      query: query,
      fetch_all: true,
    }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  const fetchEntityDetails = (url) => {
    const results = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((response) => response.json())
      .then((data) => data);

    return results;
  };

  const handleSelectedEntity = (entitySelected) => {
    if (entitySelected) {
      var url = `/api/support_entities/${entitySelected.value}`;

      fetchEntityDetails(url).then((data) => {
        setSelectedEntity(data);
      });
    } else {
      setSelectedEntity(null);
    }
  };

  const renderEntitySelect = (required = true) => {
    return (
      <div className="row">
        <div className="col-12">
          <Typography variant="body1" style={labelStyles}>
            Covered Entity Details
          </Typography>
        </div>
        <div className="col-12">
          <AsyncSelect
            components={{ DropdownIndicator: null, IndicatorSeparator: null }}
            openMenuOnFocus={false}
            openMenuOnClick={false}
            defaultOptions={false}
            name="support[entity_id]"
            placeholder={"Search Covered Entity" + (required ? " *" : "")}
            label="Search Covered Entity *"
            styles={customSelectStyles}
            isClearable={true}
            loadOptions={(query) => debouncedLoadOptions(query)}
            onChange={(value) => handleSelectedEntity(value)}
            required={required}
          />
        </div>
      </div>
    );
  };

  const renderPharmacySelect = (required = true) => {
    return (
      <div className="col-6">
        {/* <CustomFormControl
            variant="outlined"
            fullWidth
            required={required}
            disabled={!selectedEntity}
          >
            <CustomInputLabel id="pharmacy-input-label">
              Pharmacy
            </CustomInputLabel>
            <Select
              disabled={!selectedEntity}
              labelId="pharmacy-input-label"
              input={
                <CustomOutlinedInput
                  label="Pharmacy"
                  id="pharmacy-input"
                  name="support[pharmacy_id]"
                />
              }
              styles={customSelectStyles}
              value={selectedPharmacy ?? ""}
              onChange={(event) => {
                setSelectedPharmacy(event.target.value);
              }}
            >
              {selectedEntity?.pharmacies?.details?.map((cp) => {
                return (
                  <MenuItem key={cp.value} value={cp.value}>
                    {cp.label}
                  </MenuItem>
                );
              })}
            </Select>
          </CustomFormControl> */}
        <ReactSelect
          isDisabled={!selectedEntity}
          id="pharmacy"
          label="Pharmacy"
          placeholder="Select Pharmacy"
          name="support[pharmacy_id]"
          value={selectedEntity ? selectedPharmacy ?? "" : ""}
          onChange={(value) => {
            setSelectedPharmacy(value);
          }}
          options={selectedEntity?.pharmacies?.details}
          styles={customSelectStyles}
        />
      </div>
    );
  };

  const renderPharmacyDEA = (required = true) => {
    return (
      <div className="col-6">
        <CustomTextField
          label="Pharmacy DEA"
          margin="normal"
          variant="outlined"
          id="pharmacy_dea"
          name="support[pharmacy_dea]"
          fullWidth
          required={required}
        />
      </div>
    );
  };

  const renderContactDetails = (required = true) => {
    return (
      <div className="row">
        <div className="col-12">
          <Typography variant="body1" style={labelStyles}>
            Contact Details
          </Typography>
        </div>
        <div className="col-4">
          <CustomTextField
            label="Contact Name"
            margin="normal"
            variant="outlined"
            id="contact_name"
            name="support[contact_name]"
            fullWidth
            required={required}
          />
        </div>
        <div className="col-4">
          <CustomTextField
            label="Contact Email"
            margin="normal"
            variant="outlined"
            id="contact_email"
            name="support[contact_email]"
            fullWidth
            required={required}
          />
        </div>
        <div className="col-4">
          <CustomTextField
            label="Contact Phone"
            margin="normal"
            variant="outlined"
            id="contact_phone"
            name="support[contact_phone]"
            fullWidth
            required={false}
          />
        </div>
      </div>
    );
  };

  const renderProductDetails = (required = true) => {
    return (
      <div className="row">
        <div className="col-6">
          <CustomTextField
            label="Product Name"
            margin="normal"
            variant="outlined"
            id="product_name"
            name="support[product_name]"
            fullWidth
            required={required}
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="NDC"
            margin="normal"
            variant="outlined"
            id="ndc"
            name="support[ndc]"
            fullWidth
            required={required}
          />
        </div>
      </div>
    );
  };

  const renderDateRange = (required = true) => {
    return (
      <div className="row">
        <div className="col-12">
          <Typography variant="body1" style={labelStyles}>
            Date Range of Data Variance
          </Typography>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={datePickerTheme}>
            <div className="col-6">
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                required={required}
                name="support[start_date]"
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-start"
                label="Start Date"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div className="col-6">
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                required={required}
                name="support[end_date]"
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                error={error}
                helperText={error ? errorMessage : ""}
                id="date-picker-end"
                label="End Date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const renderSubInquiry = (Selections, required = true) => {
    return (
      <div className="row" style={{ marginTop: "8px" }}>
        <div className="col-12">
          <FormControl variant="outlined" fullWidth required>
            <CustomInputLabel id="subinquiry-label">Sub-Inquiry</CustomInputLabel>
            <Select
              labelId="subinquiry-label"
              input={
                <CustomOutlinedInput
                  label="Sub-Inquiry"
                  id="subinquiry"
                  name="support[subinquiry]"
                />
              }
              value={subInquiry}
              label="Sub Inquiry"
              onChange={handleSubInquiryChange}
              fullWidth
              required={required}
            >
              {Selections.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              ;
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };

  const renderManufacturer = (required = true) => {
    return (
      <div className="row" style={{ marginTop: "8px" }}>
        <div className="col-12">
          <Typography variant="body1" style={labelStyles}>
            Product Details
          </Typography>
        </div>
        <div className="col-12">
          {/* <FormControl variant="outlined" fullWidth required>
            <CustomInputLabel id="manufacturer-label">
              Manufacturer
            </CustomInputLabel>
            <Select
              labelId="manufacturer-label"
              input={
                <CustomOutlinedInput
                  label="Manufacturer"
                  id="manufacturer"
                  name="support[manufacturer]"
                />
              }
              value={manufacturer}
              label="Manufacturer"
              onChange={handleManufacturerChange}
              fullWidth
              required={required}
            >
              {manufacturers.map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
              ;
            </Select>
          </FormControl> */}
          <ReactSelect
            id="manufacturer"
            label="Manufacturer"
            placeholder="Select Manufacturer"
            name="support[manufacturer]"
            options={manufacturers}
            styles={customSelectStyles}
          />
        </div>
      </div>
    );
  };

  const renderSummary = (required = true) => {
    return (
      <div className="row">
        <div className="col-12">
          <Typography variant="body1" style={labelStyles}>
            Notes
          </Typography>
        </div>
        <div className="col-12">
          <CustomTextField
            label="Summary"
            margin="normal"
            variant="outlined"
            id="summary"
            name="support[summary]"
            fullWidth
            required={required}
            multiline
          />
        </div>
      </div>
    );
  };

  const renderDataDisputeForm = () => {
    return (
      <React.Fragment>
        {renderContactDetails()}
        {renderEntitySelect()}
        <div className="row">
          <div className="col-12">
            <Typography variant="body1" style={labelStyles}>
              Pharmacy Details
            </Typography>
          </div>
          {renderPharmacySelect()}
          {renderPharmacyDEA(false)}
        </div>
        {renderManufacturer()}
        {renderProductDetails()}
        {renderDateRange()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const renderWhollyOwnedExemptionForm = () => {
    return (
      <React.Fragment>
        {renderSubInquiry(["Application", "Recertification"])}
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect()}
        {renderPharmacyDEA(false)}
        {renderManufacturer()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const render340bPricingForm = () => {
    return (
      <React.Fragment>
        {renderSubInquiry(["Eligibility", "Wholesaler Discrepancy"])}
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect()}
        {renderPharmacyDEA(false)}
        {renderManufacturer()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const renderDataSubmissionForm = () => {
    return (
      <React.Fragment>
        {renderSubInquiry(["General", "TPA", "Troubleshooting"])}
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect()}
        {renderPharmacyDEA(false)}
        {renderManufacturer()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const renderSingleCPDesignationForm = () => {
    return (
      <React.Fragment>
        {renderSubInquiry(["Assitance with HINs", "Specialty Product", "Troubleshooting"])}
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect()}
        {renderPharmacyDEA(false)}
        {renderManufacturer()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const renderManufacturerPolicyForm = () => {
    return (
      <React.Fragment>
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect(false)}
        {renderPharmacyDEA(false)}
        {renderManufacturer()}
        {renderProductDetails()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const render340bAccountAccessForm = () => {
    return (
      <React.Fragment>
        {renderSubInquiry([
          "Account Access Issues",
          "Adding Hospital Users to Account",
          "Adding Third Party Users to Account",
        ])}
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const renderClaimsAlertsForm = () => {
    return (
      <React.Fragment>
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect(false)}
        {renderPharmacyDEA(false)}
        {renderManufacturer()}
        {renderSummary()}
      </React.Fragment>
    );
  };

  const renderOtherForm = () => {
    return (
      <React.Fragment>
        {renderContactDetails()}
        {renderEntitySelect()}
        {renderPharmacySelect(false)}
        {renderPharmacyDEA(false)}
        {renderManufacturer(false)}
        {renderProductDetails(false)}
        {renderDateRange(false)}
        {renderSummary()}
      </React.Fragment>
    );
  };

  return (
    <form method="POST" action="/intake_forms">
      <div className="row">
        <div className="col-12">
          {/* <Typography variant="body1" style={labelStyles} styles={{ marginBottom: 8 }}>
            Inquiry Type
          </Typography> */}
        </div>
        <div className="col-12">
          <FormControl variant="outlined" style={{ marginTop: 12 }} fullWidth required>
            <CustomInputLabel id="type-label">Inquiry</CustomInputLabel>
            <Select
              labelId="type-label"
              value={type}
              label="Inquiry"
              input={<CustomOutlinedInput label="Type" id="type" name="support[type]" />}
              onChange={handleTypeChange}
              fullWidth
              required
              readOnly
              IconComponent={() => <div />}
            >
              <MenuItem value={"Data Variance"}>Data Variance</MenuItem>
              {/*<MenuItem value={"Wholly Owned CP Exemption"}>
                Wholly Owned CP Exemption
              </MenuItem>
              <MenuItem value={"340B Pricing"}>340B Pricing</MenuItem>
              <MenuItem value={"Data Submission"}>Data Submission</MenuItem>
              <MenuItem value={"Single CP Designation"}>
                Single CP Designation
              </MenuItem>
              <MenuItem value={"Manufacturer Policy"}>
                Manufacturer Policy
              </MenuItem>
              <MenuItem value={"340B ESP Account Access"}>
                340 ESP Account Access
              </MenuItem>
              <MenuItem value={"Insufficient Claims Alerts"}>
                Insufficient Claim Alerts
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>*/}
            </Select>
          </FormControl>
        </div>
      </div>
      {type == "Data Variance" && renderDataDisputeForm()}
      {type == "Wholly Owned CP Exemption" && renderWhollyOwnedExemptionForm()}
      {type == "340B Pricing" && render340bPricingForm()}
      {type == "Data Submission" && renderDataSubmissionForm()}
      {type == "Single CP Designation" && renderSingleCPDesignationForm()}
      {type == "Manufacturer Policy" && renderManufacturerPolicyForm()}
      {type == "340B ESP Account Access" && render340bAccountAccessForm()}
      {type == "Insufficient Claims Alerts" && renderClaimsAlertsForm()}
      {type == "Other" && renderOtherForm()}
      <div className="row">
        <div className="col-12 mt-3 d-flex justify-content-end">
          <button className="btn" type="submit">
            <i className="solid solid-budicon-sending-message" />
            Send Form
          </button>
        </div>
      </div>
    </form>
  );
};

export default SupportsForm;
