import React, { useState, useEffect } from "react";
import { Illustration } from "../../images/svgs";

const WithdrawModal = (props) => {
  const renderHeader = () => {
    return (
      <div className="modal__content__header flex">
        <div className="flex flex-col item-start">
          <h3 className="t--lg"> {props.title} </h3>
          <p className="t--sm t--gray">{props.subtitle}</p>
        </div>
        <div>
          <Illustration />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flex flex-col item-start">
        <div className="modal__content__body w--100">{props.body}</div>
      </div>
    );
  };

  const renderFooter = () => {
    return <div className="modal__content__footer p--0">{props.footer}</div>;
  };

  return (
    <div className="modal__background" data-testid="modal-continer">
      <div
        className="modal__container"
        style={{ width: props.width ? props.width : "680px" }}
      >
        <div className="modal__content modal__content--padded">
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
