import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";

import {getValidationDetails} from "../notices/validation_details";
import { mappingWarnings } from "../notices/mapping_warning";

const Errors = (props) => {
  const renderErrors = () => {
    if (props.hasError) {
      var error = getValidationDetails("missing_fields")
      return (
        <div className="notify__error animated fadeInUp">
          <div className="notify__error__col">
            <div className="notify__error__error-title">Validation Error</div>
          </div>

          <div className="notify__error__col notify__error__col--spread">
            <div className="notify__error__description-title">{error.title}</div>
            <div className="notify__error__description">{error.description}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="notify__error__container">
      {renderErrors()}
    </div>
  );
};

export default Errors;
