import React, { useState, useEffect, useMemo, useRef } from "react";
import { Refresh } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import MultiChipSelector from "../Draw/MultiChipSelector";
import ChipSelector from "./ChipSelector";
import Toggle from "./Toggle";
import DateSelector from "./DateSelector";

const pharmaciesWithInhouseOption = ["johnson_n_johnson", "second_sight_solutions", "amgen"];

const AccountsDraw = (props) => {
  const [selected, setSelected] = useState(props.searchQuery.id_340b);
  const [status, setStatus] = useState(null);
  const [groupBy, setGroupBy] = useState(null);
  const [enableExclusions, setEnableExclusions] = useState(null);
  const [dateRange, setDateRange] = useState(props.searchQuery.date_range);

  const [selectedDetails, setSelectedDetails] = useState({
    manufacturer: props.searchQuery.manufacturer,
    product_names: [],
  });

  useEffect(() => {
    props.handleSearch("id_340b", selected);
  }, [selected]);

  useEffect(() => {
    props.handleSearch("details", selectedDetails);
  }, [selectedDetails]);

  useEffect(() => {
    props.handleFilter("status", status);
  }, [status]);

  useEffect(() => {
    props.handleSearch("grouping", groupBy);
  }, [groupBy]);

  useEffect(() => {
    props.handleSearch("enable_exclusions", enableExclusions);
  }, [enableExclusions]);

  useEffect(() => {
    props.handleSearch("date_range", dateRange);
  }, [dateRange]);

  const pharmacyTypeOptions = () => {
    if (pharmaciesWithInhouseOption.includes(selectedDetails.manufacturer)) {
      return [
        { value: "contract", label: "Contract Pharmacy" },
        { value: "inhouse", label: "In-House" },
      ];
    }

    return [{ value: "contract", label: "Contract Pharmacy" }];
  };

  const handleSelectedMfr = (value) => {
    setSelectedDetails({ manufacturer: value, product_names: [], pharmacy_type: "contract" });
  };

  const handleSelectedProduct = (value) => {
    setSelectedDetails((prevState) => {
      if (selectedDetails.product_names.includes(value)) {
        return {
          ...prevState,
          pharmacy_type: props.searchQuery.pharmacy_type,
          product_names: prevState.product_names.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevState,
          pharmacy_type: props.searchQuery.pharmacy_type,
          product_names: [...prevState.product_names, value],
        };
      }
    });
  };

  const renderDownload = () => {
    if (props.accounts && props.accounts.length) {
      if (props.searchQuery.pharmacy_type == "inhouse") {
        return (
          <a href={props.inhouseDownloadPath} download="340B_ESP_Inhouse_Submission_Balances.xlsx" target="_blank">
            <div className="btn btn--light">Download File</div>
          </a>
        );
      } else {
        return (
          <a href={props.downloadPath} download="340B_ESP_Submission_Balances.xlsx" target="_blank">
            <div className="btn btn--light">Download File</div>
          </a>
        );
      }
    } else {
      return <div className="btn btn--light btn--disabled">Download File</div>;
    }
  };

  return (
    <div>
      <div className="draw__tools__container">
        <div className="btn btn--sqr">
          <Refresh fontSize="small" />
        </div>
        {renderDownload()}
      </div>
      <DateSelector setDateRange={setDateRange} />
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Covered Entity</div>
        <div className="chip__well">
          <ChipSelector items={props.entities} selected={selected} setSelected={setSelected} required={true} />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Account Status</div>
        <Toggle
          setValue={setStatus}
          enabled_value={"NEGATIVE"}
          disabled_value={null}
          label={"Negative Balance Accts Only"}
        />
      </div>
      {/* <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Pharmacy Chains</div>
        <Toggle
          setValue={setGroupBy}
          enabled_value={"chain_name"}
          disabled_value={"product_name"}
          label={"Group by Pharmacy Chains"}
        />
      </div> */}
      {/* <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Exclusions</div>
        <Toggle
          setValue={setEnableExclusions}
          enabled_value={"YES"}
          disabled_value={null}
          label={"Include WO Exemptions"}
        />
      </div> */}
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Manufacturers</div>
        <div className="chip__well">
          <ChipSelector
            items={props.manufacturers ? props.manufacturers : []}
            selected={selectedDetails.manufacturer}
            setSelected={handleSelectedMfr}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Type</div>
        <div className="chip__well">
          <ChipSelector
            items={pharmacyTypeOptions()}
            selected={props.searchQuery.pharmacy_type}
            setSelected={(value) => props.handleSearch("pharmacy_type", value)}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Products</div>
        <div className="chip__well">
          <MultiChipSelector
            options={props?.products[selectedDetails.manufacturer] || []}
            selected={selectedDetails.product_names}
            handleSelection={handleSelectedProduct}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountsDraw;