import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import Notices from "../../components/notice";
import TableFilter from "./submission_table_filter";
import SubmissionTable from "./submission_table";
import { columnDetails } from "../../schemas/schema_helper";
import { Illustration } from "../../../images/svgs";
import OriginalFileSelect from "./original_file_select";

import { TableData } from "../../processors/table_data";

const DataOutput = (props) => {
  const [filter, setFilter] = useState("all");
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [columnMappingWarnings, setColumnMappingWarnings] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    var { data, headers, columnMappingWarnings, mapping } = TableData(
      props.data,
      props.viewOriginal,
      props.originalViewId,
    );

    setData(data);
    setHeaders(headers);
    setMapping(mapping);
    setColumnMappingWarnings(columnMappingWarnings);
  }, [props.data, props.viewOriginal, props.originalViewId]);

  const getHeaders = () => {
    if (headers.length) {
      return headers;
    }

    return props.headers;
  };

  const filterData = (filter, data) => {
    switch (filter) {
      case "all":
        return data;
      case "errors":
        return data.filter((row) => row.hasErrors);
      case "no_errors":
        return data.filter((row) => !row.hasErrors);
      case "selected":
        return data.filter((row) => row.selected);
      case "not_selected":
        return data.filter((row) => !row.selected);
      default:
        return [];
    }
  };

  const renderFileSelect = () => {
    if (props.viewOriginal) {
      return (
        <OriginalFileSelect
          attachments={props.data}
          originalViewId={props.originalViewId}
          setOriginalViewId={props.setOriginalViewId}
        />
      );
    }
    return null;
  };

  const renderEmpty = () => {
    return (
      <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
        <Illustration />

        <h3 className="t--lg mt--16 mb--16">Nothing attached yet</h3>
        <p className="t--sm">Attach a file to get started on your submission</p>
      </div>
    );
  };

  if (!props.data.length) return renderEmpty();

  return (
    <div>
      <div>{!props.viewOriginal && <Notices data={props.data} />}</div>
      <div className="data-table__actionbar data-table__actionbar--flex-start">
        <div style={{ display: "flex", gap: 16 }}>
          {renderFileSelect()}
          <TableFilter filter={filter} setFilter={setFilter} viewOriginal={props.viewOriginal} />
        </div>
      </div>
      <div className="data-table__container">
        <SubmissionTable
          key={Math.random()}
          viewOriginal={props.viewOriginal}
          type={props.type}
          tableData={filterData(filter, data)}
          headers={getHeaders()}
          columnDetails={columnDetails(props.type)}
          mapping={mapping}
          columnMappingWarnings={columnMappingWarnings}
          setScrollTo={props.setScrollTo}
        />
      </div>
    </div>
  );
};

export default DataOutput;
