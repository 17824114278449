import { useRef, useEffect } from "react";

export const useOnOutsideClick = (handleOutsideClick) => {
    const containerRef = useRef();
  
    const onClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleOutsideClick();
      }
    };
  
    useMountEffect(() => {
      document.addEventListener("click", onClick, true);
      return () => {
        document.removeEventListener("click", onClick, true);
      };
    });
  
    return { containerRef };
  };

  const useMountEffect = (fun) => useEffect(fun, []);

  export default useOnOutsideClick;