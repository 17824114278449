import _ from "lodash";

export const columnSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  ndc: null,
  quantity: null,
  wholesaler_invoice_number: null,
  service_provider_id: null,
  contracted_entity_id: null,
  prescriber_id_qualifier: null,
  prescriber_id: null,
  service_provider_id_qualifier: null,
};

export const columnDetails = [
  {
    name: "date_of_service",
    title: "DOS",
    description: "Date on which the patient filled their prescription.",
    required: true,
    order: 99,
  },
  {
    name: "date_prescribed",
    title: "Date Prescribed",
    description: "Date the prescriber wrote the prescription.",
    required: true,
    order: 99,
  },
  {
    name: "rx_number",
    title: "Rx Number",
    description: "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    required: true,
    order: 99,
  },
  {
    name: "ndc",
    title: "NDC-11",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    required: true,
    order: 99,
  },
  {
    name: "quantity",
    title: "Quantity",
    description: "The number of units in the prescription.",
    required: true,
    order: 99,
  },
  {
    name: "service_provider_id",
    title: "Service Provider ID",
    description:
      "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    required: true,
    order: 99,
  },
  {
    name: "contracted_entity_id",
    title: "340B ID",
    description:
      "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    required: true,
    order: 99,
  },
  {
    name: "prescriber_id",
    title: "Prescriber ID",
    description: "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    required: false,
    order: 99,
  },
  {
    name: "prescriber_id_qualifier",
    title: "Prescriber ID Qualifier",
    description: "Indicates the type of unique ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    required: false,
    order: 99,
  },
  {
    name: "service_provider_id_qualifier",
    title: "Service Provider ID Qualifier",
    description:
      "Indicates the type of unique ID provider. “01” for NPI, “05” for Medicaid”, “07” for NCPDP, and “12” for DEA.",
    required: false,
    order: 99,
  },
  {
    name: "wholesaler_invoice_number",
    title: "Wholesaler Invoice Number",
    description:
      "The invoice number assigned by the wholesaler for the replenishment order made by the 340B covered entity.",
    required: false,
    order: 99,
  },
];

export const tableHeaders = columnDetails.map((column) => column.name);
export const sortedColumnDetails = columnDetails.sort((a, b) => a.order - b.order);
export const required = columnDetails.filter((column) => column.required).map((column) => column.name);
export const notRequired = columnDetails.filter((column) => !column.required).map((column) => column.name);
export const isRequiredField = (column) => {
  return required.includes(column);
};

export class PharmacyClaim {
  constructor() {
    this.hasErrors = false;

    this.data = {
      date_of_service: null,
      date_prescribed: null,
      rx_number: null,
      ndc: null,
      quantity: null,
      prescriber_id: null,
      prescriber_id_qualifier: null,
      service_provider_id: null,
      service_provider_id_qualifier: null,
      wholesaler_invoice_number: null,
      contracted_entity_id: null,
      claim_conforms_flag: null,
      formatted_rx_number: null,
    };

    this.columns_with_errors = {
      date_of_service: false,
      date_prescribed: false,
      rx_number: false,
      ndc: false,
      quantity: false,
      prescriber_id: false,
      prescriber_id_qualifier: false,
      service_provider_id: false,
      service_provider_id_qualifier: false,
      wholesaler_invoice_number: false,
      contracted_entity_id: false,
    };
  }
}
