import {
  isValidDateOfService,
  isValidDatePrescribed,
  isValidRXNumber,
  isValidQuantity,
  isValidFillNumber,
  isValidWholesalerInvoiceNumber,
  isValidPrescriberIDQualifier,
  isValidServiceProviderIDQualifier,
  isValidContractedEntityID,
  isValidDateOfEncounter,
  isValidHCPCS,
  isValidPrimaryDiagnosisCode,
  isValidDiagnosisCode,
  isValidPlaceOfService,
  isValidPhysicianIDQualifier,
  isValidBillingProviderIDQualifier,
  isValidNPI,
} from "./data_validations";
import { isRequiredField } from "../schemas/pharmacy_encounter_claim";

const isValidEncounterClaimField = (value, column, row, mapping) => {
  if (["claim_conforms_flag", "encounter_conforms", "formatted_rx_number"].includes(column)) {
    return true;
  }

  if (isRequiredField(column) && !value) {
    return false;
  }

  switch (column) {
    // pharmacy submission fields
    case "date_of_service":
      return isValidDateOfService(value, row[mapping["date_prescribed"]]);
    case "date_prescribed":
      return isValidDatePrescribed(value, row[mapping["date_of_service"]]);
    case "rx_number":
      return isValidRXNumber(value);
    case "ndc":
      return true; // skip validations for column we filter by
    case "quantity":
      return isValidQuantity(value);
    case "fill_number":
      return isValidFillNumber(value);
    case "wholesaler_invoice_number":
      return isValidWholesalerInvoiceNumber(value);
    case "prescriber_id_qualifier":
      return isValidPrescriberIDQualifier(value);
    case "prescriber_id":
      return isValidNPI(value);
    case "service_provider_id_qualifier":
      return isValidServiceProviderIDQualifier(value);
    case "service_provider_id":
      return isValidNPI(value);
    case "contracted_entity_id":
      return isValidContractedEntityID(value);

    //   // Additional encounter fields
    case "billing_provider_id":
      return isValidNPI(value);
    case "billing_provider_id_qualifier":
      return isValidBillingProviderIDQualifier;
    case "date_of_encounter":
      return isValidDateOfEncounter(value);
    case "physician_id":
      return isValidNPI(value);
    case "physician_id_qualifier":
      return isValidPhysicianIDQualifier(value);
    case "hcpcs_code":
      return isValidHCPCS(value);
    case "hcpcs_code_modifier_1":
      return true;
    case "hcpcs_code_modifier_2":
      return true;
    case "diagnosis_code1":
      return isValidPrimaryDiagnosisCode(value);
    case "diagnosis_code2":
      return isValidDiagnosisCode(value);
    case "diagnosis_code3":
      return isValidDiagnosisCode(value);
    case "diagnosis_code4":
      return isValidDiagnosisCode(value);
    case "diagnosis_code5":
      return isValidDiagnosisCode(value);
    case "diagnosis_code6":
      return isValidDiagnosisCode(value);
    case "diagnosis_code7":
      return isValidDiagnosisCode(value);
    case "diagnosis_code8":
      return isValidDiagnosisCode(value);
    case "diagnosis_code9":
      return isValidDiagnosisCode(value);
    case "diagnosis_code10":
      return isValidDiagnosisCode(value);
    case "place_of_service_code":
      return isValidPlaceOfService(value);
    default:
      return false;
  }
};

export default isValidEncounterClaimField;
