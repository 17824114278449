import React, { useState } from "react";
import TabBar from "../TabBar";
import ClaimsTable from "./table";
import SubmissionsModal from "../../modals/ClaimsModal";

const RebateClaimsData = (props) => {
  const tabs = ["Pharmacy Claims", "Medical Claims"];
  const modalItems = [
    {
      heading: "Pharmacy Eligibility Data",
      content: `Pharmacy claims represent adjudicated prescription claims data originating from a retail, mail-order or specialty pharmacy dispense of a medication for subsequent self-administration by the patient.`,
      link: props.pharmacy_link,
      // badge: "Eligbility",
    },
    {
      heading: "Medical Eligibility Data",
      content: `Medical claims represent health care claims data originating from a physician's administration of a medication 
      directly to a patient in an outpatient setting. The physician administered drug may have been separately reimbursable 
      (e.g. an infused oncology medication) or may have been reimbursed as part of an outpatient procedure (e.g. the administration of a blood thinner).`,
      link: props.medical_link,
      // badge: "Eligbility",
    },
  ];

  const [activeTab, setActiveTab] = useState(["Pharmacy Claims"]);
  const [displayTabBarTags, setDisplayTabBarTags] = useState(false);

  const enableTab = (tab) => {
    setActiveTab(tab);
  };

  const pharmacyDataView = (tab) => {
    return (
      <div
        key={tab}
        className="tabs__view tabs__view--active"
        style={{ marginTop: 20 }}
        data-tab-name={tab}
      >
        <ClaimsTable submissions={props.pharmacy_submissions} kind="encounter" />
      </div>
    );
  };

  const medicalDataView = (tab) => {
    return (
      <div
        key={tab}
        className="tabs__view tabs__view--active"
        style={{ marginTop: 20 }}
        data-tab-name={tab}
      >
        <ClaimsTable submissions={props.medical_submissions} kind="medical" />
      </div>
    );
  };

  const renderTabViews = () => {
    if (activeTab == "Pharmacy Claims") {
      return pharmacyDataView("pharmacy-claims");
    }
    if (activeTab == "Medical Claims") {
      return medicalDataView("medical-claims");
    }
  };

  return (
    <>
      <div
        className="table__actionbar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #DEE7EE",
          padding: 0,
        }}
      >
        <TabBar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={enableTab}
          displayTagsFor={"Medical Claims"}
          displayTabBarTags={displayTabBarTags}
          style={{ display: "flex", flex: 1, margin: 0, borderBottom: "none" }}
        />

        <SubmissionsModal {...props} items={modalItems} />
      </div>

      {renderTabViews()}
    </>
  );
};

export default RebateClaimsData;
