import React from "react";
import DataSubmission from "./shared/data_submission";
import {
  tableHeaders,
  columnDetails,
  notRequired,
} from "../schemas/pharmacy_encounter_claim";

const EncounterSubmission = (props) => {
  return (
    <DataSubmission
      {...props}
      headers={tableHeaders}
      type="encounter"
      columnDetails={columnDetails}
      notRequired={notRequired}
    />
  );
};

export default EncounterSubmission;
