import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { ExpandMore, GetApp, ChevronRight, UnfoldMore } from "@material-ui/icons";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import TableLoadingSkeleton from "../TableLoadingSkeleton";

export default function ClaimsTable(props) {
  const tableContainerRef = useRef();

  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("id_340b", {
      header: "340B ID",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor("claim_number", {
      header: "Claim Number",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{_.toUpper(renderValue().substr(0, 15) + "...")}</div>,
    }),
    columnHelper.accessor("service_provider_id", {
      header: "Pharmacy",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor("date_of_service", {
      header: "DOS",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{_.toUpper(renderValue())}</div>,
    }),
    columnHelper.accessor((row) => row.plan_id_code, {
      header: "Plan",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{row.original.plan_name ? row.original.plan_name : "--"}</div>
            <div className="t--sm t--subtitle">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.product_service_id, {
      header: "NDC",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div>
            <div className="t--sm t--500">{row.original.product_name}</div>
            <div className="t--sm t--subtitle">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("quantity", {
      header: "Quantity",
      cell: ({ renderValue }) => <div className="t--md t--500">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.hcpcs_code, {
      header: "HCPCS Code",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div>
            <div className="t--sm t--500">{row.original.hcpcs_product_name}</div>
            <div className="t--sm t--subtitle">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("quality", {
      header: "Status",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getIndicatorName(getValue())}`} />
            {getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("date_submitted", {
      header: "Submitted",
      cell: ({ renderValue }) => <div className="t--sm t--500">{renderValue()}</div>,
    }),
  ];

  const getIndicatorName = (name) => {
    var status = {
      conforming: "green",
      "non-conforming": "skyblue",
      "INVALID 340B ID": "blue",
      "NOT A CONTRACT PHARMACY": "orange",
    };

    return status[name];
  };

  const claims = useMemo(() => props.claims, [props.claims]);

  const table = useReactTable({
    data: claims,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    initialState: { pagination: { pageSize: 50 } },
    getPaginationRowModel: getPaginationRowModel(),
  });

  const renderPaginationControls = () => {
    return (
      <div className="flex__container" style={{ justifyContent: "flex-end", marginTop: 10 }}>
        <button className="btn btn--small" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Prev
        </button>
        <button
          className="btn btn--small"
          style={{ marginLeft: 10 }}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    );
  };

  const renderRows = () => {
    if (props.isLoading) return <TableLoadingSkeleton rows={20} columns={10} />;

    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        </Fragment>
      );
    });
  };

  return (
    <>
      <div ref={tableContainerRef}>
        <table className="primary">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sm">
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <span
                        style={{ fontSize: 12, display: "inline-flex", verticalAlign: "top" }}
                        onClick={() => header.column.toggleSorting()}
                      >
                        <UnfoldMore fontSize="inherit" color="inherit" />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
        {renderPaginationControls()}
      </div>
    </>
  );
}
