import React, { useState, useEffect, useRef } from "react";

import { ExpandMore } from "@material-ui/icons";

const filterOptions = {
  originalView: [
    { label: "All rows", filter: "all" },
    { label: "Only rows with errors", filter: "errors" },
    { label: "Only rows selected for submission", filter: "selected" },
    { label: "Only rows not selected for submission", filter: "not_selected" },
  ],
  submissionView: [
    { label: "All rows", filter: "all" },
    { label: "Only rows with errors", filter: "errors" },
  ],
};

const SubmissionTableFilter = ({ filter, setFilter, viewOriginal }) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef();
  const options = viewOriginal ? filterOptions.originalView : filterOptions.submissionView;

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    setFilter("all");
  }, [viewOriginal]);

  const handleClick = (option) => {
    setFilter(option.filter);
  };

  const renderOption = (option, index) => {
    if (option.filter === filter) {
      return (
        <div
          key={index}
          className="filterbox__menu__item filterbox__menu__item--selected"
          onClick={() => handleClick(option)}
        >
          <div>{option.label}</div>
          <div className="filterbox__menu__item__selected__icon">
            <div className="solid solid-budicon-check-ui" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="filterbox__menu__item" key={index} onClick={() => handleClick(option)}>
          {option.label}
        </div>
      );
    }
  };

  const renderOptions = () => {
    if (open) {
      return (
        <div className="filterbox__menu" style={{ left: 0 }}>
          {options.map((option, i) => renderOption(option, i))}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSelectedOption = () => {
    return options.find((option) => option.filter === filter).label;
  };

  return (
    <div
      className={`filterbox__container filterbox__container--no-hover`}
      ref={filterRef}
      onClick={() => setOpen(!open)}
    >
      <span className="t--subtitle" style={{ marginRight: 6 }}>
        Display:
      </span>
      {renderSelectedOption()}
      <ExpandMore fontSize="small" />
      {renderOptions()}
    </div>
  );
};

export default SubmissionTableFilter;
