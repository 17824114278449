import React, { useState, useMemo } from "react";
import _, { get } from "lodash";
import XLSX from "xlsx";
import ConfirmationModal from "../../../ConfirmationModal";
import Notice from "../../../Notice";
import SubmissionCard from "./submission_card";
import moment from "moment";

const Post = (props) => {
  const kind = props.type == "encounter" ? "Pharmacy_Eligibility_" : `${_.startCase(props.type)}_`;
  const [title, setTitle] = useState(kind + moment().format("MM-DD-YY"));
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const headers = props.submissionData[0]?.formattedData.length
    ? _.keys(props.submissionData[0]?.formattedData[0].data)
    : [];
  var workbook = XLSX.utils.book_new();
  const workbookSheet = XLSX.utils.aoa_to_sheet([headers]);

  const handleFile = () => {
    var data = _.flatten(
      props.submissionData.map((submission) => _.map(submission.formattedData, "data")),
    );

    XLSX.utils.sheet_add_json(workbookSheet, data, {
      skipHeader: true,
      blankrows: false,
      origin: "A2",
    });
  };

  const writeWorkbook = (_callback) => {
    handleFile();
    XLSX.utils.book_append_sheet(workbook, workbookSheet, "340B ESP™");

    _callback(XLSX.write(workbook, { bookType: "csv", type: "array" }));
  };

  const handleSubmission = () => {
    setDisplayConfirmation(false);
    if (!props.submissionData || submitting) return;
    setSubmitting(true);
    writeWorkbook(postSubmission);
  };

  const postSubmission = (csv) => {
    var formData = new FormData();
    var date = new Date();

    formData.append(
      "submission[uploads]",
      new File([csv], `340B_ESP_${date.toISOString().split("T")[0]}.csv`),
    );
    formData.append("submission[name]", title);
    formData.append("submission[kind]", props.type);
    formData.append("submission[credit]", props.rebate);

    fetch(props.submissionPath, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: formData,
    }).then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        return res.json().then((data) => {
          location.href = data.path;
        });
      } else {
        res.json().then((data) => {
          location.href = data.path;
        });

        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      }
    });
  };

  const getTotalRowCount = () => {
    if (props.submissionData) {
      return props.submissionData.reduce((totalRows, submission) => {
        return totalRows + submission.originalData.length;
      }, 0);
    }
  };

  const getSelectedRowCount = () => {
    if (props.submissionData) {
      return props.submissionData.reduce((totalRows, submission) => {
        return totalRows + submission.formattedData.length;
      }, 0);
    }
  };

  const submitEnabled = useMemo(() => {
    if (!props.submissionData || !props.submissionData.length || !getSelectedRowCount()) {
      return false;
    }

    return !props.submissionData.some((submission) => submission.hasErrors);
  }, [props.submissionData]);

  const submitCard = () => {
    if (!submitEnabled) {
      return;
    }
    setDisplayConfirmation(true);
  };

  return (
    <div>
      <Notice details={notice} />
      <SubmissionCard
        date={new Date()}
        title={title}
        numberOfFiles={props.fileCount}
        selectedRows={getSelectedRowCount()}
        cancelLink={props.cancelLink}
        totalRows={getTotalRowCount()}
        submitCard={submitCard}
        submitEnabled={submitEnabled}
        setTitle={setTitle}
      />
      <ConfirmationModal
        displayModal={displayConfirmation}
        setModalState={setDisplayConfirmation}
        action={handleSubmission}
      />
    </div>
  );
};

export default Post;
