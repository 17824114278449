import React, { forwardRef } from "react";
import _ from "lodash";
import Post from "./submit";

import AttachedFile from "./attached_file";

const SheetJSFT = [".xlsx", ".xls", ".csv"];

const DataInput = forwardRef((props, inputRef) => {
  // Handles attaching new file
  const handleChange = (e) => {
    const attachments = e.target.files;
    props.handleFile(attachments[0]);
  };

  const renderAttachments = () => {
    if (props.submissionData?.length > 0) {
      return (
        <>
          {props.submissionData.map((submission, i) => {
            const file = props.files.find((file) => file.id === submission.file_id);
            return (
              <AttachedFile
                file={file}
                key={submission.id}
                usedFields={props.usedFields}
                pos={i}
                handleSelectAttachment={props.handleSelectAttachment}
                attachment={submission}
                handleRemoveFile={props.handleRemoveFile}
                selected={props.selectedAttachmentId === submission.id}
                mappingTabSelected={props.mappingTabSelected}
                handleSelectMapping={props.handleSelectMapping}
                mappings={props.mappings}
              />
            );
          })}
        </>
      );
    }
  };

  return (
    <div>
      <div className="draw__item__container">
        <Post
          type={props.type}
          rebate={props.rebate}
          organization={props.organization}
          submissionData={props.submissionData}
          submissionPath={props.submissionPath}
          fieldMappingGroups={props.fieldMappingGroups}
          filesWithErrors={props.filesWithErrors}
          headers={props.headers}
          fileCount={props.files.length}
        />
      </div>

      <div className="draw__section__header">
        <div className="draw__section__title" style={{ fontSize: 13 }}>
          Attach File(s) to Submit
        </div>
        <div className="draw__section__caddy">
          <div
            className={props.submissionData ? "btn btn--small" : "btn btn--small btn--pulse--loud"}
            onClick={() => {
              document.getElementById("submissionAttachments").click();
            }}
          >
            Attach
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            className="form-control"
            id="submissionAttachments"
            accept={SheetJSFT}
            multiple={true}
            ref={inputRef}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="draw__section__container">{renderAttachments()}</div>
    </div>
  );
});

export default DataInput;
