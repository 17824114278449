import React from "react";

import tutorialPromptImg from "~/images/review_submission_illustration.png";

const SubmissionTutorialIntro = ({ handleClose, handleConfirm }) => {
  const handleGoToMapping = () => {
    handleClose();
    handleConfirm();
  };

  return (
    <div className="modal__background modal__background--light">
      <div className="tutorial_modal__container">
        <div className="tutorial_modal__image-container tutorial_modal__image-container--no-padding-bottom">
          <img src={tutorialPromptImg} />
        </div>
        <div className="tutorial_modal__body">
          <h2 className="tutorial_modal__header">Learn to review your submissions?</h2>
          <div className="tutorial_modal__description t--md t--500">
            <div className="tutorial_modal__description t--md t--500">
              Before you can submit, learn how to review your submissions.
            </div>
          </div>
          <div className="tutorial_modal__button-container tutorial_modal__button-container--flex-end">
            <div className="tutorial_modal__button-container__navigation">
              <button className="btn btn--white btn--light-border" onClick={handleClose}>
                No thanks
              </button>
              <button className="btn" onClick={handleGoToMapping}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionTutorialIntro;
