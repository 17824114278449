import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Notice from "../../components/Notice";
import ConfirmationModal from "../../components/ConfirmationModal";
import _ from "lodash";
import { manufacturers } from "../../manufacturers/details";
import { search, filterByStatus } from "../../components/Pharmacies/utils";
import PharmacySearch from "../../components/Pharmacies/PharmacySearch";

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#DEE5EB",
    marginLeft: 10,
    padding: 0,
    "&$checked": {
      color: "#3246D3",
      padding: 0,
    },
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
}))(Checkbox);

export default function BauschPharmacyTable(props) {
  const [pharmacies, setPharmacies] = useState(props.pharmacies);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [eligibility, setEligibility] = useState(props.eligibility);
  const [searchTerms, setSearchTerms] = useState({ searchQuery: "", filterQuery: [] });

  useEffect(() => {
    filterPharmacies();
  }, [searchTerms]);

  const filterPharmacies = () => {
    if (searchTerms.filterQuery.length > 0) {
      return filterByStatus(pharmacies, searchTerms, eligibility, ["retail"]);
    } else {
      return search(pharmacies, searchTerms);
    }
  };

  const renderEligibilityTag = (status, date) => {
    switch (status) {
      case "ELIGIBLE":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            ELIGIBLE
          </div>
        );
      case "INELIGIBLE":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--red" />
            INELIGIBLE
          </div>
        );
      case "INELIGIBLE_MISSING_CLAIMS":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--red" />
            INELIGIBLE - MISSING CLAIMS
          </div>
        );
      case "IN_PROCESS":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            IN PROCESS
          </div>
        );
      case "AT_RISK":
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--orange" />
            AT RISK
          </div>
        );
    }
  };

  const renderPharmacyStatus = (pharmacy) => {
    return renderEligibilityTag(
      props.eligibility[pharmacy.pharmacy_id].retail,
      props.eligibility[pharmacy.pharmacy_id].risk_expiration_date
    );
  };

  const renderPharmacies = () => {
    return filterPharmacies().map((pharmacy, i) => {
      return (
        <tr key={i} className="sm">
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.address}</div>
          </td>
          <td className="t--md">{pharmacy.dea}</td>
          <td className="t--md">{renderPharmacyStatus(pharmacy)}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <div className="table__actionbar__vertical_stack">
          <div className="section__title">Contracted Pharmacies</div>
          <PharmacySearch searchTerms={searchTerms} setSearchTerms={setSearchTerms} filterable={true} />
        </div>
      </div>

      <div className="table__container" style={{ padding: "0 5px", marginBottom: 50 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Pharmacy</th>
              <th>DEA</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
      <Notice details={notice} />
    </div>
  );
}
