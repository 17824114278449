import React, { useState } from "react";

import { InfoOutlined, ExpandMore } from "@material-ui/icons";

const HelpDropdown = ({ setDisplaySubmissionTutorialIntro }) => {
  const [openHelpOptions, setOpenHelpOptions] = useState(false);

  const options = [
    { label: "Quick Tutorial", onClick: () => setDisplaySubmissionTutorialIntro(true) },
  ];

  const renderOption = (option, index) => {
    return (
      <div key={index} className="filterbox__menu__item" onClick={option.onClick}>
        <div>{option.label}</div>
      </div>
    );
  };

  const renderHelpOptions = () => {
    if (openHelpOptions) {
      return (
        <div
          className="filterbox__menu filterbox__menu--sm"
          style={{ left: 0, minWidth: "unset", top: 40 }}
        >
          {options.map((option, i) => renderOption(option, i))}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className="filterbox__container filterbox__container--sm"
      onClick={() => setOpenHelpOptions(!openHelpOptions)}
    >
      <InfoOutlined style={{ fontSize: 16 }} />
      <span className="t--500">Help Resources</span>
      <ExpandMore />
      {renderHelpOptions()}
    </div>
  );
};

export default HelpDropdown;
