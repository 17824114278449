import React from "react";

const StatCards = (props) => {
  return (
    <div className="stat-cards__container">
      <div className="stat-cards__card">
        <div className="stat-cards__card__icon">
          <i className="solid solid-budicon-shared-user" />
        </div>
        <div className="stat-cards__card__details__container">
          <div className="stat-cards__card__details__value">
            {props.totalSubmissions}
          </div>
          <div className="stat-cards__card__details__label">
            Total Submissions
          </div>
        </div>
      </div>
      <div className="stat-cards__card">
        <div className="stat-cards__card__icon">
          <i className="solid solid-budicon-graphic-moderate-a" />
        </div>
        <div className="stat-cards__card__details__container">
          <div className="stat-cards__card__details__value">{props.recordCount}</div>
          <div className="stat-cards__card__details__label">
            This Month's Records
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCards;