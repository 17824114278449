import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CloseIcon from "@material-ui/icons/Close";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

const SubmissionCard = (props) => {
  const {
    date,
    title,
    numberOfFiles,
    totalRows,
    selectedRows,
    submitEnabled,
    submitCard,
    setTitle,
  } = props;

  const [editing, setEditing] = useState(false);

  const renderSelectedRows = () => {
    if (isNaN(selectedRows)) {
      return <div className="t--sm px-20">Selected Rows: --</div>;
    }
    return (
      <div className="t--sm px-20">
        Selected Rows: {selectedRows}/{totalRows}
      </div>
    );
  };

  return (
    <div className="tile" style={{ position: "relative" }}>
      <div className="tile__header">
        <div className="draw__submission_card__date_icon">
          <div className="draw__submission_card__date_icon__date">{moment(date).format("DD")}</div>
          <div className="draw__submission_card__date_icon__month">
            {moment(date).format("MMM")}
          </div>
        </div>

        <div>
          {editing ? (
            <>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="tile__input"
                style={{ marginLeft: 10 }}
                autoFocus
                data-testid="title-field"
              />
              <div
                className="tile__icon-container"
                style={{ position: "absolute", top: 10, right: 10 }}
              >
                <SaveOutlinedIcon
                  className="icon icon--small"
                  onClick={() => setEditing(false)}
                  data-testid="save-icon"
                />
              </div>
            </>
          ) : (
            <>
              <div
                className="flex__container"
                style={{
                  maxWidth: 120,
                  overflowWrap: "break-word",
                  margin: "0 10px",
                  flexDirection: "column",
                }}
              >
                <div className="t--sm t--subtitle t--italics">Submission Name</div>
                <div className="t--sm t--bold">{title}</div>
              </div>
              <div
                className="tile__icon-container"
                style={{ position: "absolute", top: 10, right: 10 }}
              >
                <CreateOutlinedIcon
                  className="icon icon--small"
                  onClick={() => setEditing(true)}
                  data-testid="edit-icon"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="item--flex my-10 t--gray">
        <div className="t--sm">Files: {numberOfFiles}</div>
        {renderSelectedRows()}
      </div>

      <div className="item--flex flex--between">
        <a href={window.location.href}>
          <div
            className="btn btn--outline btn--white btn--sq"
            style={{ width: 46, height: 46, lineHeight: "44px", fontSize: 16 }}
          >
            <div className="solid solid-budicon-trash-cancel" />
          </div>
        </a>
        {/* <a href={window.location.href}>
          <div
            className="btn btn--ib btn--small btn--outline-subtle-white btn--default-align"
          >
            <CloseIcon className="icon--small" data-testid="close-icon" />
            Cancel
          </div>
        </a> */}
        <div
          className={`btn btn--ib btn--small btn--default-align ${
            submitEnabled ? "" : "btn--disabled"
          }`}
          style={{ flex: 1 }}
          onClick={submitCard}
          data-testid="submit-button"
        >
          Save and Submit
        </div>
      </div>
    </div>
  );
};

export default SubmissionCard;
