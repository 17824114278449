import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import YutrepiaDesignationForm from "./YutrepiaDesignationsForm";

import Liquidia from "~/images/Liquidia.png";

const LiquidiaDesignations = (props) => {
  const manufacturer_name = "liquidia";
  const vanity_manufacturer_name = "Liquidia";
  const [retailHasSelectedSpecialtyPharmacy, setRetailHasSelectedSpecialtyPharmacy] = useState(false);
  const [retailSelectedSpecialtyPharmacy, setRetailSelectedSpecialtyPharmacy] = useState();
  const [manufacturerFormDetails, setManufacturerFormDetails] = useState({
    liquidia: {},
  });

  useEffect(() => {
    props.setCompletedDesignations(isComplete());
  }, [manufacturerFormDetails]);

  const isComplete = () => {
    var completed = true;

    var openForms = _.map(manufacturerFormDetails, (manufacturer) => {
      return _.filter(manufacturer, (form) => {
        return form.open;
      });
    });

    // remove empty arrays and flatten
    var filteredForms = openForms.filter((e) => e.length).flat();

    if (filteredForms.length) {
      completed = _.every(filteredForms, ["completed", true]);
    }

    return completed;
  };

  const renderYutrepia = () => {
    return (
      <YutrepiaDesignationForm
        entities={props.entities}
        kind={"yutrepia"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("yutrepia"))}
      />
    );
  };

  return (
    <div>
      <div className="designation_form__section__header">
        <div className="designation_form__section__header__logo">
          <img src={Liquidia} />
        </div>
        <div className="designation_form__section__header__title">{vanity_manufacturer_name}</div>
      </div>
      {renderYutrepia()}
    </div>
  );
};

export default LiquidiaDesignations;
