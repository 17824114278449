import React, { useState, useEffect } from "react";
import _ from "lodash";

const TabBar = (props) => {
  const renderTag = (tab) => {
    if (props.displayTabBarTags && tab == props.displayTagsFor) {
      return (
        <div
          className="tag tag--small tag--orange"
          style={{ position: "relative", top: -2, marginLeft: 5 }}
        >
          <i className="solid solid-budicon-alert" />
        </div>
      );
    }
  };

  const renderTabs = () => {
    return props.tabs.map((tab) => (
      <div
        key={tab}
        className={
          tab == props.activeTab
            ? "tabs__item tabs__item--active"
            : "tabs__item"
        }
        onClick={() => {
          props.setActiveTab(tab);
        }}
      >
        {tab}
        {renderTag(tab)}
      </div>
    ));
  };

  return (
    <div className="tabs__container" style={{ ...props.style }}>
      {renderTabs()}
    </div>
  );
};
export default TabBar;