import React, { useState } from "react";
import useOnOutsideClick from "./Data/hooks/useOnOutsideClick";
import { SettingsOutlined, QuizOutlined, PrivacyTipOutlined, ExitToAppOutlined, ArticleOutlined } from "@mui/icons-material";

const ProfileIndicator = ({ current_user, organization, faqs_path, terms_path, privacy_path }) => {
  const [displayViewMore, setDisplayViewMore] = useState(false);

  const initials = `${current_user.first_name} ${current_user.last_name}`
    ?.match(/(\b\S)?/g)
    .join("")
    .toUpperCase();

  const ViewMore = () => {
    const { containerRef } = useOnOutsideClick(() => setDisplayViewMore(false));

    return (
      <>
        {displayViewMore && (
          <div
            className="header__notifications__container animated delay-1 fadeInDown--small"
            style={{ right: "10px", height: "auto", width: 200, left: "unset" }}
            ref={containerRef}
          >
            <div className="header__notifications__container__item">
              <div className="header__notifications__container__item__content">
                <div className="flex flex-col item-start">
                  <a
                    className="t--md t--hover t--full"
                    href={`/organizations/${organization.id}/edit`}
                  >
                    <SettingsOutlined /> Settings
                  </a>

                  <a className="t--md t--hover t--full" href={faqs_path} target="_blank">
                    <QuizOutlined /> FAQs
                  </a>
                  <a className="t--md t--hover t--full" href={terms_path} target="_blank">
                    <ArticleOutlined /> Terms of Use
                  </a>
                  <a className="t--md t--hover t--full" href={privacy_path} target="_blank">
                    <PrivacyTipOutlined /> Privacy Policy
                  </a>
                  <a className="t--md t--hover t--full" href="/logout">
                    <ExitToAppOutlined /> Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className="profile__avatar profile__avatar--space-right"
      style={{ marginRight: -2, cursor: "pointer" }}
    >
      <div className="profile__avatar__initials" onClick={() => setDisplayViewMore(true)}>
        {initials}
      </div>
      <ViewMore />
    </div>
  );
};

export default ProfileIndicator;
