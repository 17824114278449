import _ from "lodash";
import { PharmacyEncounterClaim, tableHeaders } from "../../schemas/pharmacy_encounter_claim";

import { formatDate, normalizeRxNumber, generateSecureHash } from "../../utils/data_helpers";
import { isDateInRange, areDatesWithinRange } from "../../validators/data_validations";
import { OriginalData } from "../../schemas/original_data";
import isValidEncounterClaimField from "../../validators/is_valid_encounter_claim_field";
import { isConformingDateOfEncounter, isValidClaim } from "../../validators/data_validations";

/* 
PURPOSE:
1. Filter out rows that don't match filterlist (ndcs, etc.)
2. Normalize data to conform to schema (adds 2 custom columns)
3. Validate Data (valid cell values, valid column mappings, etc.)
4. Format necessary values (format dates, remove dash from rx numbers)
5. Hash/de-itentify necessary cells
  */

const EncounterClaimsEngine = ({ data, mapping, filterList, filterKey, salt }) => {
  var hasErrors = false;
  var noMappingError = false;
  var result = { originalData: [], formattedData: [] };

  const process = () => {
    // skip data validation if there are no mappings
    if (!mapping) {
      _.each(data, (row) => {
        result.originalData.push(setOriginal(row, mapping, false));
      });

      noMappingError = true;
      hasErrors = true;

      return result;
    }

    _.each(data, (row) => {
      const { claim, originalData } = processRow(row, mapping);

      if (inList(row)) {
        result.formattedData.push(claim);
      }
      result.originalData.push(originalData);
    });

    return result;
  };

  const inList = (row) => {
    return row[mapping[filterKey]] in filterList;
  };

  const processRow = (row, mapping) => {
    let claim = new PharmacyEncounterClaim();
    let originalData = setOriginal(row, mapping, inList(row));

    _.forIn(claim.data, (_, columnName) => {
      setClaimData(row, claim, columnName, mapping, salt);

      setValidationErrors(row, claim, originalData, columnName, mapping);
    });

    return {
      claim,
      originalData,
    };
  };

  const setOriginal = (row, mapping, selected) => {
    return new OriginalData(row, mapping, selected);
  };

  const setClaimData = (row, claim, columnName, mapping, salt) => {
    claim.data[columnName] = formatValue(row, columnName, row[mapping[columnName]], mapping, salt);

    // claim.data[columnName] = row[mapping[columnName]];
  };

  const setValidationErrors = (row, claim, originalData, columnName, mapping) => {
    var result = isValidEncounterClaimField(row[mapping[columnName]], columnName, row, mapping);
    if (!result) {
      claim.columns_with_errors[columnName] = true;
      claim.hasErrors = true;

      originalData.columns_with_errors[mapping[columnName]] = true;
      originalData.hasErrors = true;

      hasErrors = true;
    }
  };

  const formatValue = (row, columnName, value, mapping, salt) => {
    if (columnName == "encounter_conforms") {
      return isConformingDateOfEncounter(row[mapping["date_of_encounter"]], row[mapping["date_of_service"]]).toString();
    }

    if (columnName == "claim_conforms_flag") {
      return isValidClaim(row[mapping["date_of_service"]]).toString();
    }

    if (["date_of_service", "date_prescribed", "date_of_encounter"].includes(columnName)) {
      return formatDate(value);
    }


    if (["rx_number"].includes(columnName)) {
      return generateSecureHash(value, salt);
    }

    if (["formatted_rx_number"].includes(columnName)) {
      return generateSecureHash(normalizeRxNumber(row[mapping["rx_number"]]), salt);
    }

    return value;
  };

  return {
    data: process(),
    hasErrors: hasErrors,
    noMappingError: noMappingError,
    columnNames: tableHeaders,
  };
};

export default EncounterClaimsEngine;
