import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";
import CloseIcon from "@material-ui/icons/Close";

const SecondaryModal = (props) => {
  const renderTitle = () => {
    if (props.title) {
      return props.title;
    } else {
      return "Title Placeholder";
    }
  };

  const renderSubtitle = () => {
    if (props.subtitle) {
      return props.subtitle;
    } else {
      return "Subtitle Placeholder";
    }
  };

  const renderHeader = () => {
    return (
      <div className="modal__content__header">
        <div className="modal__content__header--container">
          <div className="t--xl t--primary mt-1" style={{ fontSize: "24px" }}>
            {renderTitle()}
          </div>
          <div className="t--subtitle t--md mb-2 mt--16">{renderSubtitle()}</div>
        </div>
      </div>
    );
  };

  const renderClose = () => {
    if (props.showCloseIcon) {
      return (
        <div
          className="btn btn--sqr btn--sqr--modal"
          style={{ boxShadow: "none", border: "none", top: 32, right: 32 }}
          onClick={() => props.setDisplayModal(false)}
        >
          <CloseIcon className="t--sm" />
        </div>
      );
    }
  };
  const renderBody = () => {
    return <div className="modal__content__body">{props.body}</div>;
  };

  const renderFooter = () => {
    return <div className="modal__content__footer p--0">{props.footer}</div>;
  };

  const renderBillboard = () => {
    return <div className="modal__billboard">{props.billboard}</div>;
  };

  return (
    <div className="modal__background" data-testid="modal-continer">
      <div className="modal__container" style={{ width: props.width ? props.width : "680px" }}>
        {renderBillboard()}
        <div className="modal__content modal__content--padded">
          {renderClose()}
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default SecondaryModal;
