import React, { useState } from "react";
import CofirmationModal from "./library/confirmation_modal";
import BackgroundImage from "~/images/modal-background-image.png";

const ConfirmModal = (props) => {
  const modalBody = () => {
    return <div className="t--md t--gray pt-3 pb-3">{props.body}</div>;
  };

  const modalFooter = () => {
    return (
      <div className="flex justify-end" style={{ gap: 20 }}>
        <button
          className="btn btn--light-outline btn--small t--md t--500"
          style={{ lineHeight: "inherit", alignSelf: "center", height: "100%" }}
          onClick={props.secondaryAction}
        >
          Cancel
        </button>
        <button className="btn btn--small" onClick={props.primaryAction}>
          {props.isDeleting ? (
            <div className="loading-indicator loading-indicator--no-margin"></div>
          ) : (
            props.primaryActionText
          )}
        </button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex align-end justify-end mr--64" style={{ height: "100%" }}>
        <img src={BackgroundImage} height={"50px"} />;
      </div>
    );
  };

  if (props.displayModal) {
    return (
      <CofirmationModal
        title={props.title}
        header={renderHeader()}
        footer={modalFooter()}
        body={modalBody()}
        width="400px"
        showCloseIcon={true}
        setDisplayModal={props.setDisplayModal}
      />
    );
  }

  return null;
};

export default ConfirmModal;
