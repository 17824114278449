import React from "react";

const HeaderCell = ({ header, title, viewOriginal, warnings, mapping }) => {
  // const headerValue = header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());

  const renderColumnMappingWarning = (
    columnName,
    columnTitle,
    columnMapping,
    warnings
  ) => {
    // TODO: Needs refactor. Here and several other places, it would be helpful to have a reverse columnMapping for the original view

    const getHeaderValue = () => {
      if (!viewOriginal || !columnMapping || !columnMapping[columnName]) {
        return columnTitle;
      }

      return columnMapping[columnName];
    };

    if (warnings && warnings[columnName]) {
      return (
        <div>
          <div
            className="tag__info tag__info--lg tag__info--lg--orange"
            style={{ marginRight: 5, verticalAlign: "middle" }}
          >
            <div
              className={
                columnName == "contracted_entity_id"
                  ? "tooltip tooltip--bottom--last tooltip--fast"
                  : "tooltip tooltip--bottom tooltip--fast"
              }
            >
              <div className="tooltip__container">
                <div className="tooltip__container__title">You mapped</div>
                <div className="tooltip__container__subtitle tooltip__container__subtitle--bold-orange">
                  {columnMapping[columnName]}
                </div>
              </div>
            </div>
          </div>
          {getHeaderValue()}
        </div>
      );
    } else {
      return getHeaderValue();
    }
  };

  return (
    <th
      className="data-table__head__column"
      style={{ width: header.getSize() }}
    >
      {renderColumnMappingWarning(header.id, title, mapping, warnings)}
    </th>
  );
};

export default HeaderCell;
