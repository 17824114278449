import React from "react";
import { Skeleton } from "@mui/material";

const TableLoadingSkeleton = ({ rows = 10, columns = 5, ...props }) => {
  return Array.from(Array(rows)).map((_, index) => (
    <tr key={index}>
      {Array.from(Array(columns)).map((_, index) => (
        <td key={index} style={props.cellStyle}>
          <Skeleton animation="wave" />
        </td>
      ))}
    </tr>
  ));
};

export default TableLoadingSkeleton;
