import React from "react";

import tutorialPromptImg from "~/images/column_mapping_tutorial_prompt.png";

const MappingTutorialPrompt = ({ handleClose, goToMapping }) => {
  const handleGoToMapping = () => {
    handleClose();
    goToMapping();
  };

  return (
    <div className="modal__background modal__background--light">
      <div className="tutorial_modal__container">
        <div className="tutorial_modal__image-container">
          <img src={tutorialPromptImg} />
        </div>
        <div className="tutorial_modal__body">
          <h2 className="tutorial_modal__header">No Column Mapping Saved</h2>
          <div className="tutorial_modal__description t--md t--500">
            <div className="tutorial_modal__description t--md t--500">
              In order to make a successful submission, you first need to set up at least one column
              mapping. Head over to “Manage Column Mappings” in order to get started.
            </div>
          </div>
          <div className="tutorial_modal__button-container tutorial_modal__button-container--flex-end">
            <div className="tutorial_modal__button-container__navigation">
              <button className="btn btn--white btn--light-border" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn" onClick={handleGoToMapping}>
                Go to Column Mappings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingTutorialPrompt;
