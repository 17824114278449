import React from 'react';

export const Illustration = () => (
    <svg width="231" height="161" viewBox="0 0 231 161" fill="none">
      <path
        d="M56.706 93.464v-1.628h-.651v1.628h-1.628v.65h1.628v1.628h.65v-1.627h1.628v-.651h-1.627zM223.839 86.14v-1.627h-.651v1.628h-1.627v.65h1.627v1.628h.651v-1.627h1.628v-.651h-1.628zM52.637 16.163H51.01v-1.628h-.651v1.628H48.73v.65h1.628v1.628h.65v-1.627h1.628v-.651zM62.565 111.365a2.285 2.285 0 0 1-2.279-2.278 2.285 2.285 0 0 1 2.279-2.278 2.285 2.285 0 0 1 2.278 2.278 2.285 2.285 0 0 1-2.279 2.278zm0-3.905c-.896 0-1.628.732-1.628 1.627s.732 1.627 1.628 1.627c.895 0 1.627-.732 1.627-1.627s-.732-1.627-1.627-1.627zM201.381 18.93a2.285 2.285 0 0 1-2.278-2.28 2.285 2.285 0 0 1 2.278-2.277 2.286 2.286 0 0 1 2.279 2.278 2.286 2.286 0 0 1-2.279 2.278zm0-3.906c-.895 0-1.627.732-1.627 1.627s.732 1.627 1.627 1.627 1.628-.732 1.628-1.627-.733-1.627-1.628-1.627zM217.98 99.974a2.285 2.285 0 0 1-2.278-2.279 2.285 2.285 0 0 1 2.278-2.278 2.285 2.285 0 0 1 2.279 2.278 2.285 2.285 0 0 1-2.279 2.279zm0-3.906c-.895 0-1.627.732-1.627 1.627s.732 1.628 1.627 1.628c.896 0 1.628-.733 1.628-1.628 0-.895-.732-1.627-1.628-1.627zM210.901 31.249c0-.033.017-3.45 3.564-3.483 2.93-.032 3.255-2.669 3.271-2.783l.651.066c0 .032-.39 3.336-3.905 3.368-2.913.033-2.93 2.702-2.93 2.832h-.651zM9.674 117.582c-.488 0-.83-.081-.846-.081l.162-.635c.114.032 2.702.635 3.727-2.116 1.253-3.336 4.46-2.18 4.492-2.164l-.228.602c-.114-.033-2.637-.928-3.662 1.79-.83 2.246-2.587 2.604-3.645 2.604z"
        fill="#25265E"
      />
      <path
        d="M208.379 41.453v76.211a7.376 7.376 0 0 1-7.372 7.372H88.31a7.376 7.376 0 0 1-7.372-7.372V41.453a7.376 7.376 0 0 1 7.372-7.372h112.697a7.376 7.376 0 0 1 7.372 7.372z"
        fill="#fff"
      />
      <path
        d="M201.007 125.361H88.31a7.702 7.702 0 0 1-7.698-7.698v-76.21a7.702 7.702 0 0 1 7.698-7.698h112.697a7.702 7.702 0 0 1 7.698 7.697v76.211a7.702 7.702 0 0 1-7.698 7.698zM88.327 34.406a7.05 7.05 0 0 0-7.047 7.047v76.21a7.05 7.05 0 0 0 7.046 7.047h112.697a7.05 7.05 0 0 0 7.047-7.047v-76.21a7.05 7.05 0 0 0-7.047-7.047H88.326z"
        fill="#25265E"
      />
      <path
        d="M208.379 41.453v4.817H80.954v-4.817a7.376 7.376 0 0 1 7.372-7.372h112.697c4.053 0 7.356 3.303 7.356 7.372zM196.499 68.565H88.115v.65h108.384v-.65zM196.499 79.306H88.115v.65h108.384v-.65zM196.499 90.046H88.115v.651h108.384v-.65zM196.499 100.787H88.115v.651h108.384v-.651z"
        fill="#25265E"
      />
      <path d="M175.832 94.115h10.74l-5.37-8.788-5.37 8.788z" fill="#fff" />
      <path
        d="M187.158 94.44h-11.896l5.956-9.731 5.94 9.731zm-10.741-.65h9.586l-4.785-7.845-4.801 7.844z"
        fill="#25265E"
      />
      <path d="M121.802 85.457h-8.951v8.349h8.951v-8.349z" fill="#3246D3" />
      <path d="M105.203 75.368h-8.951v8.348h8.951v-8.348z" fill="#fff" />
      <path
        d="M105.528 84.025h-9.602v-9h9.602v9zm-8.95-.65h8.299v-7.698h-8.3v7.697z"
        fill="#25265E"
      />
      <path
        d="M91.207 41.713a1.465 1.465 0 1 0 0-2.93 1.465 1.465 0 0 0 0 2.93zM96.74 41.713a1.465 1.465 0 1 0 0-2.93 1.465 1.465 0 0 0 0 2.93zM102.273 41.713a1.464 1.464 0 1 0 0-2.928 1.464 1.464 0 0 0 0 2.928z"
        fill="#fff"
      />
      <path
        d="M215.148 143.381a2.115 2.115 0 1 0-.966-4.119 2.115 2.115 0 0 0 .966 4.119zM208.265 160.887l-.455-.683c5.679-3.743 7.014-14.484 7.014-14.598l.813.098c-.032.455-1.383 11.245-7.372 15.183z"
        fill="#25265E"
      />
      <path
        d="M217.98 141.131s-3.58-.912-6.525.846c0 0 .227 4.866 3.547 4.443 3.792-.472 2.978-5.289 2.978-5.289z"
        fill="#3246D3"
      />
      <path
        d="M211.373 156.982s1.221-3.06.472-5.11c-.764-2.051-2.229-2.393-2.717-2.132-.489.26-1.351.732-.505 2.62.863 1.888 2.75 4.622 2.75 4.622z"
        fill="#25265E"
      />
      <path
        d="M202.992 144.516s-1.057-2.214 0-5.078c1.058-2.848 1.791-11.717-4.54-11.294-6.331.423-6.754 8.869-6.119 13.198.635 4.329-.309 7.291-1.367 9.716-1.058 2.425-4.443 4.231-3.906 9.618h21.449c-.032 0 1.026-8.56-5.517-16.16z"
        fill="#fff"
      />
      <path
        d="M208.77 161.001h-22.035l-.033-.293c-.39-3.922 1.269-6.037 2.588-7.746.537-.7 1.041-1.351 1.35-2.034 1.074-2.458 1.97-5.306 1.351-9.537-.504-3.45-.277-8.479 2.034-11.327 1.107-1.367 2.588-2.131 4.394-2.245 1.823-.114 3.239.472 4.199 1.773 2.067 2.767 1.448 7.861.667 9.976-.927 2.507-.146 4.492-.032 4.785 6.526 7.616 5.549 16.29 5.533 16.372l-.016.276zm-21.433-.651h20.847c.097-1.53.276-8.999-5.436-15.623l-.032-.032-.017-.033c-.048-.098-1.106-2.376-.016-5.321.716-1.937 1.302-6.836-.586-9.358-.83-1.107-2.05-1.611-3.629-1.513-1.627.113-2.945.781-3.938 2.001-2.181 2.685-2.376 7.519-1.888 10.822.651 4.411-.342 7.47-1.399 9.895-.326.749-.863 1.448-1.433 2.164-1.285 1.66-2.734 3.532-2.473 6.998z"
        fill="#25265E"
      />
      <path d="M198.387 128.095s-4.117 10.35 0 32.532v-32.532z" fill="#fff" />
      <path
        d="M198.078 160.676c-2.132-11.49-2.018-19.757-1.546-24.672.52-5.338 1.529-7.925 1.562-8.039l.602.244c-.049.098-4.036 10.545.016 32.353l-.634.114z"
        fill="#25265E"
      />
      <path
        d="m193.078 132.8-.491.428 3.882 4.453.491-.428-3.882-4.453zM192.499 135.802l-.489.43 4.34 4.94.489-.43-4.34-4.94zM203.572 145.321l-6.491 8.228.511.404 6.491-8.229-.511-.403zM204.883 147.234l-7.413 9.179.506.409 7.413-9.179-.506-.409z"
        fill="#25265E"
      />
      <path
        d="M87.431 160.448s-1.074-4.866 1.628-9.602c0 0 1.48-4.605-.944-8.788-2.441-4.198 0-8.934 2.848-9.601 2.848-.684 6.217 1.627 7.03 4.866.814 3.238.13 5.549 5.013 7.974 4.882 2.425 4.67 15.151 4.67 15.151H87.431z"
        fill="#fff"
      />
      <path
        d="M107.969 160.773H87.171l-.049-.26c-.049-.212-1.074-5.029 1.644-9.797.114-.391 1.302-4.654-.928-8.495-1.22-2.099-1.383-4.557-.44-6.754.733-1.709 2.067-2.994 3.483-3.32 3.043-.716 6.575 1.709 7.421 5.11.163.635.26 1.237.358 1.807.407 2.359.7 4.068 4.476 5.956 4.996 2.49 4.849 14.923 4.849 15.444l-.016.309zm-20.277-.651h19.626c-.016-1.969-.277-12.433-4.492-14.532-4.068-2.035-4.426-4.069-4.817-6.429-.097-.569-.195-1.139-.358-1.757-.765-3.076-3.938-5.273-6.64-4.638-1.22.293-2.376 1.416-3.043 2.945-.862 2.002-.716 4.248.407 6.168 2.474 4.264 1.042 8.869.977 9.048l-.033.066c-2.262 3.97-1.79 8.071-1.627 9.129z"
        fill="#25265E"
      />
      <path
        d="M97.683 160.643c-4.035-8.804-7.03-28.089-7.062-28.284l.65-.098c.033.196 3.011 19.399 7.015 28.122l-.603.26zM76.722 145.215a2.115 2.115 0 1 0 .001-4.23 2.115 2.115 0 0 0 0 4.23zM87.415 160.691c-6.721-2.473-10.497-12.661-10.66-13.084l.765-.276c.033.097 3.808 10.268 10.172 12.612l-.277.748z"
        fill="#25265E"
      />
      <path
        d="m96.274 134.553-3.459 9.271.61.227 3.459-9.27-.61-.228zM98.042 138.966l-3.999 10.109.605.239 4-10.109-.606-.239z"
        fill="#25265E"
      />
      <path
        d="M73.436 143.669s3.287-1.708 6.542-.683c0 0 .895 4.784-2.441 5.142-3.776.407-4.102-4.459-4.102-4.459z"
        fill="#3246D3"
      />
      <path
        d="M68.293 143.523a1.92 1.92 0 1 0 0-3.841 1.92 1.92 0 0 0 0 3.841zM83.509 157.6s-1.888-2.702-1.627-4.866c.276-2.165 1.627-2.848 2.164-2.702.537.13 1.481.407 1.074 2.441-.407 2.018-1.611 5.127-1.611 5.127zM88.187 152.873l-.385.524 10.033 7.371.385-.525-10.033-7.37zM87.488 155.915l-.38.529 5.923 4.252.38-.529-5.923-4.252z"
        fill="#25265E"
      />
      <path
        d="M115.764 139.812s-1.351-2.848 0-6.493c1.35-3.645 2.294-15.021-5.81-14.467-8.121.537-8.658 11.359-7.844 16.908.814 5.55-.407 9.325-1.758 12.45-1.35 3.108-5.68 5.403-5.012 12.303h27.454s1.351-10.952-7.03-20.701z"
        fill="#fff"
      />
      <path
        d="M123.087 160.838h-28.04l-.032-.293c-.489-4.98 1.61-7.681 3.303-9.846.7-.895 1.351-1.741 1.742-2.62 1.367-3.157 2.538-6.818 1.741-12.27-.635-4.394-.358-10.806 2.588-14.435 1.399-1.725 3.271-2.685 5.565-2.848 2.295-.147 4.085.602 5.306 2.229 2.587 3.467 1.806 10.074.846 12.678-1.204 3.271-.179 5.875-.033 6.2a29.729 29.729 0 0 1 6.705 14.126c.716 3.955.391 6.672.374 6.786l-.065.293zm-27.454-.651h26.868c.131-1.774.456-11.506-6.981-20.163l-.033-.033-.016-.032c-.065-.114-1.399-3.011-.016-6.738.927-2.49 1.676-8.804-.749-12.059-1.074-1.448-2.669-2.099-4.735-1.969-2.1.146-3.825 1.009-5.094 2.604-2.799 3.466-3.076 9.667-2.441 13.93.83 5.615-.44 9.537-1.774 12.613-.423.96-1.09 1.839-1.823 2.766-1.676 2.132-3.564 4.541-3.206 9.081zM70.685 148.877s-4.215.341-4.898-.668c-.668-1.009 1.155-1.904 3.563-.374.342.212 1.335 1.042 1.335 1.042zM72.003 151.025s-4.052 1.204-4.93.358c-.863-.846.731-2.1 3.4-1.107.39.13 1.53.749 1.53.749z"
        fill="#25265E"
      />
      <path
        d="M80.954 160.773c-7.681-5.94-12.629-14.647-12.678-14.728l.716-.407c.05.082 4.915 8.658 12.466 14.484l-.504.651zM109.564 160.496c-2.734-14.712-2.588-25.29-1.986-31.571.651-6.835 1.953-10.123 2.002-10.269l.602.244c-.016.032-1.318 3.369-1.953 10.139-.602 6.232-.732 16.729 1.986 31.343l-.651.114z"
        fill="#25265E"
      />
      <path
        d="m102.999 124.894-.491.427 4.972 5.705.491-.428-4.972-5.704z"
        fill="#25265E"
      />
      <path
        d="M65.298 142.123s2.8-1.627 5.957-.618c0 0 .813 4.345-2.214 4.67-3.45.375-3.743-4.052-3.743-4.052z"
        fill="#3246D3"
      />
      <path
        d="m102.244 128.708-.489.429 5.553 6.321.489-.429-5.553-6.321zM116.571 140.917l-8.306 10.528.511.404 8.306-10.528-.511-.404zM118.266 143.362l-9.497 11.763.506.409 9.498-11.763-.507-.409z"
        fill="#25265E"
      />
      <path
        d="M142.144 60.916v20.18c-5.516-.065-9.976-4.557-9.976-10.09 0-5.533 4.46-10.025 9.976-10.09z"
        fill="#3246D3"
      />
      <path
        d="m126.424 58.068.862 2.604s-.52 2.083 2.246 6.412c0 0 .781.781 1.302.602 0 0 1.204.521 2.083.342 0 0 1.204 1.042 1.725.863 0 0 1.204 1.204 1.383 0 0 0-.699-2.084-1.725-3.109l-2.083-3.108s3.109 1.904 4.15 1.041l-2.246-2.083s-1.562-1.383-2.766-1.562l-5.371-20.603 2.767-3.808-7.095-5.533-3.923 7.144a3.648 3.648 0 0 0-.065 3.401l8.756 17.397z"
        fill="#fff"
      />
      <path
        d="M135.602 69.753c-.423 0-.846-.326-1.058-.52h-.016c-.57 0-1.351-.57-1.709-.863-.781.097-1.709-.228-2.002-.358-.65.097-1.35-.57-1.497-.716l-.049-.05c-2.506-3.921-2.376-6.07-2.31-6.558l-.83-2.49-8.74-17.38a3.991 3.991 0 0 1 .066-3.71l4.101-7.47 7.648 5.972-2.864 3.938 5.273 20.245c1.253.293 2.653 1.546 2.718 1.595l2.522 2.327-.293.244c-.765.635-2.294.114-3.368-.39l1.334 2.018c1.074 1.09 1.758 3.124 1.79 3.222l.016.065-.016.082c-.081.52-.325.716-.537.78-.032 0-.098.017-.179.017zm-.862-1.237.146.147c.26.26.635.472.732.44.017 0 .049-.05.082-.18-.131-.374-.765-2.05-1.628-2.913l-.048-.049-2.897-4.345 1.253.765c1.009.618 2.555 1.302 3.385 1.204l-1.872-1.725c-.016-.016-1.497-1.318-2.587-1.48l-.212-.033-.049-.212-5.403-20.75 2.669-3.677-6.542-5.11-3.743 6.802a3.334 3.334 0 0 0-.049 3.109l8.756 17.43.895 2.7-.016.098c0 .017-.424 2.018 2.197 6.12.26.26.732.569.943.504l.114-.033.114.049c.017 0 1.139.472 1.888.326l.163-.033.114.098c.553.472 1.236.862 1.416.797l.179-.049z"
        fill="#25265E"
      />
      <path
        d="m119.41 32.176 7.404 6.136 4.02-4.231h36.177s3.434-1.319 1.66-3.727c-.863-1.172-3.597-3.873-6.184-6.282-3.515-3.255-7.193-6.558-9.488-6.965-3.987-.7-8.837-.521-8.837-.521s-9.178-.342-17.315 4.508c.016.016-5.533 7.974-7.437 11.082z"
        fill="#3246D3"
      />
      <path
        d="M145.106 18.441c-.049-.13-3.288-3.482-3.288-3.482s.342-5.37-2.424-3.288l-2.995-3.352-7.193 7.063s5.045 11.896 10.383 5.712l1.204 1.562s1.709-.553 2.864-1.497c1.237-.976 1.449-2.718 1.449-2.718z"
        fill="#fff"
      />
      <path
        d="M136.383 23.21a3.18 3.18 0 0 1-.553-.05c-3.874-.602-6.787-7.355-6.9-7.648l-.082-.212 7.6-7.453 3.027 3.385c.618-.407 1.172-.472 1.627-.228 1.14.618 1.107 3.14 1.075 3.824 3.189 3.304 3.238 3.434 3.254 3.515l.017.066-.017.065c-.016.081-.244 1.871-1.546 2.929-1.188.96-2.897 1.53-2.978 1.546l-.211.065-1.107-1.432c-1.009 1.09-2.083 1.627-3.206 1.627zm-6.77-7.747c.505 1.123 3.141 6.558 6.314 7.063 1.188.179 2.344-.358 3.434-1.644l.26-.31 1.302 1.693c.489-.179 1.693-.667 2.555-1.367.912-.748 1.221-1.969 1.286-2.376-.374-.439-2.083-2.23-3.173-3.352l-.098-.098.016-.146c.065-1.107-.065-2.995-.732-3.353-.277-.146-.667-.032-1.172.358l-.244.18-2.962-3.32-6.786 6.672z"
        fill="#25265E"
      />
      <path
        d="M141.493 12.013c-1.041-1.383-2.864 0-2.571 1.432.293 1.432-.635 1.855-.635 1.855s-1.806-1.416-2.734-2.474c-.944-1.074-1.513.017-1.513.017-.765 3.873-3.727 3.58-3.727 3.58-4.898-2.116-1.806-6.802-.277-8.235 1.074-1.009 2.783-1.773 2.783-1.773-.016-.05-.049-.098-.065-.147a18.59 18.59 0 0 1-.391-.83c-1.057-2.327-.829-2.978-.292-3.938.341-.635 3.482-2.441 5.777-.879 1.578 1.074 1.09 3.271.618 4.557-.13.374-.26.65-.342.83-.048.097-.081.146-.081.146 3.857 2.279 3.45 5.859 3.45 5.859z"
        fill="#25265E"
      />
      <path
        d="m157.865 29.816-7.291 1.335-2.946.7-2.603-.863-4.508.863-2.344 2.245h29.163s4.069-.602.781-4.768l-5.37-4.85-5.631 4.59.749.748z"
        fill="#fff"
      />
      <path
        d="M167.385 34.406h-29.993l2.994-2.88 4.687-.896 2.588.863 2.864-.667 6.705-1.221-.537-.553 6.119-4.98 5.615 5.077c1.188 1.514 1.594 2.767 1.22 3.743-.504 1.27-2.132 1.53-2.197 1.53l-.065-.016zm-28.382-.651h28.333c.163-.033 1.335-.277 1.676-1.14.293-.732-.097-1.806-1.106-3.075l-5.143-4.638-5.142 4.182.895.928-7.877 1.448-3.027.716-2.604-.862-4.329.83-1.676 1.61z"
        fill="#25265E"
      />
      <path
        d="M138.434 5.178c-.131.374-.261.65-.342.83-1.058-.18-3.271-.407-5.354.26-.147-.293-.277-.57-.391-.83 1.139-.456 3.532-1.155 6.087-.26z"
        fill="#fff"
      />
      <path
        d="m132.559 6.675-.13-.244a11.556 11.556 0 0 1-.391-.83l-.146-.31.309-.13c1.237-.504 3.678-1.204 6.33-.276l.31.114-.114.309a6.139 6.139 0 0 1-.358.846l-.098.228-.244-.032c-1.09-.18-3.222-.391-5.208.26l-.26.065zm.211-1.042c.033.082.082.163.114.245 1.904-.537 3.857-.407 4.996-.228.033-.082.066-.163.114-.26-2.099-.635-4.052-.18-5.224.243z"
        fill="#25265E"
      />
      <path d="m32.197 151.155-.488 6.087h-6.347l.488-6.087h6.347z" fill="#fff" />
      <path
        d="M32.002 157.567h-6.998l.537-6.737h6.998l-.537 6.737zm-6.282-.651h5.696l.44-5.435h-5.697l-.439 5.435z"
        fill="#25265E"
      />
      <path
        d="M25.362 155.256s3.45 2.62 6.347 0l8.592 4.475c.375.195.245.765-.195.765H24.923c-.472 0-.83-.456-.7-.911l1.139-4.329z"
        fill="#25265E"
      />
      <path d="m47.983 151.155.651 6.087h-6.347l-.65-6.087h6.346z" fill="#fff" />
      <path
        d="M48.992 157.567h-6.998l-.716-6.737h6.998l.716 6.737zm-6.412-.651h5.696l-.586-5.435h-5.696l.586 5.435z"
        fill="#25265E"
      />
      <path
        d="M42.124 155.256s3.45 2.62 6.347 0l8.593 4.475c.374.195.244.765-.196.765H41.685c-.472 0-.83-.456-.7-.911l1.14-4.329zM39.211 71.364l6.835 32.597 5.208 48.008H40.513l-3.906-41.499-10.073-32.174 12.677-6.932z"
        fill="#25265E"
      />
      <path
        d="m24.256 48.466-.456-6.412s-3.824-1.985-3.352-4.735c.471-2.75 1.676-5.045 7.795-7.796 0 0 9.325 9.325 2.294 12.06l1.221 5.956-7.502.927z"
        fill="#fff"
      />
      <path
        d="m23.946 48.825-.472-6.575c-.765-.423-3.808-2.344-3.368-4.996.504-3.043 1.969-5.338 7.974-8.04l.212-.097.162.162c.228.228 5.745 5.794 4.931 9.732-.26 1.22-1.09 2.165-2.49 2.767l1.237 6.038-8.186 1.008zm4.199-18.91c-6.152 2.815-7.014 5.125-7.388 7.453-.407 2.49 3.157 4.377 3.19 4.394l.162.08.44 6.25 6.786-.83-1.205-5.875.26-.097c1.351-.521 2.116-1.319 2.344-2.393.684-3.238-3.694-8.039-4.589-8.983z"
        fill="#25265E"
      />
      <path
        d="m33.434 51.151 13.345 6.184 18.878-6.184v3.092L46.942 65.635l-19.692-8.3 6.184-6.184z"
        fill="#fff"
      />
      <path
        d="m46.974 65.993-20.294-8.56 6.689-6.688 13.442 6.233 19.187-6.282v3.727l-19.024 11.57zm-19.155-8.771 19.09 8.039L65.33 54.048v-2.457l-18.569 6.086-13.247-6.135-5.696 5.68zM23.8 38.995s1.074 1.676 0 3.06c0 0-10.383-2.605-5.5-10.074 0 0 3.97-7.34 8.397-6.266 4.426 1.074 6.135 4.915 3.743 6.429-2.376 1.513-6.2 3.661-6.2 3.661l.764 1.676-.456.456s-2.294-1.367-2.603-.456c-.293.912.325 2.279 1.855 1.514zM23.914 151.969l2.603-44.591-6.672-27.34 16.762-6.51 3.906 30.921-6.347 47.52H23.914z"
        fill="#25265E"
      />
      <path
        d="M53.516 66.335 29.838 88.533c-1.156 1.074-2.962 1.025-4.036-.13l-11.31-12.14-.798-.847a2.85 2.85 0 0 1 .13-4.02c5.159-4.833 18.503-17.348 23.679-22.197a2.84 2.84 0 0 1 4.035.146l.863.928 11.23 12.059a2.815 2.815 0 0 1-.115 4.003z"
        fill="#3246D3"
      />
      <path
        d="M42.143 57.108 20.977 76.814l.777.833 21.165-19.705-.776-.834zM32.294 70.01l-9.458 8.804.777.834 9.457-8.804-.776-.834zM44.002 59.114l-10.78 10.035.777.834 10.78-10.035-.777-.834zM45.862 61.115 24.696 80.82l.776.834L46.639 61.95l-.776-.834zM47.71 63.107 26.546 82.812l.776.834L48.487 63.94l-.776-.833z"
        fill="#fff"
      />
      <path
        d="M42.417 50.257 14.491 76.262l-.797-.846a2.85 2.85 0 0 1 .13-4.02c5.159-4.833 18.503-17.348 23.679-22.197a2.84 2.84 0 0 1 4.035.146l.88.912z"
        fill="#25265E"
      />
      <path
        d="M32.45 58.052a.814.814 0 1 0-1.11-1.191.814.814 0 0 0 1.11 1.19zM35.247 55.446a.814.814 0 1 0-1.109-1.191.814.814 0 0 0 1.11 1.19zM38.046 52.84a.814.814 0 1 0-1.109-1.191.814.814 0 0 0 1.109 1.19z"
        fill="#fff"
      />
      <path
        d="m58.87 58.621 16.502-10.138-16.55-8.43.048 18.568z"
        fill="#3246D3"
      />
      <path
        d="M37.356 75.14s1.92 3.987 3.092 4.133c0 0 1.025.293 1.025-.732 0 0 1.628.293 1.481-1.025 0 0 1.172.146 1.172-.88 0 0 1.334-.146 1.025-1.171-.293-1.025-1.334-2.36-1.334-2.36s1.334 1.025 2.213.586c0 0-1.025-2.213-2.8-3.238 0 0-1.773-.44-3.84.732l-6.64-.879v5.159l4.606-.326z"
        fill="#fff"
      />
      <path
        d="M40.757 79.631c-.179 0-.309-.032-.374-.049-1.188-.179-2.734-3.124-3.222-4.1l-4.703.308v-5.875l6.9.912c2.115-1.156 3.905-.733 3.97-.716l.05.016.032.016c1.839 1.074 2.88 3.288 2.93 3.385l.13.293-.277.147c-.423.211-.912.162-1.351.016.26.423.505.911.635 1.367.13.456 0 .765-.114.96-.244.358-.651.521-.928.586-.065.31-.211.504-.342.618-.244.228-.57.293-.813.31-.033.341-.196.57-.342.7-.342.325-.846.374-1.172.357a.955.955 0 0 1-.341.554 1.17 1.17 0 0 1-.668.195zm-3.206-4.833.098.195c.765 1.579 2.148 3.857 2.848 3.938l.049.017c.081.016.358.065.488-.05.081-.064.13-.194.13-.373v-.391l.39.065c.18.032.717.065.96-.163.05-.032.164-.162.131-.52l-.049-.407.407.048c.114.017.472.017.667-.162.098-.082.147-.228.147-.407v-.293l.293-.033c.146-.016.57-.114.716-.358.065-.114.081-.244.032-.423-.276-.96-1.253-2.23-1.27-2.246l-1.594-2.05 2.05 1.595c.261.211.977.634 1.53.634-.358-.634-1.22-1.985-2.44-2.717-.261-.05-1.807-.293-3.548.716l-.098.049-6.38-.847v4.443l4.443-.26z"
        fill="#25265E"
      />
      <path
        d="M36.689 77.76v-7.486l-11.148-2.767-.78-6.558L37.827 48.71s-1.79-3.092-6.265-3.825c0 0-.977 4.882-7.65 1.465L16.64 51.2a2.07 2.07 0 0 0-.91 1.904l1.675 18.308a3.578 3.578 0 0 0 2.897 3.19L36.69 77.76z"
        fill="#fff"
      />
      <path
        d="m37.014 78.15-16.778-3.222a3.923 3.923 0 0 1-3.157-3.483l-1.677-18.308a2.35 2.35 0 0 1 1.058-2.197l7.437-4.964.163.082c2.344 1.204 4.199 1.48 5.517.862 1.35-.65 1.66-2.083 1.66-2.1l.065-.308.31.048c4.572.75 6.411 3.857 6.493 3.988l.13.227L25.102 61.08l.732 6.167 11.18 2.767v8.137zM23.93 46.725l-7.128 4.752c-.537.358-.83.976-.765 1.611l1.676 18.308a3.3 3.3 0 0 0 2.637 2.913l15.997 3.076V70.55l-11.115-2.766-.83-6.95 12.986-12.156c-.488-.667-2.213-2.75-5.614-3.401-.179.537-.684 1.643-1.92 2.245-1.465.684-3.45.424-5.924-.797z"
        fill="#25265E"
      />
      <path
        d="M60.872 57.433s1.97.342 2.93-.293c0 0 .748 0 .96-.634 0 0 .846 0 1.171-.749 0 0 .749-.114.96-.749 0 0 .847-.211.847-.748 0 0 0-2.653-.326-3.174-.325-.537-1.383-.537-1.48.114 0 0-.96-.211-1.059.749 0 0-1.171-.212-1.057.748 0 0-.96-.211-.847.75l.342 1.74-2.44 2.246z"
        fill="#fff"
      />
      <path
        d="M62.028 57.856c-.635 0-1.172-.081-1.205-.098l-.65-.113 2.782-2.588-.325-1.595c-.05-.488.114-.748.276-.895.18-.163.407-.211.586-.228a.804.804 0 0 1 .26-.455c.245-.228.603-.277.863-.277.098-.277.244-.44.39-.537.245-.18.522-.212.717-.212.13-.227.374-.39.683-.455.44-.082 1.026.065 1.302.52.342.586.358 2.897.358 3.353 0 .602-.618.895-.927 1.009-.245.488-.716.683-.977.765-.309.553-.862.716-1.172.765-.276.52-.846.634-1.074.65-.537.31-1.269.391-1.887.391zm-.407-.667c.65.032 1.513 0 2.001-.325l.082-.05h.097c.017 0 .505-.016.651-.406l.082-.228h.228c.016 0 .634-.016.862-.537l.065-.163.18-.032s.553-.098.699-.521l.049-.163.179-.049c.211-.049.602-.228.602-.423 0-1.237-.098-2.734-.277-3.01-.097-.163-.374-.26-.618-.212-.114.016-.244.065-.26.212l-.05.341-.341-.081c-.081-.016-.326-.033-.488.081-.098.065-.163.196-.18.375l-.032.341-.342-.065c-.146-.032-.472-.032-.618.098-.016.016-.081.081-.065.293l.049.455-.44-.097c-.081-.017-.293-.033-.39.049-.082.065-.065.227-.065.325l.39 1.92-2.05 1.872zM231 160.35H.56v.651H231v-.651z"
        fill="#25265E"
      />
    </svg>
  );

  export const FileIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="31" viewBox="0 0 24 31">
      <g transform="translate(-0.223)">
        <path
          d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z"
          transform="translate(2.541)"
          fill="#E2EAFF"
        />
        <path d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z" transform="translate(19.089)" fill="#9DB7FF" />
        <g transform="translate(0 14.401)">
          <rect width="21" height="10" rx="2" transform="translate(0.223 0.344)" fill="#3360D9" />
          <text
            transform="translate(5 7)"
            fill="#ffffff"
            fontSize="6"
            fontFamily="Roboto-Bold, Roboto"
            fontWeight="700"
            letterSpacing="0.007em"
          >
            <tspan x="0" y="0">
              FILE
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );