import React from "react";
import ReportCard from "./ReportCard";

export default function ReportList() {
  const submissions = [
    {
      name: "Pharmacy Submission Details",
      link: "reports/claim_details",
      subtitle: "View submitted Pharmacy Claims",
    },
    {
      name: "Medical Submission Details",
      link: "reports/medical_claim_details",
      subtitle: "View submitted Medical Claims",
    },
    {
      name: "Pharmacy Eligibility Submission Details",
      link: "reports/encounter_eligibility_details",
      subtitle: "View submitted Eligibility Data",
      badge: "Eligibility Data",
    },
    {
      name: "Medical Eligibility Submission Details",
      link: "reports/medical_eligibility_details",
      subtitle: "View submitted Eligibility Data",
      badge: "Eligibility Data",
    },
  ];

  const contracts = [
    {
      name: "At-Risk Contract Pharmacies",
      link: "reports/at_risk",
      subtitle:
        "View Contract Pharmacies identified as having incomplete 340B Claims Submissions",
    },
    {
      name: "Single-Contract Pharmacies Designations",
      link: "reports/designations",
      subtitle: "View designations for all manufacturers",
    },
  ];



  return (
    <>
      <div className="">
        <h4 className="t--sm t--upcase mt--32">submissions</h4>
        <ReportCard reports={submissions} />

        <h3 className="t--sm t--upcase mt--32">contract pharmacies</h3>
        <ReportCard reports={contracts} />

      </div>
    </>
  );
}
