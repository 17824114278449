import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const LoginForm = (props) => {
  return (
    <div>
      <form method="POST" action="/login">
        <CustomTextField
          label="Email Address"
          margin="normal"
          variant="outlined"
          id="email"
          name="session[email]"
          fullWidth
          required
          autoFocus
        />
        <CustomTextField
          label="Password"
          margin="normal"
          variant="outlined"
          id="password"
          fullWidth
          required
          name="session[password]"
          type="password"
        />
        <div className="register__form__footnote">
          By clicking ‘Login’ you agree to our Terms of Use and Privacy Policy
        </div>

        <div className="register__form__actions">
          <a href="/register">
            <div className="btn btn--outline btn--white">
              Don't have an account?
            </div>
          </a>
          <button
            className="btn"
            id="login"
            style={{ flexGrow: 1, marginLeft: 30 }}
            type="submit"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
