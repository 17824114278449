import { formatDate } from "../utils/data_helpers";
import _ from "lodash";

// Check
// TODO: This function doesn't care if the date arg is before or after the current date. Should we update this to return false if date arg is in the future?
export const isDateInRange = (date, range) => {
  if (isNaN(Date.parse(formatDate(date)))) return;

  // The number of milliseconds in one day
  const timeInDay = 1000 * 60 * 60 * 24;

  const dateToday = formatDate(Date());
  const daysBetween = (Date.parse(dateToday) - Date.parse(formatDate(date))) / timeInDay;

  // daysBetween must be positive number
  if (daysBetween >= 0 && daysBetween <= range) {
    return true;
  } else {
    return false;
  }
};

export const areDatesWithinRange = (date1, date2, range) => {
  const formattedDate1 = formatDate(date1);
  const formattedDate2 = formatDate(date2);
  if (isNaN(Date.parse(formattedDate1)) || isNaN(Date.parse(formattedDate2)) || isNaN(range)) return;

  const millisecondsInDay = 1000 * 60 * 60 * 24;

  const daysBetween = (Date.parse(formattedDate2) - Date.parse(formattedDate1)) / millisecondsInDay;

  // date1 must be earlier than date2
  if (daysBetween < 0 || daysBetween > range) {
    return false;
  } else {
    return true;
  }
};

export const isValidNDC = (val) => {
  if (!val) return false;
  // if it contains a dash, use one of the following formats
  if (val.includes("-")) {
    // 3 valid format strings with dashes
    var reg1 = /^\d{5}-\d{4}-\d{2}$/;
    var reg2 = /^^\d{6}-\d{3}-\d{2}$$/;
    var reg3 = /^^\d{6}-\d{4}-\d{1}$$/;

    return reg1.test(val) || reg2.test(val) || reg3.test(val);
  } else {
    var reg = /^\d+$/;
    if (reg.test(val) == false) {
      return false;
    }

    return val.length == 11;
  }
};

export const isValidDateOfService = (val, datePrescribed) => {
  if (!isValidDate(val)) {
    return false;
  }
  return !isQuestionableDateOfService(val, datePrescribed);
};

export const isValidDate = (val) => {
  return !isNaN(Date.parse(val)) && Date.parse(val) > 0;
};

export const isQuestionableDateOfService = (val, datePrescribed) => {
  if (isNaN(Date.parse(val)) || isNaN(Date.parse(datePrescribed))) return true;

  var today = Date.parse(Date());

  var dos = Date.parse(formatDate(val));
  var dp = Date.parse(formatDate(datePrescribed));

  if (dos > today) {
    return true;
  } else if (dos < dp) {
    return true;
  } else {
    return false;
  }
};

export const isValidDatePrescribed = (val, dateOfService) => {
  if (!isValidDate(val) || !isValidDate(dateOfService)) return false;

  var today = Date.parse(Date());

  var dp = Date.parse(formatDate(val));
  var dos = Date.parse(formatDate(dateOfService));

  if (dp > today) {
    return false;
  } else if (dos < dp) {
    return false;
  } else {
    return true;
  }
};

export const isValidRXNumber = (val) => {
  // rx_number is required
  if (!val) {
    return false;
  }

  var rx_number = val.split("-");

  const rx = rx_number[0];
  const cnt = rx_number[1];

  // Check that multiple dashes are not present
  // eg. xxxxxxxx-xx-xx would result in array size of 3
  if (rx_number.length > 2) {
    return false;
  }

  // Check that rx number does not end in dash
  // eg. xxxxxxxx- would result in array size of 2
  //  [xxxxxxxx, ""]
  if (cnt == "") {
    return false;
  }

  if (rx.length < 5 || rx.length > 12) {
    return false;
  }

  if ((cnt && cnt.length < 1) || (cnt && cnt.length > 2)) {
    return false;
  }

  // must be all numbers
  var reg = /^\d+$/;
  if (reg.test(rx) == false) {
    return false;
  }

  if (cnt && reg.test(cnt) == false) {
    return false;
  }

  return true;
};

export const isValidQuantity = (val, threshold = 5000) => {
  // quantity is required
  if (!val) {
    return false;
  }

  // Check if string contains any character that is not a number, a period or a comma
  if (!val.match(/^-?\d*(\.\d+)?$/)) return false;

  // quantity cannot exeed threshold
  // if (parseFloat(val) > threshold) {
  //   return false;
  // }

  return !isNaN(parseFloat(val));
};

export const isQuestionableQuantity = (val, threshold = 5000) => {
  return parseFloat(val) > threshold;
};

export const isValidFillNumber = (val) => {
  // quantity is required
  if (!val) {
    return false;
  }

  // Check if string contains any character that is not a number, a period or a comma
  if (!val.match(/^\d+$/)) return false;

  return !isNaN(parseInt(val));
};

export const isValidWholesalerInvoiceNumber = (val) => {
  // allow blank values
  if (!val) return true;

  if (val) {
    // numbers, dashes, commas, period and pound
    var reg = /^[0-9-,.#]+$/;

    return reg.test(val);
  }
};

export const isQuestionableWholesalerInvoiceNumber = (val) => {
  var valid = val.length >= 5 && val.length <= 17;
  return !valid;
};

export const isValidPrescriberIDQualifier = (val) => {
  if (_.isEmpty(val)) return true;

  // must be either 01, 1 or 12
  var reg = /^(01|1|12)$/;

  return reg.test(val);
};

// Why is the qualifier arg first?
export const isValidPrescriberID = (qualifier, val) => {
  if (!val) return true;

  // Should we validate prescriber_id_qualifier here?

  var valid = false;
  switch (qualifier) {
    case "1":
    case "01":
      valid = isValidNPI(val);
      break;
    case "7":
    case "07":
      valid = isValidNCPDP(val);
      break;
    case "12":
      valid = isValidDEA(val);
      break;
    default:
      if (isValidNPI(val) || isValidNCPDP(val) || isValidDEA(val)) {
        valid = true;
      }
  }
  return valid;
};

export const isValidServiceProviderIDQualifier = (val) => {
  if (_.isEmpty(val)) return true;

  // must be either 01 or 12
  var reg = /^(01|1|05|5|07|7|10|12)$/;

  return reg.test(val);
};

export const isValidServiceProviderID = (qualifier, val) => {
  var valid = false;
  switch (qualifier) {
    case "1":
    case "01":
      valid = isValidNPI(val);
      break;
    case "7":
    case "07":
      valid = isValidNCPDP(val);
      break;
    case "12":
      valid = isValidDEA(val);
      break;
    default:
      if (isValidNPI(val) || isValidNCPDP(val) || isValidDEA(val)) {
        valid = true;
      }
  }
  return valid;
};

export const isValidNPI = (val) => {
  // must be all numbers
  var reg = /^\d+$/;
  if (reg.test(val) == false) {
    return false;
  }

  // must not start with zero
  if (val.charAt(0) == "0") {
    return false;
  }

  // must be 10 digits
  if (val.length != 10) {
    return false;
  }

  // perform luhn check
  return luhnCheck(val);
};

export const isValidNCPDP = (val) => {
  // must be numeric
  var reg = /^\d+$/;
  if (reg.test(val) == false) {
    return false;
  }

  // must be 7 digits
  if (val.length != 7) {
    return false;
  }

  // must have at most 1 leading zero
  if (val.charAt(0) == "0") {
    if (val.charAt(1) == "0") {
      return false;
    }
  }

  // must conform to the following checksum
  // https://ushik.ahrq.gov/ViewItemDetails?system=sdo&itemKey=127238000
  return nabpCheck(val);
};

// https://ushik.ahrq.gov/ViewItemDetails?system=sdo&itemKey=127238000
const nabpCheck = (val) => {
  var a = parseInt(val.charAt(0)) + parseInt(val.charAt(2)) + parseInt(val.charAt(4));
  var b = 2 * (parseInt(val.charAt(1)) + parseInt(val.charAt(3)) + parseInt(val.charAt(5)));

  return (a + b) % 10 == parseInt(val.charAt(6));
};

export const isValidDEA = (val) => {
  if (!val || val.length != 9) {
    return false;
  }

  // first character must be one of the following (A|B|C|D|E|F|G|H|J|K|L|M|P|R|S|T|U|X)
  var reg = /^(A|B|C|D|E|F|G|H|J|K|L|M|P|R|S|T|U|X)[A-Za-z0-9][\d]+$/;

  if (reg.test(val) == false) {
    return false;
  }

  // must conform to the following checksum
  // https://www.meditec.com/blog/dea-numbers-what-do-they-mean/
  return deaCheck(val);
};

const luhnCheck = (val) => {
  let nCheck = 24,
    bEven = false;
  val = val.replace(/\D/g, "");

  for (var n = val.length - 1; n >= 0; n--) {
    var cDigit = val.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
};

export const deaCheck = (val) => {
  var array = val.split("");
  var uid = array.slice(2, 8);
  var checksum = array[array.length - 1];

  var value = (
    parseInt(uid[0]) +
    parseInt(uid[2]) +
    parseInt(uid[4]) +
    2 * (parseInt(uid[1]) + parseInt(uid[3]) + parseInt(uid[5]))
  )
    .toString()
    .split("");

  return value[value.length - 1] == checksum;
};

export const isValidContractedEntityID = (val) => {
  if (!val) return false;

  // alphanumeric and dash only
  var reg1 = /^[a-zA-Z0-9-]+$/;

  // must be an accepted prefix
  var reg2 = /^CAN|RRC|CAH|PED|FQ|SCH|BL|DSH|CH|TB|FP|RW4|HV|STD|NH|UI|URB|HM|RWI/;

  return reg1.test(val) && reg2.test(val);
};

export const isValidDateOfEncounter = (val) => {
  const formattedData = formatDate(val);

  if (formattedData) return true;

  return false;
};

export const isConformingDateOfEncounter = (date_of_encounter, date_of_service) => {
  if (!date_of_encounter || !date_of_service) return false;

  const formattedDateOfEncounter = formatDate(date_of_encounter);
  const formattedDateOfService = formatDate(date_of_service);

  if (!formattedDateOfEncounter || !formattedDateOfService) return false;

  return areDatesWithinRange(formattedDateOfEncounter, formattedDateOfService, 365);
};

export const isValidClaim = (date_of_service) => {
  if (!date_of_service) return false;

  const formattedDate = formatDate(date_of_service);

  if (!formattedDate) return false;

  return isDateInRange(formattedDate, 45);
};

export const isValidHCPCS = (hcpcs_code) => {
  if (!hcpcs_code) {
    return false;
  }
  //TEMP
  if (hcpcs_code.length > 5) {
    return false;
  }

  return true;
};

export const isValidPhysicianID = (val) => {
  if (!val) return true;

  if (isValidNPI(val) || isValidNCPDP(val) || isValidDEA(val)) {
    return true;
  }

  return false;
};

export const isValidBillingProviderID = (val) => {
  if (!val) return true;

  if (isValidNPI(val) || isValidNCPDP(val) || isValidDEA(val)) {
    return true;
  }

  return false;
};

export const isValidPhysicianIDQualifier = (val) => {
  if (!val) return true;

  return val.length < 3;
}

export const isValidBillingProviderIDQualifier = (val) => {
  if (_.isEmpty(val)) return true;

  // must be either 01 or 12
  var reg = /^(01|1|05|5|07|7|10|12)$/;

  return reg.test(val);
}

export const isValidPrimaryDiagnosisCode = (val) => {
  if (!val) return false;

  if (val.length <= 8) return true;

  return false;
};

export const isValidDiagnosisCode = (val) => {
  if (!val) return true;

  if (val.length <= 8) return true;

  return false;
};

export const isValidPlaceOfService = (val) => {
  if (!val) return false;

  // Must be 2 characters long (letters or numbers or both)
  const reg = /^[a-zA-Z0-9]{2}$/;

  return reg.test(val);
};

export const isValidPlanIDQualifier = (val) => {
  if (!val) return true;

  return val.length === 1;
};

export const isValidPlanIDCode = (val) => {
  if (!val) return true;

  return val.length < 18;
};

export const isValidPlanName = (val) => {
  if (!val) return true;

  return val.length < 31;
};

export const isValidProductServiceProviderIDQualifier = (val) => {
  if (!val) return true;

  return val === "03";
};

export const isValidProductServiceID = (val) => {
  if (!val) return true;

  return isValidNDC(val);
};

export const isValidHCPCSQuantity = (val) => {
  if (!val) return false;

  return val !== "0";
};

export const isValidHCPCSUnitOfMeasure = (val) => {
  if (!val) return true;

  const validHCPCSUnitsOfMeasure = ["EA", "ML", "GM"];

  return validHCPCSUnitsOfMeasure.includes(val);
};

export const isValidPrescriptionType = (val) => {
  if (!val) return true;
  return ["0", "1", "-1"].includes(val);
};

export const isValidClaimNumber = (val) => {
  if (!val) return true;

  return val.length < 21;
};

export const isValidHCPCSCodeModifier = (val) => {
  if (!val) return true;

  return val.length === 2;
};
