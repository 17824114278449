import React, { useState, useEffect } from "react";
import _ from "lodash";

const TabBar = (props) => {
  const renderTag = (tab) => {
    if (props.displayTabBarTags && tab.id == props.displayTagsFor) {
      return (
        <div
          className="tag tag--small tag--orange"
          style={{ position: "relative", top: -2, marginLeft: 5 }}
        >
          <i className="solid solid-budicon-alert" />
        </div>
      );
    }
  };

  const renderLabel = (tab) => {
    if (tab.id === "original_view" && props.fileCount > 1) {
      return (
        <div>
          Original Files
          <span className="t--subtitle" style={{ marginLeft: 8 }}>
            {props.fileCount}
          </span>
        </div>
      );
    }
    return tab.label;
  };

  const renderTabs = () => {
    return props.tabs.map((tab) => (
      <div
        key={tab.id}
        className={tab.id == props.activeTab ? "tabs__item tabs__item--active" : "tabs__item"}
        onClick={() => {
          props.setActiveTab(tab.id);
        }}
      >
        {renderLabel(tab)}
        {renderTag(tab)}
      </div>
    ));
  };

  return (
    <div className="tabs__container" style={{ ...props.style }}>
      {renderTabs()}
    </div>
  );
};
export default TabBar;
