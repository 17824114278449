// Currently, these ↓ data validations are too specific to work for medical claim validation. Needs refactor
import {
  isValidDate,
  isValidPlanIDQualifier,
  isValidPlanIDCode,
  isValidPlanName,
  isValidHCPCSUnitOfMeasure,
  isValidPrescriptionType,
  isValidClaimNumber,
  isValidHCPCS,
  isValidHCPCSCodeModifier,
  isValidContractedEntityID,
  isValidQuantity,
  isValidNPI,
} from "./data_validations";
import { isRequiredField } from "../schemas/medical_claim";

const isValidMedicalClaimField = (value, column, row) => {
  if (["claim_conforms_flag"].includes(column)) {
    return true;
  }

  if (isRequiredField(column) && !value) {
    return false;
  }

  switch (column) {
    // Medical Columns
    case "date_of_service":
      return isValidDate(value);
    case "plan_id_qualifier":
      return isValidPlanIDQualifier(value);
    case "plan_id_code":
      return isValidPlanIDCode(value);
    case "plan_name":
      return isValidPlanName(value);
    case "service_provider_id":
      return isValidNPI(value);
    case "prescriber_id":
      return isValidNPI(value);
    case "product_service_id":
      return true; // skip validations for column we filter by
    case "billed_hcpcs_quantity":
      return isValidQuantity(value);
    case "billed_hcpcs_unit_of_measure":
      return isValidHCPCSUnitOfMeasure(value);
    case "prescription_type":
      return isValidPrescriptionType(value);
    case "claim_number":
      return isValidClaimNumber(value);
    case "hcpcs_code":
      return isValidHCPCS(value);
    case "hcpcs_code_modifier_1":
      return isValidHCPCSCodeModifier(value);
    case "hcpcs_code_modifier_2":
      return isValidHCPCSCodeModifier(value);
    case "contracted_entity_id":
      return isValidContractedEntityID(value);
    default:
      return false;
  }
};

export default isValidMedicalClaimField;
