import React, { useState } from "react";

import TutorialModal from "../../../../modals/library/tutorial_modal";

import slideOneImg from "~/images/column_mapping_tutorial_slide_1.gif";
import slideTwoImg from "~/images/column_mapping_tutorial_slide_2.png";
import slideThreeImg from "~/images/column_mapping_tutorial_slide_3.gif";
import slideFourImg from "~/images/column_mapping_tutorial_slide_4.gif";

const slides = [
  {
    img: slideOneImg,
    title: "Get Started by Uploading Your CSV",
    desc: "To get started, attach a file that you want to upload as part of a claim to 340B ESP. Next, create a new column mapping by clicking “Create a New Mapping”. 340B ESP will extract the column headers from your file. In order to submit claims data, you must have at least one column mapping saved.",
  },
  {
    img: slideTwoImg,
    title: "Review Your CSV Fields to Prepare for Column Mapping",
    desc: "In order to meet HIPAA compliance standards, 340B ESP only collects a subset of your 340B claims data. To process your data, we need you to indicate which fields in your 340B claims data represent the specific data fields we collect. We refer to this matching process as a “Column Mapping”.",
  },
  {
    img: slideThreeImg,
    title: "Filling Out the Column Mapping",
    desc: `Review the required data fields, and then click and drag the column headers from your file to match them to the corresponding field.\nIf you’ve made a mistake and want to change the column header you matched, simply hover over the column and click the Trash icon.`,
  },
  {
    img: slideFourImg,
    title: "Saving Your Column Mapping",
    desc: "Once you have completed the mapping process, you can give your column mapping a name. Click “Save” to finish the process. You can come back at any time and make edits in the future.",
  },
];

const ColumnMappingTutorial = ({ handleClose }) => {
  return <TutorialModal slides={slides} handleClose={handleClose} />;
};

export default ColumnMappingTutorial;
