import React from "react";
import {
  GroupsTwoTone,
  ChevronRight,
  VerifiedUser,
  BusinessTwoTone,
} from "@mui/icons-material";
import moment from "moment";
import { wholeNumberWithCommas } from "../utils/NumberFormatter";
import _ from "lodash";

const StatCards = (props) => {
  const renderUsers = () => {
    return _.map(props.user_initials, (initial, i) => {
      return (
        <div key={i} className="symbol__circle symbol__circle--overlap">
          {initial}
        </div>
      );
    });
  };

  return (
    <>
      <div className="div1">
        <div className="tile__well" style={{ height: "100%" }}>
          <div className="tile">
            <div className="tile__header tile__header--spread">
              <GroupsTwoTone htmlColor={"#3246D3"} fontSize={"large"} />
              <a href={`/organizations/${props.organization}/edit`}>
                <div className="btn btn--sqr">
                  <ChevronRight fontSize="small" />
                </div>
              </a>
            </div>
            <div className="t--lg">{props.user_name}</div>
            <div className="t--sm t--subtitle">{props.user_email}</div>
            <div className="tile__footer tile__footer--spread tile__footer--mt-a">
              <div>Role: {props.user_role.toUpperCase()}</div>
              <div>
                <VerifiedUser htmlColor={"#2BD67B"} fontSize={"small"} />
                <span style={{ marginLeft: 5 }}>2fa: ON</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div2">
        <div className="tile__well" style={{ height: "100%" }}>
          <div className="tile">
            <div className="tile__header tile__header--spread">
              <div className="t--md">Users</div>
              <a href={`/organization/${props.organization}/users`}>
                <div className="btn btn--sqr">
                  <ChevronRight fontSize="small" />
                </div>
              </a>
            </div>
            <div className="flex__container flex__container--align-center">
              <div className="t-500 t--xl">{props.user_count}</div>
              <div className="t--sm ml-2">Total</div>
            </div>
            <div className="tile__footer tile__footer--spread tile__footer--mt-a">
              <div>Team Members</div>
              <div style={{ display: "flex" }}>{renderUsers()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="div3">
        <div className="tile__well" style={{ height: "100%" }}>
          <div className="tile">
            <div className="tile__header tile__header--spread">
              <div className="t--md">Registered Entities</div>
              <a
                href={`/organization/${props.organization}/entities/${props.primary_ce}`}
              >
                <div className="btn btn--sqr">
                  <ChevronRight fontSize="small" />
                </div>
              </a>
            </div>
            <div className="t--500 t--xl">{props.entities_count}</div>
            <div className="tile__footer tile__footer--spread tile__footer--mt-a">
              <div>Primary Entity</div>
              <div>{props.primary_id_340b}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="div4">
        <div className="tile__well" style={{ height: "100%" }}>
          <div className="tile">
            <div className="tile__header tile__header--spread">
              <div className="t--md">Conforming Claims</div>
              <a href={`/organization/${props.organization}/reports`}>
                <div className="btn btn--sqr">
                  <ChevronRight fontSize="small" />
                </div>
              </a>
            </div>
            <div className="flex__container flex__container--align-center">
              <div className="t-500 t--xl">
                {wholeNumberWithCommas(
                  props.conforming_claims_count[moment().format("MM")]
                )}
              </div>
              <div className="t--sm ml-2">This Month</div>
            </div>
            <div className="tile__footer tile__footer--spread tile__footer--mt-a">
              <div>Last Month</div>
              <div>
                {wholeNumberWithCommas(
                  props.conforming_claims_count[
                    moment().subtract(1, "month").format("MM")
                  ]
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatCards;
