const manufacturerDetails = [
  {
    name: "amgen",
    label: "Amgen",
    hin: true,
    policy_doc_url: "/Amgen Updated Contract Pharmacy Policy - 2.21.2024.pdf",
    policy_doc_name: "Amgen Updated Contract Pharmacy Policy - 2.21.2024.pdf",
  },
  {
    name: "abbvie",
    label: "Abbvie",
    hin: true,
    policy_doc_url: "/AbbVie 340B Integrity Initiative Update March 2023.pdf",
    policy_doc_name: "AbbVie 340B Integrity Initiative Update March 2023.pdf",
  },
  {
    name: "astellas",
    label: "Astellas",
    hin: true,
    policy_doc_url: "/Notice_to_340B_Covered_Entities__07032023.pdf",
    policy_doc_name: "Notice_to_340B_Covered_Entities__07032023.pdf",
  },
  {
    name: "astrazeneca",
    label: "AstraZeneca",
    hin: true,
    policy_doc_url: "/AstraZeneca - 340B Covered Entity Letter.pdf",
    policy_doc_name: "AstraZeneca - 340B Covered Entity Letter.pdf",
  },
  {
    name: "pharmacyclics",
    label: "Pharmacyclics",
    hin: true,
    policy_doc_url: "/AbbVie 340B Integrity Initiative Update March 2023.pdf",
    policy_doc_name: "AbbVie 340B Integrity Initiative Update March 2023.pdf",
  },
  {
    name: "bausch",
    label: "Bausch",
    hin: true,
    policy_doc_url: "/BHC 340B Updated Policy_CE Letter 2023-06-12.pdf",
    policy_doc_name: "BHC 340B Updated Policy_CE Letter 2023-06-12.pdf",
  },
  {
    name: "bayer",
    label: "Bayer",
    hin: true,
    policy_doc_url: "/Bayer 340B Covered Entity Letter 4-27-2023.pdf",
    policy_doc_name: "Bayer 340B Covered Entity Letter 4-27-2023.pdf",
  },
  {
    name: "biogen",
    label: "Biogen",
    hin: true,
    policy_doc_url: "/Biogen_CE_announcement_12.1.23.pdf",
    policy_doc_name: "Biogen_CE_announcement_12.1.23.pdf",
  },
  {
    name: "bristol_myers_squibb",
    label: "Bristol Myers Squibb",
    hin: true,
    policy_doc_url: "/BMS_CE_Letter.pdf",
    policy_doc_name: "BMS_CE_Letter.pdf",
  },
  {
    name: "eisai",
    label: "Eisai",
    hin: true,
    policy_doc_url: "/Eisai 340B Contract Pharmacy Announcement to CE's.pdf",
    policy_doc_name: "Eisai 340B Contract Pharmacy Announcement to CE's.pdf",
  },
  {
    name: "eli_lilly",
    label: "Eli Lilly",
    hin: true,
    policy_doc_url:
      "/Lilly USA Notice to Entities Regarding Reinstatement of Previous Contract Pharmacy Policy 06-NOV-2023F.pdf",
    policy_doc_name:
      "Lilly USA Notice to Entities Regarding Reinstatement of Previous Contract Pharmacy Policy 06-NOV-2023F.pdf",
  },
  {
    name: "exelixis",
    label: "Exelixis",
    hin: true,
    policy_doc_url: "/Exelixis_Contract Pharmacy Initiative_CE Notice and FAQ_June2023.pdf",
    policy_doc_name: "Exelixis_Contract Pharmacy Initiative_CE Notice and FAQ_June2023.pdf",
  },
  {
    name: "emd_serono",
    label: "EMD Serono",
    hin: true,
    policy_doc_url: "/EMD Serono - Letter to 340B Covered Entities 9.1.23.pdf",
    policy_doc_name: "EMD Serono - Letter to 340B Covered Entities 9.1.23.pdf",
  },
  {
    name: "boehringer_ingelheim",
    label: "Boehringer Ingelheim",
    hin: true,
    policy_doc_url: "/Boehringer Ingelheim - Letter to 340B Covered Entities.pdf",
    policy_doc_name: "Boehringer Ingelheim - Letter to 340B Covered Entities",
  },
  {
    name: "glaxosmithkline",
    label: "GlaxoSmithKline",
    hin: true,
    policy_doc_url: "/GSK Policy Change Letter, FAQ, NDC List.pdf",
    policy_doc_name: "GSK Policy Change Letter, FAQ, NDC List.pdf",
  },
  {
    name: "gilead",
    label: "Gilead",
    hin: true,
    policy_doc_url: "/Gilead Contract Pharmacy Integrity Initiative Covered Entity Letter & FAQ_1.1.24.pdf",
    policy_doc_name: "Gilead Contract Pharmacy Integrity Initiative Covered Entity Letter & FAQ_1.1.24.pdf",
  },
  {
    name: "incyte",
    label: "Incyte",
    hin: true,
    policy_doc_url: "/Incyte Corporation CE 340B OPZELURA Letter.pdf",
    policy_doc_name: "Incyte Corporation CE 340B OPZELURA Letter.pdf",
  },
  {
    name: "jazz",
    label: "Jazz Pharmaceuticals",
    hin: true,
    policy_doc_url: "/Jazz Pharmaceuticals - CE notification for 340B policy.pdf",
    policy_doc_name: "Jazz Pharmaceuticals - CE notification for 340B policy.pdf",
  },
  {
    name: "johnson_n_johnson",
    label: "Johnson & Johnson",
    hin: true,
    policy_doc_url: "/JJHCS Notice to End Customers Regarding Updates to 340B Delivery Limitations.pdf",
    policy_doc_name: "JJHCS Notice to End Customers Regarding Updates to 340B Delivery Limitations.pdf",
  },
  {
    name: "liquidia",
    label: "Liquidia",
    hin: true,
    policy_doc_url: "/Liquidia_340B Covered Entities Comms.pdf",
    policy_doc_name: "Liquidia_340B Covered Entities Comms.pdf",
  },
  {
    name: "merck",
    label: "Merck",
    hin: true,
    policy_doc_url: "/Merck 340B Program Integrity Initiative Letter - July 31 2023.pdf",
    policy_doc_name: "Merck 340B Program Integrity Initiative Letter - July 31 2023.pdf",
  },
  {
    name: "novo_nordisk",
    label: "Novo Nordisk",
    hin: true,
    policy_doc_url: "/NNI Combined Policy Notification Revised 1.17.24.pdf",
    policy_doc_name: "NNI Combined Policy Notification Revised 1.17.24.pdf",
  },
  {
    name: "novartis",
    label: "Novartis",
    hin: true,
    policy_doc_url: "/Novartis CE Letter April 3 2023.pdf",
    policy_doc_name: "Novartis CE Letter April 3 2023.pdf",
  },
  {
    name: "organon",
    label: "Organon",
    hin: true,
    policy_doc_url: "/Organon PHS 340B policy document.pdf",
    policy_doc_name: "Organon PHS 340B policy document.pdf",
  },
  {
    name: "pfizer",
    label: "Pfizer",
    hin: false,
    policy_doc_url: "/Pfizer Announcement Oct 2023.pdf",
    policy_doc_name: "Pfizer Announcement Oct 2023",
  },
  {
    name: "sandoz",
    label: "Sandoz",
    hin: true,
    policy_doc_url: "/Sandoz_CE_letter_10.30.23.pdf",
    policy_doc_name: "Sandoz_CE_letter_10.30.23.pdf",
  },
  {
    name: "sanofi",
    label: "Sanofi",
    hin: true,
    policy_doc_url: "/340B Sanofi Covered Entity Letter.pdf",
    policy_doc_name: "340B Sanofi Covered Entity Letter.pdf",
  },
  {
    name: "teva",
    label: "Teva",
    hin: false,
    policy_doc_url: "/Covered Entity_Teva 340B Policy.pdf",
    policy_doc_name: "Covered Entity_Teva 340B Policy.pdf",
  },
  {
    name: "ucb",
    label: "UCB",
    hin: true,
    policy_doc_url: "/340B-Covered-Entity-Letter-UCB.pdf",
    policy_doc_name: "340B-Covered-Entity-Letter-UCB",
  },
  {
    name: "takeda",
    label: "Takeda",
    hin: true,
    policy_doc_url: "/Takeda OBU 340B Contract Pharmacy Policy Letter 02.23.2024.pdf",
    policy_doc_name: "Takeda OBU 340B Contract Pharmacy Policy Letter 02.23.2024.pdf",
  },
];

export const manufacturers = manufacturerDetails;

// export const duopaBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];
export const imbruvicaBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];

export const imbruvicaBlockedCes = [
  "DSH140088",
  "DSH280013",
  "DSH450801",
  "DSH120022",
  "DSH340053",
  "DSH390027",
  "CH021887CW",
  "DSH140158",
  "DSH390133",
  "DSH670077",
  "DSH450844",
  "DSH450424",
  "DSH050454",
  "DSH380018B",
  "DSH160101",
  "DSH220052",
  "DSH330125",
  "DSH050353",
  "DSH420051",
  "PED053309-00",
  "DSH050485",
  "DSH500036",
  "DSH050077",
  "PED453304-00",
  "DSH340028",
  "DSH280013",
  "DSH200024",
  "DSH350011",
  "DSH430027",
  "DSH240100",
  "DSH350015",
  "DSH520098",
  "DSH390111",
  "DSH450102",
  "DSH330044",
  "DSH490032",
  "DSH050038",
  "DSH490009",
  "DSH190060",
  "DSH300003",
  "PED243302-00",
  "DSH300003",
  "DSH150024",
  "DSH150021",
  "DSH330119",
  "DSH450024",
  "DSH440050",
  "DSH440176",
  "DSH440063A",
  "DSH490053",
  "DSH490114A",
  "DSH490114B",
  "DSH250004P",
  "DSH390006",
  "DSH390270",
  "DSH370093",
  "DSH180012",
  "DSH110087 ",
  "DSH390226",
  "DSH230017",
  "DSH200033",
  "DSH140304",
  "DSH050239",
  "DSH050336",
  "DSH050335",
  "DSH050013",
  "DSH050301",
  "DSH120006",
  "DSH360027",
  "DSH360027E",
  "DSH360027G",
  "DSH360027W",
  "DSH360027X",
  "DSH390142A",
  "DSH050305",
  "DSH170122",
  "DSH310064",
  "DSH110034",
  "DSH430016A",
  "DSH030064H",
  "DSH030111",
  "DSH180080",
  "DSH180138",
  "DSH180103",
  "DSH180103B",
  "DSH180104",
  "DSH010023A",
  "DSH260032",
  "DSH080004",
  "DSH450137",
  "DSH220077",
  "DSH220046",
  "DSH220086",
  "DSH220086AN",
  "DSH220086AV",
  "DSH220086H",
  "DSH220033A",
  "DSH220033B",
  "DSH220033BX",
  "DSH220031",
  "DSH230075",
  "DSH100039",
  "DSH100086",
  "DSH220011",
  "DSH110024B",
  "DSH340130",
  "DSH050625",
  "DSH050625BD",
  "DSH510022",
  "PED123300-00",
  "PED363300-00",
  "PED053302-00",
  "PED453302-00",
  "DSH260180",
  "DSH080001",
  "DSH320002",
  "DSH450801",
  "PED363300-03",
  "DSH510008A",
  "DSH340068",
  "DSH370056",
  "DSH150074",
  "DSH110104",
  "DSH450015",
  "DSH010092",
  "DSH250082",
  "DSH060011",
  "DSH060011A",
  "DSH060011CZ",
  "DSH340030",
  "DSH230230",
  "DSH110078",
  "DSH060028",
  "DSH060028C",
  "DSH360077",
  "DSH360025",
  "DSH260137",
  "DSH330386",
  "CHC28973-02",
  "DSH110079",
  "DSH500064",
  "DSH450289B",
  "DSH450289",
  "DSH450289AN",
  "DSH450289B",
  "DSH070025",
  "DSH180029",
  "DSH050320",
  "DSH150056B",
  "DSH460001",
  "DSH100022",
  "DSH100022",
  "DSH100022I",
  "DSH180040",
  "DSH030014",
  "DSH030014K",
  "DSH030014L",
  "DSH030014N",
  "DSH030014P",
  "DSH030014Q",
  "DSH030014R",
  "DSH030014S",
  "DSH030014T",
  "DSH030014W",
  "DSH030014X",
  "DSH140124",
  "DSH210029",
  "DSH210009",
  "DSH440063",
  "DSH500058U",
  "DSH230297",
  "DSH050696",
  "DSH110143B",
  "DSH130049C",
  "DSH100157",
  "DSH100012",
  "DSH500150",
  "DSH110038A",
  "DSH050778",
  "DSH050778D",
  "DSH050327",
  "DSH050376",
  "DSH050373",
  "DSH140276",
  "PED053305-00",
  "DSH360087",
  "DSH010005",
  "DSH330136",
  "DSH360118",
  "DSH450132",
  "DSH420004",
  "DSH450184",
  "DSH450184AA",
  "DSH450184B",
  "DSH450184CG",
  "DSH450184EG",
  "DSH450848",
  "DSH450068AF",
  "DSH450068BB",
  "DSH250019",
  "DSH100285",
  "DSH100230",
  "DSH100281A",
  "DSH100281J",
  "DSH100038AC",
  "DSH100038BF",
  "DSH390156",
  "DSH390156D",
  "DSH440049",
  "DSH440049AC",
  "DSH440049AN",
  "DSH440049BK",
  "DSH360059",
  "DSH330059",
  "DSH330169",
  "DSH330046",
  "DSH330046A",
  "CH051780",
  "DSH330055",
  "DSH330055",
  "DSH050367",
  "DSH340047",
  "CH09067G",
  "DSH240001K",
  "DSH250004H",
  "DSH250004A",
  "DSH250004A",
  "DSH190015",
  "RW410422",
  "DSH110087CX",
  "DSH110161",
  "DSH110161AT",
  "DSH110161X",
  "DSH210040",
  "DSH340141",
  "DSH340085",
  "DSH330214",
  "DSH330214",
  "DSH330214M",
  "DSH380009",
  "DSH380009",
  "DSH380009G",
  "DSH100006",
  "DSH370093",
  "DSH370093BJ",
  "DSH050115",
  "DSH450015",
  "DSH450015A",
  "DSH450015BG",
  "DSH450015I",
  "DSH150021",
  "DSH390223",
  "DSH390256",
  "DSH110074",
  "DSH180044",
  "DSH340040",
  "DSH340053",
  "PED463301-00",
  "DSH120001",
  "DSH390044",
  "DSH440152",
  "DSH240106",
  "DSH290001A",
  "DSH490052",
  "DSH330125",
  "DSH050262",
  "DSH240100",
  "DSH430027AC",
  "DSH050396",
  "DSH050112",
  "DSH340008",
  "DSH100001",
  "DSH100113",
  "DSH210012",
  "DSH050335",
  "DSH220074",
  "DSH010001",
  "DSH360008",
  "DSH260105",
  "DSH190308",
  "DSH370091",
  "DSH050013",
  "DSH030024F",
  "DSH380047",
  "DSH240036",
  "DSH180035",
  "DSH070002",
  "DSH030024",
  "PED443302-00",
  "DSH130006",
  "DSH510007",
  "DSH050441",
  "DSH330160",
  "DSH330285",
  "DSH500027",
  "DSH140228",
  "DSH100128",
  "DSH110015",
  "DSH450039",
  "DSH450039F",
  "PED453304-00",
  "DSH140091",
  "PED393303-00",
  "DSH390111",
  "DSH390111",
  "DSH390111",
  "DSH110064",
  "DSH110064D",
  "DSH230121",
  "DSH340091P",
  "DSH330024",
  "DSH330046H",
  "DSH360085",
  "DSH360085EV",
  "DSH420068A",
  "DSH140088",
  "DSH140115",
  "DSH360068",
  "DSH260048",
  "DSH060022",
  "DSH050348",
  "DSH150023",
  "DSH150023C",
  "DSH390041",
  "DSH330394",
  "DSH510006",
  "CH03702AM",
  "DSH360048",
  "DSH460009R",
  "DSH330241",
  "DSH330350",
  "DSH330241",
  "DSH450686",
  "DSH190005",
  "DSH010033",
  "DSH010033",
  "DSH030024D",
  "DSH040016",
  "DSH050599",
  "DSH050599CY",
  "DSH050025",
  "DSH050025BW",
  "DSH050025CG",
  "DSH050025L",
  "DSH140088",
  "DSH360003",
  "DSH360003",
  "DSH060024",
  "DSH140150AA",
  "DSH160058",
  "DSH170040",
  "DSH170040",
  "DSH180067",
  "DSH180141",
  "DSH210002",
  "DSH210002",
  "DSH220163",
  "DSH230046",
  "DSH250001B",
  "DSH260141",
  "DSH320001C",
  "DSH440015",
  "DSH450690",
  "DSH450690A",
  "DSH450018A",
  "DSH450018DY",
  "DSH460009S",
  "DSH470003",
  "DSH490009",
  "DSH500008",
  "DSH490032",
  "DSH440039",
  "DSH340091",
  "DSH510001X",
  "DSH520096",
];

export const imidBlockedTypeCodes = ["CAN", "CAH", "SCH", "RRC"];

export const imidBlockedCes = [
  "AAA10004",
  "DSH240040",
  "DSH240210",
  "DSH220116",
  "DSH240080",
  "DSH360025",
  "DSH130006",
  "DSH170040",
  "DSH450054",
  "DSH520087",
  "DSH500036",
  "DSH500036",
  "DSH350011",
  "DSH350011",
  "DSH350015",
  "DSH240100",
  "DSH280013",
  "DSH230046",
  "DSH500027",
  "DSH210029",
  "DSH330285",
  "DSH210002",
  "DSH050373",
  "DSH110161",
  "DSH100008",
  "DSH340047",
  "DSH220031",
  "DSH040016",
  "DSH050376",
  "DSH420068",
  "DSH050599",
  "DSH050025",
  "DSH420004",
  "DSH160058",
  "DSH240001",
  "DSH110143",
  "DSH210009",
  "DSH230053",
  "DSH230092",
  "DSH430027",
  "DSH060024",
  "DSH180088",
  "DSH140119",
  "DSH140029",
  "DSH370028",
  "DSH370106",
  "DSH370113",
  "DSH370016",
  "DSH140150",
  "DSH140150",
  "DSH340053",
  "DSH430015",
  "DSH430016",
  "DSH420007",
  "PED523300-00",
  "DSH230038",
  "DSH010023",
  "DSH450690",
  "DSH490052",
  "DSH460009",
  "DSH440039",
  "DSH100006",
  "RWI900152G",
  "FP00919",
  "CH020910",
  "RWI00954",
  "HV26894B",
  "STD00612",
  "STD007951",
  "STD009091",
  "HV26894",
  "HV26894A",
  "HV26894C",
  "RWI00717A",
  "STD00717",
  "HV022090",
  "CH022090",
  "CH02209A",
  "CH020150",
  "CH02089A",
  "CH02089B",
  "CH02089C",
  "CH020890",
  "CH021400",
  "RWIID009362",
  "RWIIR00936",
  "RWII009365",
  "STD009365",
  "TB009365",
  "CHC33662-00",
  "CH020670",
  "CH02067A",
  "CH02067B",
  "CH02067C",
  "CHC22687-00",
  "CHC22687-01",
  "CHC22687-02",
  "CH020730",
  "CH02073F",
  "CH02073G",
  "CH02073H",
  "CH02073J",
  "CH021260",
  "CH02126A",
  "CH02126B",
  "CH02126C",
  "CH02073A",
  "CH02103A",
  "CH021030",
  "FP00936",
  "FP00959",
  "CH02070L",
  "DSH400044",
  "CH020660",
  "CH02066A",
  "CH02066B",
  "CH02070E",
  "CH02070F",
  "CH02070G",
  "CH02070H",
  "CH02070J",
  "CH02070K",
  "RWI00970",
  "CH020680",
  "CH02068A",
  "CH02068B",
  "CH02068C",
  "HV020680",
  "CH02104G",
  "CH02104K",
  "CH02104R",
  "CH02104S",
  "CH021040",
  "CH02104C",
  "CH02104E",
  "CH02104H",
  "CH02104J",
  "CH02104L",
  "CH02104M",
  "CH02104P",
  "CH02104Q",
  "HV021040",
  "HV021040A",
  "HV021040B",
  "HV021040C",
  "HV021040D",
  "HV021040E",
  "HV021040F",
  "CH02223B",
  "CH022230",
  "CH02223A",
  "DSH400015",
  "HV023170",
  "CH020690",
  "RWI00957",
  "CH02203H",
  "CH022030",
  "CH02203A",
  "CH02203C",
  "CH02203D",
  "CH02203E",
  "CH02203F",
  "CH02203G",
  "CH02203B",
  "RWI00908",
  "CH021250",
  "CH02125A",
  "HV021670",
  "RWI00925",
  "CH020650",
  "CH02065A",
  "CH02065B",
  "CH02065C",
  "CH02065D",
  "CH02065E",
  "CH02065F",
  "CH02065G",
  "CH02065H",
  "CH02065J",
  "CH021870",
  "FP009368",
  "RW424858",
  "PED403301-00",
  "FP0073133",
  "FP00698",
  "FP00680",
  "FP00674",
];

export const camzyosBlockedTypeCodes = ["CAN", "CAH", "SCH", "RRC"];

export const camzyosBlockedCes = [
  "AAA10004",
  "DSH220071",
  "DSH110078",
  "DSH210009",
  "DSH440039",
  "DSH330214",
  "DSH390073N",
  "DSH390073M",
  "RWI900152G",
  "FP00919",
  "CH020910",
  "RWI00954",
  "HV26894B",
  "STD00612",
  "STD007951",
  "STD009091",
  "HV26894",
  "HV26894A",
  "HV26894C",
  "RWI00717A",
  "STD00717",
  "HV022090",
  "CH022090",
  "CH02209A",
  "CH020150",
  "CH02089A",
  "CH02089B",
  "CH02089C",
  "CH020890",
  "CH021400",
  "RWIID009362",
  "RWIIR00936",
  "RWII009365",
  "STD009365",
  "TB009365",
  "CHC33662-00",
  "CH020670",
  "CH02067A",
  "CH02067B",
  "CH02067C",
  "CHC22687-00",
  "CHC22687-01",
  "CHC22687-02",
  "CH020730",
  "CH02073F",
  "CH02073G",
  "CH02073H",
  "CH02073J",
  "CH021260",
  "CH02126A",
  "CH02126B",
  "CH02126C",
  "CH02073A",
  "CH02103A",
  "CH021030",
  "FP00936",
  "FP00959",
  "CH02070L",
  "DSH400044",
  "CH020660",
  "CH02066A",
  "CH02066B",
  "CH02070E",
  "CH02070F",
  "CH02070G",
  "CH02070H",
  "CH02070J",
  "CH02070K",
  "RWI00970",
  "CH020680",
  "CH02068A",
  "CH02068B",
  "CH02068C",
  "HV020680",
  "CH02104G",
  "CH02104K",
  "CH02104R",
  "CH02104S",
  "CH021040",
  "CH02104C",
  "CH02104E",
  "CH02104H",
  "CH02104J",
  "CH02104L",
  "CH02104M",
  "CH02104P",
  "CH02104Q",
  "HV021040",
  "HV021040A",
  "HV021040B",
  "HV021040C",
  "HV021040D",
  "HV021040E",
  "HV021040F",
  "CH02223B",
  "CH022230",
  "CH02223A",
  "DSH400015",
  "HV023170",
  "CH020690",
  "RWI00957",
  "CH02203H",
  "CH022030",
  "CH02203A",
  "CH02203C",
  "CH02203D",
  "CH02203E",
  "CH02203F",
  "CH02203G",
  "CH02203B",
  "RWI00908",
  "CH021250",
  "CH02125A",
  "HV021670",
  "RWI00925",
  "CH020650",
  "CH02065A",
  "CH02065B",
  "CH02065C",
  "CH02065D",
  "CH02065E",
  "CH02065F",
  "CH02065G",
  "CH02065H",
  "CH02065J",
  "CH021870",
  "FP009368",
  "RW424858",
  "PED403301-00",
  "FP0073133",
  "FP00698",
  "FP00680",
  "FP00674",
];

export const donAcceptedTypeCodes = ["DSH", "RRC", "CAN", "CAH", "PED", "SCH"];

export const donBlockedCes = [
  "AAA10004",
  "CAH181311-00",
  "CAH241381-00",
  "CAH361303-00",
  "CAH521329-00",
  "CAN050146-00",
  "CAN050660-00",
  "CH041281AH",
  "CH04690J",
  "CH09108BD",
  "CH092536AT",
  "CH092536AU",
  "CH09303L",
  "CH093080",
  "CH10123W",
  "CHC24161-00",
  "DSH010023",
  "DSH010033",
  "DSH030002",
  "DSH030024",
  "DSH030064",
  "DSH030122",
  "DSH040016",
  "DSH050025",
  "DSH050040",
  "DSH050069",
  "DSH050077",
  "DSH050262",
  "DSH050327",
  "DSH050348",
  "DSH050367",
  "DSH050373",
  "DSH050376",
  "DSH050441",
  "DSH050454",
  "DSH050599",
  "DSH050625",
  "DSH050696",
  "DSH050717",
  "DSH060013",
  "DSH060024",
  "DSH060104",
  "DSH070022",
  "DSH070025",
  "DSH090004",
  "DSH090011",
  "DSH100001",
  "DSH100006",
  "DSH100007",
  "DSH100008",
  "DSH100012",
  "DSH100038",
  "DSH100113",
  "DSH100128",
  "DSH100281",
  "DSH110034",
  "DSH110038",
  "DSH110064",
  "DSH110078",
  "DSH110079",
  "DSH110087",
  "DSH110161",
  "DSH130049",
  "DSH140088",
  "DSH140119",
  "DSH140150",
  "DSH140228",
  "DSH150021",
  "DSH150023",
  "DSH150056",
  "DSH150074",
  "DSH150169",
  "DSH160058",
  "DSH170040",
  "DSH180001",
  "DSH180035",
  "DSH180044",
  "DSH180067",
  "DSH180088",
  "DSH190036",
  "DSH210002",
  "DSH210009",
  "DSH210012",
  "DSH210015",
  "DSH210024",
  "DSH210029",
  "DSH210034",
  "DSH210040",
  "DSH220010",
  "DSH220011",
  "DSH220031",
  "DSH220046",
  "DSH220071",
  "DSH220074",
  "DSH220077",
  "DSH220086",
  "DSH220116",
  "DSH220163",
  "DSH230046",
  "DSH230053",
  "DSH230075",
  "DSH230121",
  "DSH230297",
  "DSH240001",
  "DSH240036",
  "DSH240057",
  "DSH240080",
  "DSH240100",
  "DSH250004",
  "DSH250019",
  "DSH260032",
  "DSH260141",
  "DSH270004",
  "DSH280013",
  "DSH300012",
  "DSH320001",
  "DSH320021",
  "DSH330024",
  "DSH330043",
  "DSH330046",
  "DSH330055",
  "DSH330059",
  "DSH330101",
  "DSH330119",
  "DSH330125",
  "DSH330160",
  "DSH330169",
  "DSH330195",
  "DSH330214",
  "DSH330236",
  "DSH330239",
  "DSH330241",
  "DSH330259",
  "DSH330261",
  "DSH330285",
  "DSH330394",
  "DSH340028",
  "DSH340030",
  "DSH340040",
  "DSH340047",
  "DSH340053",
  "DSH340061",
  "DSH340085",
  "DSH340091",
  "DSH340113",
  "DSH340130",
  "DSH340141",
  "DSH350011",
  "DSH350015",
  "DSH360003",
  "DSH360008",
  "DSH360020",
  "DSH360027",
  "DSH360059",
  "DSH360077",
  "DSH360085",
  "DSH360087",
  "DSH360137",
  "DSH360144",
  "DSH380004",
  "DSH380009",
  "DSH380021",
  "DSH380061",
  "DSH390001",
  "DSH390002",
  "DSH390006",
  "DSH390027",
  "DSH390028",
  "DSH390044",
  "DSH390063",
  "DSH390073",
  "DSH390090",
  "DSH390111",
  "DSH390174",
  "DSH390223",
  "DSH390226",
  "DSH390256",
  "DSH390270",
  "DSH410007",
  "DSH410009",
  "DSH420004",
  "DSH420007",
  "DSH430016",
  "DSH430027",
  "DSH440015",
  "DSH440039",
  "DSH440048",
  "DSH440104",
  "DSH440152",
  "DSH450024",
  "DSH450054",
  "DSH450079",
  "DSH450137",
  "DSH450213",
  "DSH450844",
  "DSH460009",
  "DSH470003",
  "DSH490009",
  "DSH490032",
  "DSH490052",
  "DSH490063",
  "DSH490090",
  "DSH500008",
  "DSH500014",
  "DSH500027",
  "DSH500036",
  "DSH500054",
  "DSH500064",
  "DSH510055",
  "DSH520177",
  "FP021185",
  "FP089011",
  "FP176026",
  "FP17604",
  "FP191046",
  "FP191229",
  "FP191403",
  "FP30318",
  "FP30324",
  "FP405360",
  "FQHCLA335K",
  "FQHCLA415A",
  "HM00032",
  "HM01655",
  "HM02114",
  "HM08901",
  "HM11574",
  "HM12141",
  "HM13093",
  "HM18103",
  "HM19104",
  "HM20007",
  "HM2673",
  "HM27157",
  "HM28203",
  "HM37232",
  "HM40436",
  "HM40831",
  "HM441061",
  "HM4794",
  "HM4836",
  "HM5568",
  "HM63110",
  "HM6351",
  "HM6968",
  "HM7439",
  "HM77030",
  "HM8208",
  "HM8395",
  "HM8477",
  "HM8764",
  "HM95817",
  "HM958171",
  "HV00204",
  "HV00812",
  "HV00812C",
  "HV00818",
  "HV032130",
  "HV17604",
  "HV30308",
  "PED053305-00",
  "PED063301-00",
  "PED073300-00",
  "PED363302-00",
  "PED363303-00",
  "PED443302-00",
  "RRC140281-00",
  "RRC220110-00",
  "RRC270014-00",
  "RRC300003-00",
  "RRC340004-00",
  "RRC340014-00",
  "RRC360147-00",
  "RRC360180-00",
  "RRC390100-00",
  "RRC390133-00",
  "RRC500002-00",
  "RRC500024-00",
  "RRC520070-00",
  "RRC520098-00",
  "RW400069A",
  "RW410422",
  "RW431112",
  "RWI11030",
  "RWI11740",
  "RWI44195",
  "RWI70112",
  "RWI90059",
  "RWI93536",
  "RWII217C",
  "RWII55905",
  "RWIID91",
  "SCH140189-00",
  "SCH190007-00",
  "SCH350019-00",
  "SCH490021-00",
  "STD176022",
  "STD35294",
];

export const cabometyxBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];
export const cometriqBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];

export const ofevBlockedCes = [
  "DSH420004",
  "DSH170040",
  "DSH050599",
  "DSH440039",
  "DSH390111",
  "DSH140088",
  "DSH240080",
  "DSH050454",
  "DSH220031",
  "DSH460009",
  "DSH140276",
  "DSH330285",
  "DSH340113",
  "DSH100128",
  "DSH230053",
  "DSH160058",
  "DSH220077",
  "DSH010033",
  "DSH180067",
  "DSH470003",
  "DSH490063",
  "DSH330125",
  "RRC330164-00",
  "DSH340030",
  "DSH050038",
  "DSH050625",
  "DSH040016",
  "DSH280013",
  "DSH390223",
  "DSH130049",
  "DSH320001",
  "DSH330119",
  "DSH490032",
  "DSH330024",
  "DSH390006",
  "RRC520098-00",
  "DSH260141",
  "DSH350011",
  "DSH330226",
  "DSH330013",
  "DSH360020",
  "RRC300003-00",
  "DSH390270",
  "DSH140150",
  "DSH360008",
  "DSH060011",
  "DSH360003",
  "DSH520098",
  "DSH220046",
  "RRC330119-00",
  "DSH210009",
  "DSH220163",
  "DSH050025",
  "DSH390256",
  "DSH430016",
  "DSH240004",
  "DSH210029",
  "DSH260137",
  "DSH180044",
  "DSH360059",
  "DSH220116",
  "DSH050373",
  "DSH230075",
  "DSH330101",
  "DSH330043",
  "DSH440152",
  "DSH500008",
  "DSH330059",
  "DSH050348",
  "DSH300003",
  "DSH500054",
  "DSH240019",
  "DSH230046",
  "DSH100022",
  "DSH130006",
  "DSH100006",
  "RRC330226-00",
  "DSH060075",
  "DSH050335",
  "DSH330055",
  "DSH490009",
  "DSH330241",
  "DSH450102",
  "CH10257R",
  "CH09067G",
  "CAH451382-00",
  "DSH450213",
  "DSH050292",
  "DSH250004",
  "DSH250104",
  "DSH110034",
  "DSH340141",
  "DSH060024",
  "RRC200024-00",
  "DSH330195",
  "DSH380009",
  "DSH240001",
  "DSH050376",
  "DSH050228",
  "RRC520070-00",
  "DSH230297",
  "DSH430027",
  "DSH330204",
  "DSH340053",
  "DSH330399",
  "DSH330009",
  "DSH450015",
  "RRC450102-00",
  "DSH330014",
  "DSH510062",
  "DSH450039",
  "DSH390027",
  "DSH050113",
  "DSH190006",
  "DSH500064",
  "DSH220011",
  "RRC140046-00",
  "DSH310038",
  "CH08006AG",
  "DSH070036",
  "DSH070022",
  "DSH330169",
  "DSH390174",
  "DSH190060",
  "DSH060107",
  "STD321172",
  "DSH050040",
  "DSH330393",
  "DSH220024",
  "DSH330233",
  "DSH310002",
  "DSH110161",
  "DSH330160",
  "DSH050262",
  "DSH330044",
  "DSH450018",
  "DSH430077",
  "DSH390044",
  "CH10103G",
  "DSH360137",
  "DSH370093",
  "DSH440063",
  "CH091181K",
  "DSH110038",
  "DSH070002",
  "TB100133",
  "DSH210002",
  "DSH340047",
  "CAH521345-00",
  "CH09003E",
  "DSH330261",
  "CH10103B",
  "DSH030024",
  "DSH490007",
  "DSH450289",
  "DSH250019",
  "DSH130071",
];

export const benlystaBlockedCes = [
  "CAH031300-00",
  "CAH041310-00",
  "CAH051316-00",
  "CAH061302-00",
  "CAH061312-00",
  "CAH061326-00",
  "CAH061327-00",
  "CAH131302-00",
  "CAH131305-00",
  "CAH131312-00",
  "CAH131323-00",
  "CAH141315-00",
  "CAH141334-00",
  "CAH141338-00",
  "CAH141346-00",
  "CAH141350-00",
  "CAH151304-00",
  "CAH151323-00",
  "CAH151329-00",
  "CAH151332-00",
  "CAH161301-00",
  "CAH161302-00",
  "CAH161327-00",
  "CAH161342-00",
  "CAH161348-00",
  "CAH161351-00",
  "CAH161358-00",
  "CAH161364-00",
  "CAH161367-00",
  "CAH171310-00",
  "CAH171316-00",
  "CAH171319-00",
  "CAH171362-00",
  "CAH171370-00",
  "CAH171375-00",
  "CAH171384-00",
  "CAH181301-00",
  "CAH181311-00",
  "CAH181318-00",
  "CAH181329-00",
  "CAH191302-00",
  "CAH191309-00",
  "CAH201306-00",
  "CAH221300-00",
  "CAH231323-00",
  "CAH231333-00",
  "CAH231338-00",
  "CAH241308-00",
  "CAH241322-00",
  "CAH241330-00",
  "CAH241368-00",
  "CAH261306-00",
  "CAH261311-00",
  "CAH261322-00",
  "CAH261324-00",
  "CAH261330-00",
  "CAH261336-00",
  "CAH261337-00",
  "CAH271345-00",
  "CAH281363-00",
  "CAH321305-00",
  "CAH321310-00",
  "CAH341324-00",
  "CAH341328-00",
  "CAH351335-00",
  "CAH361316-00",
  "CAH361325-00",
  "CAH381302-00",
  "CAH381314-00",
  "CAH381315-00",
  "CAH381323-00",
  "CAH471301-00",
  "CAH471307-00",
  "CAH491300-00",
  "CAH501323-00",
  "CAH501326-00",
  "CAH501327-00",
  "CAH501332-00",
  "CAH501340-00",
  "CAH511321-00",
  "CAH521315-00",
  "CAH521316-00",
  "CAH521329-00",
  "CAH521336-00",
  "CAH521351-00",
  "CAH521359-00",
  "CAH531313-00",
  "CAN100079-00",
  "CH01458C",
  "CH044710",
  "CH045230",
  "CH04563F",
  "CH060940",
  "CH08006AG",
  "CH083440",
  "CH090830",
  "CH093110",
  "CH10130B",
  "CH10257R",
  "CHC08240-00",
  "CHC08786-00",
  "CHC12863-00",
  "CHC12872-17",
  "CHC28348-00",
  "CHC28987-00",
  "CHC31240-00",
  "DSH010023",
  "DSH010029",
  "DSH010087",
  "DSH010099",
  "DSH010113",
  "DSH020001",
  "DSH030014",
  "DSH030062",
  "DSH040007",
  "DSH040014",
  "DSH040016",
  "DSH040029",
  "DSH040071",
  "DSH040119",
  "DSH050006",
  "DSH050025",
  "DSH050026",
  "DSH050038",
  "DSH050040",
  "DSH050045",
  "DSH050057",
  "DSH050060",
  "DSH050069",
  "DSH050077",
  "DSH050078",
  "DSH050100",
  "DSH050103",
  "DSH050112",
  "DSH050113",
  "DSH050115",
  "DSH050121",
  "DSH050128",
  "DSH050133",
  "DSH050159",
  "DSH050167",
  "DSH050168",
  "DSH050222",
  "DSH050245",
  "DSH050254",
  "DSH050262",
  "DSH050276",
  "DSH050278",
  "DSH050292",
  "DSH050301",
  "DSH050315",
  "DSH050327",
  "DSH050335",
  "DSH050348",
  "DSH050353",
  "DSH050367",
  "DSH050373",
  "DSH050376",
  "DSH050394",
  "DSH050454",
  "DSH050455",
  "DSH050492",
  "DSH050599",
  "DSH050717",
  "DSH050761",
  "DSH050778",
  "DSH060010",
  "DSH060011",
  "DSH060012",
  "DSH060013",
  "DSH060022",
  "DSH060024",
  "DSH060031",
  "DSH060054",
  "DSH060103",
  "DSH070002",
  "DSH070006",
  "DSH070016",
  "DSH070025",
  "DSH070029",
  "DSH070035",
  "DSH070036",
  "DSH080003",
  "DSH100007",
  "DSH100012",
  "DSH100022",
  "DSH100052",
  "DSH100067",
  "DSH100075",
  "DSH100093",
  "DSH100157",
  "DSH110024",
  "DSH110087",
  "DSH110104",
  "DSH110105",
  "DSH110128",
  "DSH110161",
  "DSH120014",
  "DSH120022",
  "DSH130006",
  "DSH130007",
  "DSH130013",
  "DSH130071",
  "DSH140018",
  "DSH140067",
  "DSH140091",
  "DSH140114",
  "DSH140119",
  "DSH140124",
  "DSH140172",
  "DSH140179",
  "DSH140209",
  "DSH140276",
  "DSH140280",
  "DSH140304",
  "DSH150004",
  "DSH150007",
  "DSH150011",
  "DSH150021",
  "DSH150023",
  "DSH150051",
  "DSH150056",
  "DSH150074",
  "DSH150089",
  "DSH150104",
  "DSH150169",
  "DSH160033",
  "DSH160058",
  "DSH160064",
  "DSH160080",
  "DSH160082",
  "DSH160101",
  "DSH160110",
  "DSH160146",
  "DSH170040",
  "DSH170086",
  "DSH170145",
  "DSH180009",
  "DSH180011",
  "DSH180013",
  "DSH180035",
  "DSH180044",
  "DSH180048",
  "DSH180049",
  "DSH180051",
  "DSH180067",
  "DSH180088",
  "DSH180093",
  "DSH180102",
  "DSH180103",
  "DSH180104",
  "DSH180141",
  "DSH180143",
  "DSH190004",
  "DSH190005",
  "DSH190006",
  "DSH190015",
  "DSH190036",
  "DSH190039",
  "DSH190040",
  "DSH190046",
  "DSH190060",
  "DSH190064",
  "DSH190065",
  "DSH190098",
  "DSH190102",
  "DSH190111",
  "DSH190183",
  "DSH190201",
  "DSH190202",
  "DSH200024",
  "DSH200033",
  "DSH200041",
  "DSH220010",
  "DSH220011",
  "DSH220016",
  "DSH220024",
  "DSH220031",
  "DSH220035",
  "DSH220066",
  "DSH220071",
  "DSH220077",
  "DSH220086",
  "DSH220119",
  "DSH220177",
  "DSH230005",
  "DSH230017",
  "DSH230020",
  "DSH230021",
  "DSH230030",
  "DSH230035",
  "DSH230036",
  "DSH230038",
  "DSH230046",
  "DSH230053",
  "DSH230075",
  "DSH230089",
  "DSH230092",
  "DSH230099",
  "DSH230121",
  "DSH230132",
  "DSH230151",
  "DSH230207",
  "DSH230230",
  "DSH240001",
  "DSH240019",
  "DSH240036",
  "DSH240047",
  "DSH240080",
  "DSH240084",
  "DSH240088",
  "DSH240100",
  "DSH240101",
  "DSH250001",
  "DSH250004",
  "DSH250019",
  "DSH250040",
  "DSH250048",
  "DSH250069",
  "DSH250104",
  "DSH250123",
  "DSH250162",
  "DSH260032",
  "DSH260040",
  "DSH260048",
  "DSH260091",
  "DSH260102",
  "DSH260104",
  "DSH260137",
  "DSH260163",
  "DSH260195",
  "DSH270004",
  "DSH280013",
  "DSH290001",
  "DSH290019",
  "DSH310002",
  "DSH310009",
  "DSH310038",
  "DSH310039",
  "DSH310058",
  "DSH310064",
  "DSH310070",
  "DSH310074",
  "DSH310084",
  "DSH310086",
  "DSH320001",
  "DSH320002",
  "DSH320004",
  "DSH320089",
  "DSH330013",
  "DSH330024",
  "DSH330027",
  "DSH330043",
  "DSH330044",
  "DSH330046",
  "DSH330055",
  "DSH330056",
  "DSH330059",
  "DSH330080",
  "DSH330084",
  "DSH330090",
  "DSH330096",
  "DSH330101",
  "DSH330104",
  "DSH330119",
  "DSH330160",
  "DSH330169",
  "DSH330194",
  "DSH330195",
  "DSH330197",
  "DSH330202",
  "DSH330204",
  "DSH330214",
  "DSH330234",
  "DSH330236",
  "DSH330241",
  "DSH330261",
  "DSH330285",
  "DSH330350",
  "DSH330393",
  "DSH330396",
  "DSH330399",
  "DSH340010",
  "DSH340021",
  "DSH340023",
  "DSH340028",
  "DSH340030",
  "DSH340032",
  "DSH340040",
  "DSH340061",
  "DSH340090",
  "DSH340141",
  "DSH340143",
  "DSH340186",
  "DSH350011",
  "DSH350015",
  "DSH360008",
  "DSH360011",
  "DSH360012",
  "DSH360014",
  "DSH360017",
  "DSH360020",
  "DSH360025",
  "DSH360027",
  "DSH360035",
  "DSH360048",
  "DSH360051",
  "DSH360054",
  "DSH360059",
  "DSH360066",
  "DSH360068",
  "DSH360076",
  "DSH360085",
  "DSH360112",
  "DSH360118",
  "DSH360133",
  "DSH360134",
  "DSH360218",
  "DSH360236",
  "DSH360276",
  "DSH370037",
  "DSH370093",
  "DSH380009",
  "DSH380022",
  "DSH380051",
  "DSH380060",
  "DSH380061",
  "DSH380102",
  "DSH390001",
  "DSH390006",
  "DSH390027",
  "DSH390030",
  "DSH390044",
  "DSH390048",
  "DSH390111",
  "DSH390133",
  "DSH390174",
  "DSH390185",
  "DSH390201",
  "DSH390226",
  "DSH390256",
  "DSH390270",
  "DSH410007",
  "DSH420049",
  "DSH420051",
  "DSH430015",
  "DSH430016",
  "DSH430027",
  "DSH430077",
  "DSH440002",
  "DSH440015",
  "DSH440049",
  "DSH440104",
  "DSH440125",
  "DSH440176",
  "DSH450015",
  "DSH450018",
  "DSH450024",
  "DSH450034",
  "DSH450039",
  "DSH450046",
  "DSH450051",
  "DSH450054",
  "DSH450068",
  "DSH450101",
  "DSH450102",
  "DSH450154",
  "DSH450184",
  "DSH450213",
  "DSH450272",
  "DSH450289",
  "DSH450346",
  "DSH450352",
  "DSH450686",
  "DSH450690",
  "DSH450809",
  "DSH460009",
  "DSH470003",
  "DSH490009",
  "DSH490024",
  "DSH490032",
  "DSH490045",
  "DSH490052",
  "DSH490094",
  "DSH490098",
  "DSH490114",
  "DSH500002",
  "DSH500003",
  "DSH500008",
  "DSH500016",
  "DSH500019",
  "DSH500021",
  "DSH500027",
  "DSH500036",
  "DSH500041",
  "DSH500054",
  "DSH500058",
  "DSH500077",
  "DSH500108",
  "DSH510001",
  "DSH510006",
  "DSH510007",
  "DSH510008",
  "DSH510058",
  "DSH520075",
  "DSH520087",
  "DSH520177",
  "FQHC638021",
  "FQHCLA335",
  "PED013300-00",
  "PED013301-00",
  "PED033302-00",
  "PED043300-00",
  "PED043301-00",
  "PED053301-00",
  "PED053302-00",
  "PED053304-00",
  "PED063301-00",
  "PED083300-00",
  "PED103301-00",
  "PED113300-00",
  "PED123300-00",
  "PED143300-00",
  "PED173300-00",
  "PED193300-00",
  "PED223302-00",
  "PED263301-00",
  "PED263302-00",
  "PED283300-00",
  "PED283301-00",
  "PED363303-00",
  "PED363305-00",
  "PED393303-00",
  "PED393307-00",
  "PED443303-00",
  "PED453300-00",
  "PED453301-00",
  "PED453302-00",
  "PED453304-00",
  "PED453306-00",
  "PED453315-00",
  "PED493301-00",
  "PED503300-00",
  "PED523300-00",
  "RRC010024-00",
  "RRC050567-00",
  "RRC070024-00",
  "RRC100105-00",
  "RRC120022-00",
  "RRC120026-00",
  "RRC140179-00",
  "RRC150015-00",
  "RRC150018-00",
  "RRC150023-00",
  "RRC150082-00",
  "RRC150162-00",
  "RRC200024-00",
  "RRC220071-00",
  "RRC220110-00",
  "RRC230130-00",
  "RRC230141-00",
  "RRC250009-00",
  "RRC250040-00",
  "RRC330119-00",
  "RRC330126-00",
  "RRC350070-00",
  "RRC360011-00",
  "RRC360027-00",
  "RRC360065-00",
  "RRC360066-00",
  "RRC360147-00",
  "RRC360180-00",
  "RRC380014-00",
  "RRC390001-00",
  "RRC390046-00",
  "RRC390067-00",
  "RRC390133-00",
  "RRC440059-00",
  "RRC440073-00",
  "RRC500002-00",
  "RRC500039-00",
  "RRC500072-00",
  "RRC520004-00",
  "RRC520070-00",
  "SCH010035-00",
  "SCH030068-00",
  "SCH060049-00",
  "SCH120014-00",
  "SCH130002-00",
  "SCH140015-00",
  "SCH140189-00",
  "SCH150048-00",
  "SCH150069-00",
  "SCH170145-00",
  "SCH180038-00",
  "SCH220177-00",
  "SCH230097-00",
  "SCH230110-00",
  "SCH230133-00",
  "SCH230222-00",
  "SCH240043-00",
  "SCH240075-00",
  "SCH240093-00",
  "SCH240166-00",
  "SCH250057-00",
  "SCH260094-00",
  "SCH260195-00",
  "SCH290019-00",
  "SCH330079-00",
  "SCH330136-00",
  "SCH350006-00",
  "SCH350019-00",
  "SCH380052-00",
  "SCH390048-00",
  "SCH490018-00",
  "STD89118",
  "STD89123",
];

export const nucalaBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];
export const nucalaBlockedCes = [
  "CAH041307-00",
  "CAH041309-00",
  "CAH051316-00",
  "CAH061312-00",
  "CAH061314-00",
  "CAH111306-00",
  "CAH131314-00",
  "CAH131327-00",
  "CAH141346-00",
  "CAH151310-00",
  "CAH151315-00",
  "CAH151323-00",
  "CAH151329-00",
  "CAH151332-00",
  "CAH161334-00",
  "CAH161341-00",
  "CAH161363-00",
  "CAH161367-00",
  "CAH171345-00",
  "CAH171350-00",
  "CAH171361-00",
  "CAH171371-00",
  "CAH171384-00",
  "CAH201314-00",
  "CAH231323-00",
  "CAH231332-00",
  "CAH231338-00",
  "CAH231339-00",
  "CAH241339-00",
  "CAH241360-00",
  "CAH241374-00",
  "CAH261311-00",
  "CAH261322-00",
  "CAH271340-00",
  "CAH271345-00",
  "CAH281329-00",
  "CAH281336-00",
  "CAH281362-00",
  "CAH281363-00",
  "CAH301312-00",
  "CAH321310-00",
  "CAH361329-00",
  "CAH381302-00",
  "CAH381309-00",
  "CAH391314-00",
  "CAH431319-00",
  "CAH431322-00",
  "CAH431326-00",
  "CAH471307-00",
  "CAH501314-00",
  "CAH501316-00",
  "CAH511315-00",
  "CAH511321-00",
  "CAH521310-00",
  "CAH521334-00",
  "CAH521346-00",
  "CAH521358-00",
  "CAN050146-00",
  "CH00456E",
  "CH010720",
  "CH030790",
  "CH031060A",
  "CH03308G",
  "CH045230",
  "CH04684A",
  "CH077570",
  "CH07757Y",
  "CH10036P",
  "CH10130B",
  "CHC24161-00",
  "CHC24169-02",
  "CHC28987-00",
  "CHC33644-00",
  "DSH010023",
  "DSH010029",
  "DSH010087",
  "DSH010092",
  "DSH010164",
  "DSH020001",
  "DSH020008",
  "DSH030014",
  "DSH030023",
  "DSH030062",
  "DSH040014",
  "DSH040015",
  "DSH040016",
  "DSH040020",
  "DSH040029",
  "DSH040119",
  "DSH050006",
  "DSH050025",
  "DSH050026",
  "DSH050030",
  "DSH050038",
  "DSH050040",
  "DSH050057",
  "DSH050060",
  "DSH050077",
  "DSH050100",
  "DSH050115",
  "DSH050128",
  "DSH050133",
  "DSH050159",
  "DSH050168",
  "DSH050222",
  "DSH050262",
  "DSH050292",
  "DSH050301",
  "DSH050315",
  "DSH050327",
  "DSH050334",
  "DSH050342",
  "DSH050348",
  "DSH050360",
  "DSH050367",
  "DSH050373",
  "DSH050376",
  "DSH050454",
  "DSH050492",
  "DSH050599",
  "DSH060003",
  "DSH060006",
  "DSH060010",
  "DSH060011",
  "DSH060012",
  "DSH060013",
  "DSH060022",
  "DSH060024",
  "DSH060031",
  "DSH060054",
  "DSH060075",
  "DSH060104",
  "DSH070002",
  "DSH070006",
  "DSH070025",
  "DSH070029",
  "DSH070035",
  "DSH070036",
  "DSH100002",
  "DSH100008",
  "DSH100012",
  "DSH100022",
  "DSH100052",
  "DSH100075",
  "DSH100093",
  "DSH100157",
  "DSH100173",
  "DSH110024",
  "DSH110087",
  "DSH110104",
  "DSH110105",
  "DSH110124",
  "DSH110128",
  "DSH110161",
  "DSH120006",
  "DSH120014",
  "DSH120022",
  "DSH130006",
  "DSH130007",
  "DSH130013",
  "DSH130071",
  "DSH140067",
  "DSH140091",
  "DSH140110",
  "DSH140114",
  "DSH140119",
  "DSH140124",
  "DSH140166",
  "DSH140172",
  "DSH140179",
  "DSH140185",
  "DSH140276",
  "DSH140280",
  "DSH140292",
  "DSH140304",
  "DSH150004",
  "DSH150007",
  "DSH150011",
  "DSH150021",
  "DSH150051",
  "DSH150056",
  "DSH150058",
  "DSH150065",
  "DSH150074",
  "DSH150089",
  "DSH150091",
  "DSH150169",
  "DSH160033",
  "DSH160045",
  "DSH160047",
  "DSH160057",
  "DSH160058",
  "DSH160064",
  "DSH160080",
  "DSH160082",
  "DSH160083",
  "DSH160101",
  "DSH160110",
  "DSH160146",
  "DSH170040",
  "DSH170074",
  "DSH170086",
  "DSH170145",
  "DSH180001",
  "DSH180009",
  "DSH180011",
  "DSH180012",
  "DSH180017",
  "DSH180018",
  "DSH180025",
  "DSH180035",
  "DSH180044",
  "DSH180049",
  "DSH180051",
  "DSH180067",
  "DSH180079",
  "DSH180080",
  "DSH180087",
  "DSH180088",
  "DSH180103",
  "DSH180104",
  "DSH180138",
  "DSH180143",
  "DSH190002",
  "DSH190004",
  "DSH190005",
  "DSH190006",
  "DSH190008",
  "DSH190015",
  "DSH190017",
  "DSH190019",
  "DSH190039",
  "DSH190040",
  "DSH190044",
  "DSH190045",
  "DSH190050",
  "DSH190054",
  "DSH190060",
  "DSH190064",
  "DSH190065",
  "DSH190098",
  "DSH190102",
  "DSH190111",
  "DSH190183",
  "DSH190201",
  "DSH190202",
  "DSH200008",
  "DSH200009",
  "DSH200018",
  "DSH200024",
  "DSH200033",
  "DSH210032",
  "DSH220011",
  "DSH220016",
  "DSH220019",
  "DSH220024",
  "DSH220031",
  "DSH220033",
  "DSH220035",
  "DSH220066",
  "DSH220071",
  "DSH220077",
  "DSH220086",
  "DSH220095",
  "DSH220119",
  "DSH220177",
  "DSH230015",
  "DSH230017",
  "DSH230020",
  "DSH230021",
  "DSH230029",
  "DSH230030",
  "DSH230035",
  "DSH230036",
  "DSH230038",
  "DSH230046",
  "DSH230053",
  "DSH230058",
  "DSH230059",
  "DSH230066",
  "DSH230070",
  "DSH230072",
  "DSH230075",
  "DSH230080",
  "DSH230081",
  "DSH230089",
  "DSH230092",
  "DSH230099",
  "DSH230121",
  "DSH230151",
  "DSH230167",
  "DSH230230",
  "DSH240001",
  "DSH240019",
  "DSH240022",
  "DSH240036",
  "DSH240047",
  "DSH240084",
  "DSH240088",
  "DSH240100",
  "DSH240101",
  "DSH240214",
  "DSH250001",
  "DSH250004",
  "DSH250019",
  "DSH250040",
  "DSH250048",
  "DSH250058",
  "DSH250069",
  "DSH250078",
  "DSH250082",
  "DSH250097",
  "DSH250104",
  "DSH250123",
  "DSH260032",
  "DSH260040",
  "DSH260048",
  "DSH260091",
  "DSH260104",
  "DSH260137",
  "DSH260163",
  "DSH270004",
  "DSH280013",
  "DSH280060",
  "DSH290001",
  "DSH310003",
  "DSH310032",
  "DSH310038",
  "DSH310039",
  "DSH310064",
  "DSH310070",
  "DSH310075",
  "DSH310084",
  "DSH320001",
  "DSH320002",
  "DSH320004",
  "DSH330009",
  "DSH330013",
  "DSH330014",
  "DSH330024",
  "DSH330044",
  "DSH330046",
  "DSH330055",
  "DSH330059",
  "DSH330080",
  "DSH330090",
  "DSH330101",
  "DSH330104",
  "DSH330119",
  "DSH330127",
  "DSH330135",
  "DSH330136",
  "DSH330158",
  "DSH330160",
  "DSH330169",
  "DSH330180",
  "DSH330195",
  "DSH330197",
  "DSH330204",
  "DSH330214",
  "DSH330221",
  "DSH330236",
  "DSH330241",
  "DSH330250",
  "DSH330261",
  "DSH330264",
  "DSH330285",
  "DSH330393",
  "DSH330395",
  "DSH330399",
  "DSH340010",
  "DSH340021",
  "DSH340023",
  "DSH340027",
  "DSH340028",
  "DSH340030",
  "DSH340040",
  "DSH340061",
  "DSH340069",
  "DSH340099",
  "DSH340113",
  "DSH340141",
  "DSH340143",
  "DSH340145",
  "DSH350011",
  "DSH350015",
  "DSH360008",
  "DSH360012",
  "DSH360017",
  "DSH360025",
  "DSH360027",
  "DSH360035",
  "DSH360051",
  "DSH360054",
  "DSH360059",
  "DSH360064",
  "DSH360066",
  "DSH360068",
  "DSH360076",
  "DSH360077",
  "DSH360081",
  "DSH360085",
  "DSH360086",
  "DSH360112",
  "DSH360118",
  "DSH360132",
  "DSH360133",
  "DSH360134",
  "DSH360156",
  "DSH360159",
  "DSH360161",
  "DSH360170",
  "DSH360172",
  "DSH360189",
  "DSH360218",
  "DSH360236",
  "DSH360276",
  "DSH370093",
  "DSH380009",
  "DSH380021",
  "DSH380022",
  "DSH380056",
  "DSH380060",
  "DSH380061",
  "DSH380075",
  "DSH380102",
  "DSH390001",
  "DSH390006",
  "DSH390027",
  "DSH390030",
  "DSH390041",
  "DSH390044",
  "DSH390086",
  "DSH390111",
  "DSH390113",
  "DSH390115",
  "DSH390133",
  "DSH390156",
  "DSH390174",
  "DSH390185",
  "DSH390223",
  "DSH390256",
  "DSH390270",
  "DSH410007",
  "DSH420051",
  "DSH430015",
  "DSH430016",
  "DSH430027",
  "DSH430077",
  "DSH440015",
  "DSH440104",
  "DSH450015",
  "DSH450018",
  "DSH450024",
  "DSH450034",
  "DSH450039",
  "DSH450046",
  "DSH450054",
  "DSH450102",
  "DSH450184",
  "DSH450213",
  "DSH450289",
  "DSH450352",
  "DSH450686",
  "DSH450690",
  "DSH450801",
  "DSH460004",
  "DSH460009",
  "DSH470003",
  "DSH490009",
  "DSH490017",
  "DSH490024",
  "DSH490032",
  "DSH490037",
  "DSH490052",
  "DSH490053",
  "DSH490090",
  "DSH490094",
  "DSH500002",
  "DSH500003",
  "DSH500014",
  "DSH500016",
  "DSH500019",
  "DSH500021",
  "DSH500027",
  "DSH500036",
  "DSH500041",
  "DSH500050",
  "DSH500054",
  "DSH500058",
  "DSH500077",
  "DSH500108",
  "DSH510001",
  "DSH510006",
  "DSH510007",
  "DSH510008",
  "DSH510013",
  "DSH510030",
  "DSH510038",
  "DSH510055",
  "DSH510058",
  "DSH520013",
  "DSH520017",
  "DSH520075",
  "DSH520087",
  "DSH520177",
  "FQHC638024",
  "FQHCLA335",
  "HV00130",
  "PED013301-00",
  "PED053301-00",
  "PED053302-00",
  "PED053304-00",
  "PED053305-00",
  "PED053309-00",
  "PED063301-00",
  "PED223302-00",
  "PED243302-00",
  "PED263302-00",
  "PED283301-00",
  "PED363303-00",
  "PED363305-00",
  "PED393303-00",
  "PED443303-00",
  "PED453300-00",
  "PED453302-00",
  "PED453304-00",
  "PED453306-00",
  "PED503300-00",
  "PED523300-00",
  "RRC140135-00",
  "RRC150162-00",
  "RRC160057-00",
  "RRC250009-00",
  "RRC250040-00",
  "RRC330119-00",
  "RRC330164-00",
  "RRC360009-00",
  "RRC360036-00",
  "RRC390001-00",
  "RRC440073-00",
  "RRC500039-00",
  "SCH030007-00",
  "SCH030055-00",
  "SCH040047-00",
  "SCH120014-00",
  "SCH140189-00",
  "SCH150048-00",
  "SCH150069-00",
  "SCH170023-00",
  "SCH200050-00",
  "SCH230133-00",
  "SCH230222-00",
  "SCH240075-00",
  "SCH250057-00",
  "SCH330136-00",
  "SCH360125-00",
  "SCH380001-00",
  "SCH430048",
  "STD70002",
];
export const zejulaBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];
export const zejulaBlockedCes = [
  "CAH521329-00",
  "CAH521340-00",
  "CAN050146-00",
  "CHC28973-02",
  "DSH010029",
  "DSH030014",
  "DSH040016",
  "DSH050013",
  "DSH050025",
  "DSH050038",
  "DSH050039",
  "DSH050040",
  "DSH050077",
  "DSH050245",
  "DSH050315",
  "DSH050327",
  "DSH050335",
  "DSH050348",
  "DSH050367",
  "DSH050373",
  "DSH050376",
  "DSH050454",
  "DSH050599",
  "DSH060011",
  "DSH060024",
  "DSH070025",
  "DSH100008",
  "DSH100022",
  "DSH110087",
  "DSH110104",
  "DSH110161",
  "DSH130006",
  "DSH140067",
  "DSH140091",
  "DSH140119",
  "DSH140172",
  "DSH140276",
  "DSH140304",
  "DSH150007",
  "DSH150021",
  "DSH150023",
  "DSH150056",
  "DSH150074",
  "DSH160058",
  "DSH170040",
  "DSH180011",
  "DSH180035",
  "DSH180067",
  "DSH180080",
  "DSH180088",
  "DSH180103",
  "DSH180104",
  "DSH180138",
  "DSH200009",
  "DSH200024",
  "DSH200033",
  "DSH220011",
  "DSH220031",
  "DSH220077",
  "DSH230017",
  "DSH230046",
  "DSH230053",
  "DSH230075",
  "DSH230167",
  "DSH230230",
  "DSH230297",
  "DSH240019",
  "DSH240036",
  "DSH240084",
  "DSH240088",
  "DSH240100",
  "DSH250001",
  "DSH250004",
  "DSH260048",
  "DSH260137",
  "DSH280013",
  "DSH310027",
  "DSH310064",
  "DSH310086",
  "DSH320001",
  "DSH330014",
  "DSH330024",
  "DSH330043",
  "DSH330046",
  "DSH330059",
  "DSH330101",
  "DSH330160",
  "DSH330169",
  "DSH330195",
  "DSH330214",
  "DSH330241",
  "DSH330261",
  "DSH330285",
  "DSH340028",
  "DSH340030",
  "DSH340040",
  "DSH340061",
  "DSH340113",
  "DSH340141",
  "DSH350011",
  "DSH350015",
  "DSH360020",
  "DSH360027",
  "DSH360059",
  "DSH360077",
  "DSH360086",
  "DSH360132",
  "DSH370093",
  "DSH380009",
  "DSH380061",
  "DSH390001",
  "DSH390006",
  "DSH390044",
  "DSH390111",
  "DSH390115",
  "DSH390133",
  "DSH390174",
  "DSH390226",
  "DSH390256",
  "DSH390270",
  "DSH410007",
  "DSH420051",
  "DSH430016",
  "DSH430027",
  "DSH440015",
  "DSH440063",
  "DSH440176",
  "DSH450024",
  "DSH450046",
  "DSH450289",
  "DSH450690",
  "DSH460009",
  "DSH470003",
  "DSH490009",
  "DSH490032",
  "DSH490037",
  "DSH490052",
  "DSH490114",
  "DSH500027",
  "DSH500036",
  "DSH500058",
  "DSH510055",
  "DSH520087",
  "DSH520177",
  "FQHCLA335",
  "PED013301-00",
  "RRC200024-00",
  "RRC360027-00",
  "RRC360180-00",
  "RRC390133-00",
  "RRC520070-00",
  "SCH240043-00",
  "SCH240075-00",
  "SCH240093-00",
  "SCH350019-00",
  "SCH490021-00",
];

export const flolanBlockedTypeCodes = ["SCH", "RRC", "CAH", "CAN"];
