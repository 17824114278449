import _ from "lodash";

export const mapManufacturersForSelect = (manufacturers) => {
  const NUM_GROUPS = 3;

  const manufacturersGroupedByStartingLetter = _.groupBy(
    manufacturers.slice(1),
    (manufacturer) => manufacturer.label[0]
  );

  const optionsFormattedForMenu = Object.keys(manufacturersGroupedByStartingLetter).map((key) => ({
    label: key,
    options: manufacturersGroupedByStartingLetter[key].map(({ value, label }) => ({ value, label })),
  }));

  const divideDataIntoGroups = (data, NUM_GROUPS) => {
    const totalManufacturers = data.reduce((count, option) => count + option.options.length, 0);
    const manufacturersPerGroup = Math.ceil(totalManufacturers / NUM_GROUPS);

    const groups = Array.from({ length: NUM_GROUPS }, () => []);

    let groupIndex = 0;
    let currentGroupManufacturers = 0;

    data.forEach((option) => {
      if (currentGroupManufacturers + option.options.length <= manufacturersPerGroup) {
        groups[groupIndex].push(option);
        currentGroupManufacturers += option.options.length;
      } else {
        groupIndex++;
        currentGroupManufacturers = option.options.length;
        groups[groupIndex].push(option);
      }
    });

    return groups;
  };

  return divideDataIntoGroups(optionsFormattedForMenu, NUM_GROUPS);
};
