import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import { ExpandMore, Check } from "@material-ui/icons";

const DropdownSelectMapping = (props) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!btnRef.current.contains(e.target)) {
        setDisplayDropdown(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  // should rename position everywhere to ID - ID alias for position
  const getAttachmentByID = (id) => {
    return _.find(props.attachments, (data) => data.position == id);
  };

  const getMappingDetails = (id) => {
    var attachment = getAttachmentByID(id);

    if (attachment) {
      return attachment.mapping.name;
    } else {
      return "No Mapping Selected";
    }
  };

  const renderDropDown = () => {
    if (displayDropdown) {
      return (
        <div className="attachments__tile__dropdown__container">
          {_.map(props.mappings, (mapping, index) => {
            const selectedMapping = props.selectedMapping.id === mapping.id;
            return (
              <div
                key={index}
                className={`attachments__tile__dropdown__item ${
                  selectedMapping ? "attachments__tile__dropdown__item--selected" : ""
                }`}
                onClick={() =>
                  props.handleSelectMapping(props.submissionId, props.fileId, mapping.id)
                }
              >
                {mapping.name}
                {selectedMapping && <Check style={{ fontSize: 14, color: "#3246D3" }} />}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();

    if (!props.mappings.length) {
      return;
    }

    setDisplayDropdown(!displayDropdown);
  };

  const getClassNames = () => {
    if (!props.mappings.length) {
      return "attachments__tile__dropdown__button--disabled";
    } else if (displayDropdown) {
      return "attachments__tile__dropdown__button--hover";
    }

    return null;
  };

  const renderSelectedMappingName = () => {
    if (!props.mappings.length) {
      return "N/A";
    } else if (props.selectedMapping) {
      return props.selectedMapping.name;
    } else {
      return "No Mapping Selected";
    }
  };

  return (
    <div
      ref={btnRef}
      className={`attachments__tile__dropdown__button ${getClassNames()}`}
      onClick={handleClick}
    >
      {renderSelectedMappingName()}
      <ExpandMore fontSize="small" />
      {renderDropDown()}
    </div>
  );
};

export default DropdownSelectMapping;
