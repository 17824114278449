import React from "react";
import _ from "lodash";

import Errors from "./errors";

import { formatFilterKey } from "../utils/data_helpers";

const Notices = ({ data }) => {
  const formattedData = data?.flatMap((item) => item.formattedData);
  const isNotEmpty = _.compact(
    data?.flatMap((item) => item.column_mapping_warnings)
  );

  if (data.length <= 0) {
    return null;
  }

  const renderNotice = () => {
    if (data && formattedData && formattedData.length === 0) {
      return renderMessageNothingToSubmit();
    } else {
      return (
        <Errors
          hasError={_.some(data, (row) => row.hasErrors)}
        />
      );
    }
  };

  const renderMessageNothingToSubmit = () => {
    return (
      <div className="notify__banner">
        <div className="notify__banner__icon">
          <i className="solid solid-budicon-notification"> </i>
        </div>
        <div className="notify__banner__notice__title">
          <strong>Nothing to submit -</strong>
        </div>
        <div className="notify__banner__notice">
          {`It looks like there are no matching ${formatFilterKey(
            data[0].filterKey
          )} in this file.`}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderNotice()}
    </div>
  );
};

export default Notices;
