import React, { useState } from "react";

import TutorialModal from "../../../../modals/library/tutorial_modal";

import slideOneImg from "~/images/submission_tutorial_slide_1.png";
import slideTwoImg from "~/images/submission_tutorial_slide_2.png";
import slideThreeImg from "~/images/submission_tutorial_slide_3.png";
import slideFourImg from "~/images/submission_tutorial_slide_4.png";
import slideFiveImg from "~/images/submission_tutorial_slide_5.png";
import slideSixImg from "~/images/submission_tutorial_slide_6.png";

const slides = [
  {
    img: slideOneImg,
    title: "Switch between Submission and Original File view",
    tooltips: [
      {
        anchorPosition: "top-left",
        top: 80,
        left: 88,
        header: "Submission and Original File",
        content: "Easily navigate between Submission and Original File.",
      },
      {
        anchorPosition: "top-left",
        top: 326,
        left: 276,
        header: "De-identified Data",
        content: "The first three columns of the table contain de-identified data.",
      },
    ],
  },
  {
    img: slideTwoImg,
    title: "Understanding highlighted cells and rows",
    tooltips: [
      {
        anchorPosition: "bottom-left",
        top: 176,
        left: 316,
        header: "Why is this column highlighted?",
        content:
          "Columns that are highlighted in dark blue are the columns that ESP 2 will extract for submission.",
      },
      {
        anchorPosition: "bottom-right",
        top: 266,
        left: 770,
        header: "Why isn’t this row selected?",
        content: "ESP only processes certain NDCs. Download list here",
      },
    ],
  },
  {
    img: slideThreeImg,
    title: "Understanding the Display dropdown",
    tooltips: [
      {
        anchorPosition: "bottom-left",
        top: 116,
        left: 406,
        header: "Display dropdown",
        content:
          "This dropdown allows you to view only rows with errors, only rows extracted for submission or only rows not selected for submission.",
      },
    ],
  },
  {
    img: slideFourImg,
    title: "Understanding the File dropdown",
    tooltips: [
      {
        anchorPosition: "left-top",
        top: 116,
        left: 228,
        header: "File dropdown",
        content: "Use this dropdown to switch between uploaded files.",
      },
    ],
  },
  {
    img: slideFiveImg,
    title: "Understanding error messages",
    tooltips: [
      {
        anchorPosition: "top-left",
        top: 168,
        left: 184,
        header: "What does this error mean?",
        content:
          "There is an error in your file that you must correct before you can submit. The field(s) will be highlighted in red. Make sure to scroll through the whole file to inspect everything!",
      },
      {
        anchorPosition: "top-left",
        top: 372,
        left: 324,
        header: "Why is this cell in red?",
        content:
          "Columns that are highlighted in red indicate an error that you will need to fix before you can complete your submission.",
      },
    ],
  },
  {
    img: slideSixImg,
    title: "Understanding Submission Details",
    tooltips: [
      {
        anchorPosition: "left-top",
        top: 96,
        left: 373,
        header: "Submission Details",
        content:
          "Here you can review the details for the attached files. You are able to change the name of the submission if needed.",
      },
      {
        anchorPosition: "left-top",
        top: 214,
        left: 358,
        header: "Attaching Files",
        content: "Here you can attach additional or revised files.",
      },
      {
        anchorPosition: "left-top",
        top: 288,
        left: 373,
        header: "Error",
        content:
          "This file contains errors. Before being able to proceed you must remove, revise and re-attach.",
      },
      {
        anchorPosition: "bottom-left",
        top: 270,
        left: 635,
        header: "Validated as “Good”",
        content: "Once ESP validates the file as “Good”, you can save and submit the file.",
      },
      {
        anchorPosition: "bottom-left",
        top: 326,
        left: 590,
        header: "Column Mapping dropdown",
        content: "Use this dropdown to switch between saved Column Mappings.",
      },
    ],
  },
];

const SubmissionTutorial = ({ handleClose }) => {
  return <TutorialModal slides={slides} handleClose={handleClose} />;
};

export default SubmissionTutorial;
