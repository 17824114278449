// Move to single source
export default [
  {
    value: "all",
    label: "View All",
  },
  {
    value: "abbvie",
    label: "Abbvie",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection: "340B claims are required for all designated contract pharmacies",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "amgen",
    label: "Amgen",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy or if the covered entity agrees to provide 340B claims data for its in-house pharmacies",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "astellas",
    label: "Astellas",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      exemptions:
        "Grantees and contract pharmacies located in Louisiana and Arkansas for covered entities located in Louisiana and Arkansas respectively are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "astrazeneca",
    label: "Astrazeneca",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
    },
  },
  {
    value: "bausch",
    label: "Bausch",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
    },
  },
  {
    value: "bayer",
    label: "Bayer",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      geofence:
        "Designated contract pharmacies for non-LDN products must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "biogen",
    label: "Biogen",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are exempted from the policy",
    },
  },
  {
    value: "bi",
    label: "Boehringer Ingelheim",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      geofence:
        "Designated contract pharmacies for non-LDN products must be within 40 miles of the covered entity parent site",
    },
  },
  {
    value: "bms",
    label: "Bristol Myers Squibb",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
    },
  },
  {
    value: "eisai",
    label: "Eisai",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      exemptions:
        "Grantees as well as all covered entities located in Louisiana and Arkansas are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "eli lilly",
    label: "Eli Lilly",
    policies: {},
  },
  {
    value: "emd serono",
    label: "EMD Serono",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are exempted from the policy",
    },
  },
  {
    value: "exelixis",
    label: "Exelixis",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection:
        "340B claims are required for all contract pharmacies other than designated contract pharmacies",
      exemptions:
        "Grantees as well as RRC, CAH, CAN and SCH hospital entity types are exempted from the contract pharmacy policy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are exempted from the policy",
    },
  },
  {
    value: "gilead",
    label: "Gilead",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection:
        "340B claims are required for all contract pharmacies other than designated or wholly-owned contract pharmacies",
    },
  },
  {
    value: "gsk",
    label: "GlaxoSmithKline",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are exempted from the policy",
    },
  },
  {
    value: "incyte",
    label: "Incyte",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "jazz",
    label: "Jazz",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "jnj",
    label: "Johnson & Johnson",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy or if the covered entity agrees to provide 340B claims data for its in-house pharmacies",
      claimsCollection: "340B claims are required for all designated contract pharmacies",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "merck",
    label: "Merck",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection:
        "340B claims are required for all contract pharmacies permitted as part of a state-level exemption",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions:
        "Non-CH grantees as well as all covered entities located in Louisiana and Arkansas are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "novo nordisk",
    label: "Novo Nordisk",
    policies: {
      cpDesignations: "Covered entity may designate a single retail and a single specialty contract pharmacy",
      claimsCollection:
        "340B claims are required for all wholly-owned contract pharmacies that are permitted outside of a contract pharmacy designation",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are permitted with claims submissions",
    },
  },
  {
    value: "novartis",
    label: "Novartis",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are exempted from the policy",
    },
  },
  {
    value: "organon",
    label: "Organon",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection: "340B claims are required for all designated contract pharmacies",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "pfizer",
    label: "Pfizer",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection: "340B claims are required for all contract pharmacies for DON products and VYNDA",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership with the 340B covered entity are permitted; claims submissions are required for DON products and VYNDA",
    },
  },
  {
    value: "sandoz",
    label: "Sandoz",
    policies: {
      cpDesignations: "Covered entity may designate a single contract pharmacy location",
      claimsCollection: "340B claims are required for all designated contract pharmacies",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "sanofi",
    label: "Sanofi",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection: "340B claims are required for all contract pharmacies for CH grantees",
      exemptions: "Non-CH grantees as well as PED and CAN entity types are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "takeda",
    label: "Takeda",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection: "340B claims are required for all wholly-owned and designated contract pharmacies",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      whollyOwned:
        "Contract pharmacies that are owned by or share common ownership within 40 miles of the the 340B covered entity are permitted with claims submissions",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "teva",
    label: "Teva",
    policies: {
      lookback:
        "340B claims must be submitted within 45 days of the date of dispense to be considered a valid claim submission",
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      claimsCollection:
        "340B claims are required for all designated contract pharmacies or contract pharmacies permitted as part of a state-level exemption",
      geofence: "Designated contract pharmacies must be within 40 miles of the covered entity parent site",
      exemptions:
        "Grantees as well as all covered entities located in Louisiana and contract pharmacies in Arkansas for covered entities in Arkansas are exempted from the contract pharmacy policy",
    },
  },
  {
    value: "ucb",
    label: "UCB",
    policies: {
      cpDesignations:
        "Covered entity may designate a single contract pharmacy location if the covered entity does not operate an in-house pharmacy",
      exemptions: "Grantees are exempted from the contract pharmacy policy",
    },
  },
];
