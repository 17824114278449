import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Pfizer from "~/images/Pfizer.png";

const AgreementPfizer = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "pfizer") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Pfizer} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Pfizer</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Certification</div>
            </div>
          </div>
          <p>
            For orders made on or after March 1, 2022, wholesalers on behalf of Pfizer will ship certain products
            purchased at the 340B price to locations registered within the Health Resources and Services Administration
            (HRSA) database as a 340B covered entity or child site location. In addition, any contract pharmacies that
            are wholly owned by a 340B covered entity, or are under common ownership with a health system, will remain
            eligible to receive Bill To / Ship To orders of 340B priced drugs upon registering on the ESP Platform and
            receiving approval for a wholly owned exemption. These wholly owned pharmacies must be registered within the
            HRSA contract pharmacy database as a contract pharmacy location of their related 340B covered entity. If a
            covered entity is granted an exemption for a wholly owned contract pharmacy(ies), it may not also designate
            an independent contract pharmacy via https://www.340besp.com/designations Upon approval, exempt wholly owned
            pharmacies are eligible to receive Bill To / Ship To orders of 340B priced drugs for their 340B covered
            entity and all its child sites.
          </p>
          <p>
            To apply for this exemption covered entities must complete this online form and submit proof of ownership
            documents online. Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Pfizer will process wholly owned contract pharmacy application requests within five business days of
            receipt. Exemptions will be valid for twelve (12) months from approval date. After the initial 12-month
            period, Pfizer may require covered entities to resubmit proof of ownership documents to validate the
            continued eligibility of existing exemptions.
          </p>
          <p>
            By signing this form the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this Exemption request: (i) have the same corporate ownership, OR
            (ii) that the covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity
            qualifies for the exemption as described in this form. If there are any changes to the ownership structure
            of any of the contract pharmacies identified in this form, the covered entity agrees to notify Pfizer at
            340BCP@Pfizer.com within five (5) business days of such change of ownership.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementPfizer;
