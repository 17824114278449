import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { fetchHistory } from "./http";
import TableLoadingSkeleton from "../TableLoadingSkeleton";
import {getEncounterVanityName} from "../../Submissions/utils/name_helper";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export const History = (props) => {
  const [historyLines, setHistoryLines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchHistory(props.history_path);
        setHistoryLines(response.data);
      } catch (error) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [props.history_path]);

  const renderDifferences = (differences, not_considered) => {
    if (differences.length == 0)
      return (
        <div style={{ marginTop: 10 }}>
          <div
            className="flex notify__timeline p--8 t--sm"
            style={{ gap: 10, margin: 4 }}
          >
            <FiberManualRecordIcon style={{ fontSize: 12 }} />
            <div className="flex">No changes between submitted claims</div>
          </div>
        </div>
      );
    return (
      <div style={{ marginTop: 10 }}>
        {differences.map((diff) => {
          return (
            <div
              className="flex notify__timeline p--8 t--sm"
              style={{ gap: 10 }}
              key={diff.column_name}
            >
              <FiberManualRecordIcon style={{ fontSize: 12 }} />
              <div className="flex">
                {getEncounterVanityName(diff.column_name)} was changed from
                <div
                  className={`badge ${not_considered ? "badge--disabled" : ""}`}
                  style={{ margin: "0px 5px" }}
                >
                  {diff.previous_value ? diff.previous_value : <em>empty</em>}
                </div>{" "}
                to
                <div
                  className={`badge ${not_considered ? "badge--disabled" : ""}`}
                  style={{ margin: "0px 5px" }}
                >
                  {diff.current_value ? diff.current_value : <em>empty</em>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTimeline = () => {
    if (isLoading) {
      return <table><TableLoadingSkeleton style={{width: '100%'}} rows={2} columns={4} /></table>;
    }
    return (
      <Timeline class="mb-0">
        {historyLines.map((history, index) => {
          return renderHistoryLine(
            history,
            index === historyLines.length - 1 ? null : historyLines[index + 1]
          );
        })}
      </Timeline>
    )
  }

  const renderHistoryLine = (history, next_history) => {
    return (
      <TimelineItem key={history.created_at}>
        <TimelineSeparator>
          <TimelineDot style={{ background: "#3246D3", boxShadow: "none" }} />
          {history.first ? (
            ""
          ) : (
            <TimelineConnector
              style={{ background: "#3246D3", boxShadow: "none" }}
            />
          )}
        </TimelineSeparator>
        <TimelineContent>
          <div
            className="flex flex-col item-start"
            style={history.not_considered ? { color: "#AAAAAA" } : {}}
          >
            <div className="flex item-start" style={{ gap: 20 }}>
              <div
                className="t--sm t--bold t--left"
                style={{ width: `${100 / 4}%` }}
              >
                {history.date_submitted}
              </div>
              <div
                className="flex flex-col item-start t--left"
                style={{ width: `${100 / 4}%` }}
              >
                <div className="t--sm">{history.submission_name}</div>
              </div>
              <div
                className="flex flex-col item-start t--left"
                style={{ width: `${100 / 4}%` }}
              >
                <div className="t--sm">{history.submitted_by}</div>
              </div>
              <div
                className="flex flex-col item-start t--left"
                style={{ width: `${100 / 4}%` }}
              >
                {history.patient_validation_codes.map((reason) => (
                  <div
                    className={`badge ${
                      history.not_considered ? "badge--disabled" : ""
                    } mb-1`}
                    key={reason.value}
                  >
                    <div className="badge__indicator badge__indicator--red" />
                    {reason.label}
                  </div>
                ))}
              </div>
            </div>
            {!history.first &&
              renderDifferences(history.differences, history.not_considered)}
            {next_history ? renderMaxResubmissionMessage(next_history.final, next_history.conforming) : ""}
          </div>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const renderMaxResubmissionMessage = (showWarning, conforming) => {
    if (showWarning) {
      return (
        <div style={{ marginTop: 10, color: "#25265e" }}>
          <div
            className="flex notify__timeline p--8 t--sm"
            style={{ gap: 10, margin: 4 }}
          >
            <div className="indicator indicator--red" />
            <div className="flex t--bold">
              {conforming
                ? "A conforming claim has already been submitted. Resubmissions passed this point are treated as duplicates and will not be considered."
                : "Maximum resubmissions reached. No claims considered after this point."}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p--8 tile">
      <div className="flex justify-between mt-1">
        <div className="flex item-start">
          <div className="table__subrow  table__subrow__icon">
            <HistoryIcon color="primary" fontSize="small" />
          </div>
          <div className="flex flex-col w--auto item-start ml-1">
            <span className="t--md t--bold t--upcase">Claim History</span>
            <span className="t--sm t--blue">
              {isLoading ? "Retrieving history..." : `${historyLines.length} submission${historyLines.length == 1 ? 's' : ""}`}
            </span>
          </div>
        </div>
      </div>

      <ThemeProvider theme={Theme}>
        <div
          className="flex justify-between p--8 notify__info notify__info__heading"
          style={{ gap: 20, padding: "8px 20px 8px 40px" }}
        >
          <div
            className="t--sm t--upcase t--bold flex justify-start"
            style={{ width: `${100 / 4}%` }}
          >
            submitted on
          </div>
          <div
            className="t--sm t--upcase t--bold flex justify-start"
            style={{ width: `${100 / 4}%` }}
          >
            submission name
          </div>
          <div
            className="t--sm t--upcase t--bold flex justify-start"
            style={{ width: `${100 / 4}%` }}
          >
            submitted by
          </div>
          <div
            className="t--sm t--upcase t--bold flex justify-start"
            style={{ width: `${100 / 4}%` }}
          >
            Issues
          </div>
        </div>
          {renderTimeline()}
        </ThemeProvider>
      </div>
    );
};
