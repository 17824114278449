import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { restoreClaimReq, withdrawClaimReq } from "./http";
import { History } from "./History";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export const TableRowSubComponent = ({ row, showCards = true }) => {
  const [displayRestoreModal, setDisplayRestoreModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  

  return (
    <div className="p--8 tile table__subrow">
      {showCards && (
        <>
          <div className="p--8 tile mb-1">
            <div className="flex justify-between" style={{ gap: 20 }}>
              <div className="card card__item card__item--light w--100 p--8">
                <h3 className="t--md t--bold t--upcase flex mb-2">
                  <div className="table__subrow  table__subrow__icon">
                    <ApartmentIcon color="primary" fontSize="small" />
                  </div>
                  Adjudicated Pharmacy claim information
                </h3>
                <div
                  className="grid"
                  style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: 10 }}
                >
                  <div className="card p--8">
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">Date of Service</div>
                      <div className="t--sm p--5 t--bold">
                        {" "}
                        {row.original.date_of_service?.substr(0, 10)}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">Date Prescribed </div>
                      <div className="t--sm p--5 t--bold">
                        {row.original.date_prescribed?.substr(0, 10)}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">RX Number </div>
                      <div className="t--sm p--5 t--bold">
                        {row.original.rx_number?.substr(0, 15)}...
                      </div>
                    </div>
                    <div className="flex t--left">
                      <div className="t--sm  p--5 t--gray">Quantity</div>
                      <div className="t--sm  p--5 t--bold">
                        {row.original.quantity}
                      </div>
                    </div>
                  </div>
                  <div className="card p--8">
                    <div className="flex item-start">
                      <div className="t--sm p--5 t--gray">Pharmacy </div>

                      <div className="flex flex-col t--left item-start">
                        <div className="t--sm p--5 t--bold">
                          {row.original.pharmacy?.id} <br />
                          {row.original.pharmacy.name} <br />
                          {row.original.pharmacy.address}
                        </div>
                      </div>
                    </div>
                    <div className="flex item-start">
                      <div className="t--sm p--5 t--gray">Prescriber </div>

                      <div className="flex flex-col t--left item-start">
                        <div className="t--sm p--5 t--bold">
                          {row.original.prescriber?.id} <br />
                          {row.original.prescriber.name} <br />
                          {row.original.prescriber.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card__item card__item--light w--100 p--8">
                <h3 className="t--md t--bold t--upcase flex mb-2">
                  <div className="table__subrow  table__subrow__icon">
                    <ApartmentIcon color="primary" fontSize="small" />
                  </div>
                  healthcare encounter information
                </h3>

                <div
                  className="grid"
                  style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: 10 }}
                >
                  <div className="card p--8">
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">
                        Billing Provider ID
                      </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.billing_provider.id}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">
                        Date of Encounter
                      </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.date_of_encounter?.substr(0, 10)}
                      </div>
                    </div>

                    <div className="flex">
                      <div className="t--sm p--5 t--gray">HCPCS Code </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.hcpcs_code}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">Diagnosis Code </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.diagnosis_code}
                      </div>
                    </div>
                  </div>
                  <div className="card p--8">
                    <div className="flex">
                      <div className="t--sm  p--5 t--gray">
                        Place of Service Code
                      </div>
                      <div className="t--sm  p--5 t--bold ">
                        {row.original.place_of_service_code}
                      </div>
                    </div>
                    <div className="flex item-start t--left">
                      <div className="t--sm p--5 t--gray">
                        Rendering Physician{" "}
                      </div>
                      <div className="flex flex-col item-start t--left">
                        <div className="t--sm p--5 t--bold ">
                          {row.original.rendering_physician.id} <br />
                          {row.original.rendering_physician.name} <br />
                          {row.original.rendering_physician.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <History history_path={row.original.history_path} />
    </div>
  );
};
