import React, { useState, useRef, useEffect } from "react";
import { ExpandMore, Check } from "@material-ui/icons";
import _ from "lodash";
import { motion } from "framer-motion";
import useResizeObserverWidth from "../../hooks/useWidthResizeObserver";

const OriginalFileSelect = ({ attachments, originalViewId, setOriginalViewId }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const btnRef = useRef();

  const width = useResizeObserverWidth(btnRef);

  const originalViewAttachment = !!originalViewId
    ? attachments.find((attachment) => attachment.id === originalViewId)
    : null;

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!btnRef.current.contains(e.target)) {
        setDisplayDropdown(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const getClassNames = () => {
    if (!attachments.length) {
      return "attachments__tile__dropdown__button--disabled";
    } else if (displayDropdown) {
      return "attachments__tile__dropdown__button--hover";
    }

    return "";
  };

  const handleClick = () => {
    if (!attachments.length) {
      return;
    }

    setDisplayDropdown(!displayDropdown);
  };

  const renderDropDown = () => {
    if (displayDropdown) {
      return (
        <div
          className="attachments__tile__dropdown__container"
          style={{ marginTop: 8, width: width }}
        >
          {_.map(attachments, (attachment, index) => {
            const selected = attachment.id === originalViewId;

            return (
              <div
                key={index}
                className={`attachments__tile__dropdown__item ${
                  selected ? "attachments__tile__dropdown__item--selected" : ""
                }`}
                style={{ overflow: "hidden" }}
                onClick={() => setOriginalViewId(attachment.id)}
              >
                {attachment.name}
                {selected && <Check style={{ fontSize: 14, color: "#3246D3" }} />}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderAttachmentName = () => {
    if (!attachments.length) {
      return "No file attached";
    } else if (originalViewAttachment) {
      return originalViewAttachment.name;
    } else {
      return "View Original Files";
    }
  };

  return (
    <motion.div layout>
      <div
        ref={btnRef}
        className={`attachments__tile__dropdown__button ${getClassNames()}`}
        style={{
          height: 47,
          minWidth: 336,
          marginTop: "unset",
          backgroundColor: "#ffffff",
          fontWeight: 400,
        }}
        onClick={handleClick}
      >
        <div>
          <span className="t--subtitle" style={{ marginRight: 6 }}>
            File:
          </span>
          <span>{renderAttachmentName()}</span>
        </div>
        <span style={{ marginLeft: 8, justifySelf: "end" }}>
          <ExpandMore fontSize="small" />
        </span>
        {renderDropDown()}
      </div>
    </motion.div>
  );
};

export default OriginalFileSelect;
