import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";
import CloseIcon from "@material-ui/icons/Close";

const ModalHeaderImage = () => (
  <svg width="145" height="49" viewBox="0 0 145 49" fill="none">
    <path
      d="M24.84 48.228s-1.205-5.537 1.828-10.927c0 0 1.662-5.24-1.06-10-2.74-4.779 0-10.168 3.197-10.927 3.196-.778 6.977 1.852 7.89 5.537.914 3.686.147 6.315 5.626 9.075 5.48 2.76 5.243 17.242 5.243 17.242H24.84z"
      fill="#fff"
    />
    <path
      d="M47.893 48.599H24.55l-.054-.297c-.055-.24-1.206-5.722 1.844-11.149.128-.444 1.462-5.296-1.04-9.667-1.37-2.39-1.553-5.186-.494-7.686.822-1.945 2.32-3.408 3.909-3.778 3.416-.815 7.38 1.944 8.33 5.815.182.722.292 1.408.401 2.056.457 2.685.785 4.63 5.023 6.778 5.608 2.834 5.443 16.983 5.443 17.576l-.018.352zm-22.76-.741h22.03c-.019-2.241-.31-14.15-5.042-16.538-4.566-2.316-4.968-4.63-5.407-7.316-.11-.648-.219-1.296-.401-2-.859-3.5-4.42-6-7.453-5.278-1.37.333-2.667 1.61-3.416 3.352-.968 2.278-.803 4.834.457 7.019 2.776 4.852 1.17 10.093 1.096 10.297l-.037.074c-2.538 4.52-2.009 9.186-1.826 10.39z"
      fill="#25265E"
    />
    <path
      d="M36.35 48.45c-4.53-10.019-7.892-31.965-7.928-32.187l.73-.112c.037.223 3.38 22.076 7.873 32.003l-.676.297zM12.823 30.893c1.311 0 2.374-1.078 2.374-2.407 0-1.33-1.063-2.408-2.374-2.408-1.312 0-2.375 1.078-2.375 2.408 0 1.33 1.063 2.407 2.375 2.407zM24.824 48.506c-7.544-2.815-11.782-14.408-11.965-14.89l.859-.315c.036.111 4.274 11.686 11.416 14.353l-.31.852z"
      fill="#25265E"
    />
    <path
      d="m34.755 18.745-3.881 10.55.685.258 3.881-10.55-.684-.258zM36.75 23.791l-4.49 11.504.68.273 4.49-11.504-.68-.273z"
      fill="#25265E"
    />
    <path
      d="M9.133 29.134s3.69-1.945 7.343-.778c0 0 1.004 5.445-2.74 5.853-4.238.463-4.603-5.075-4.603-5.075z"
      fill="#3246D3"
    />
    <path
      d="M3.361 28.967c1.19 0 2.156-.978 2.156-2.185s-.965-2.186-2.156-2.186c-1.19 0-2.155.979-2.155 2.186 0 1.207.965 2.185 2.155 2.185zM20.44 44.987s-2.119-3.074-1.826-5.537c.292-2.463 1.826-3.241 2.429-3.075.603.149 1.662.464 1.206 2.779-.457 2.296-1.809 5.833-1.809 5.833zM25.702 39.605l-.433.597 11.262 8.388.432-.598-11.26-8.387zM24.92 43.072l-.426.601 6.647 4.84.426-.602-6.647-4.84z"
      fill="#25265E"
    />
    <path
      d="M56.642 24.745s-1.516-3.241 0-7.39C58.158 13.207 59.218.261 50.122.891c-9.115.611-9.718 12.927-8.805 19.242.913 6.316-.456 10.613-1.973 14.168-1.516 3.538-6.374 6.15-5.626 14.002h30.815s1.516-12.464-7.89-23.558z"
      fill="#fff"
    />
    <path
      d="M64.862 48.673H33.39l-.037-.334c-.548-5.667 1.808-8.741 3.708-11.204.785-1.019 1.516-1.982 1.955-2.982 1.534-3.593 2.849-7.76 1.954-13.964-.712-5-.402-12.298 2.904-16.428 1.571-1.963 3.672-3.055 6.247-3.24 2.576-.167 4.585.685 5.955 2.537 2.904 3.945 2.027 11.464.95 14.427-1.352 3.723-.201 6.686-.037 7.056 4.896 5.778 6.795 11.927 7.526 16.076.803 4.5.438 7.593.42 7.723l-.073.333zm-30.815-.741h30.157c.146-2.019.512-13.094-7.836-22.946l-.036-.038-.019-.037c-.073-.13-1.57-3.426-.018-7.667 1.041-2.833 1.881-10.02-.84-13.723-1.206-1.649-2.996-2.39-5.316-2.241-2.356.166-4.292 1.148-5.717 2.963-3.141 3.945-3.452 11.001-2.74 15.853.932 6.39-.493 10.853-1.99 14.353-.476 1.093-1.224 2.093-2.046 3.149-1.882 2.426-4 5.167-3.599 10.334zM6.046 35.06s-4.731.39-5.498-.759c-.768-1.148 1.296-2.166 4-.425.383.24 1.498 1.185 1.498 1.185zM7.526 37.505s-4.548 1.37-5.535.408c-.986-.963.822-2.39 3.818-1.26.438.148 1.717.852 1.717.852z"
      fill="#25265E"
    />
    <path
      d="M17.572 48.599c-8.622-6.76-14.174-16.668-14.23-16.76l.804-.464c.055.093 5.517 9.853 13.992 16.483l-.566.741zM49.683 48.284c-3.068-16.742-2.904-28.78-2.228-35.929.73-7.778 2.192-11.52 2.247-11.686l.675.278c-.018.037-1.48 3.833-2.191 11.538-.676 7.093-.822 19.038 2.228 35.67l-.73.13z"
      fill="#25265E"
    />
    <path
      d="m42.325 7.777-.55.487 5.58 6.492.551-.487-5.58-6.492zM41.454 12.104l-.549.49 6.233 7.193.549-.489-6.233-7.194z"
      fill="#25265E"
    />
    <path
      d="M0 27.375s3.142-1.852 6.685-.704c0 0 .914 4.945-2.484 5.315C.33 32.412 0 27.375 0 27.375z"
      fill="#3246D3"
    />
    <path
      d="M57.534 25.997 48.21 37.978l.574.459 9.322-11.981-.573-.46zM59.442 28.793 48.782 42.18l.569.465 10.66-13.387-.569-.465zM138.295 26.05a2.206 2.206 0 0 0 .269-3.079 2.135 2.135 0 0 0-3.036-.273 2.206 2.206 0 0 0-.27 3.078 2.136 2.136 0 0 0 3.037.274zM140.082 32.45s3.872-2.798 3.726-4.168c-.146-1.37-2.374-.815-3.342 2.26-.128.462-.384 1.907-.384 1.907zM140.501 35.283s4.366-1.908 4.493-3.278c.128-1.37-2.155-1.297-3.726 1.518-.219.39-.767 1.76-.767 1.76z"
      fill="#25265E"
    />
    <path
      d="m138.785 48.544-.877-.278c3.562-11.186.804-19.724.786-19.817l.858-.296c.128.352 2.886 8.89-.767 20.39z"
      fill="#25265E"
    />
    <path
      d="M139.862 22.634s-3.58.611-5.571 3.815c0 0 2.429 4.39 5.279 2.463 3.215-2.204.292-6.278.292-6.278z"
      fill="#3246D3"
    />
    <path
      d="M121.413 48.303s-.913-4.149 1.37-8.186c0 0 1.261-3.927-.803-7.501-2.046-3.574 0-7.612 2.392-8.186 2.393-.574 5.243 1.39 5.919 4.149.675 2.778.255 6.667 4.347 8.741 4.091 2.074 3.781 10.983 3.781 10.983h-17.006z"
      fill="#fff"
    />
    <path
      d="M138.785 48.673h-17.664l-.054-.297c-.037-.166-.914-4.315 1.388-8.426.109-.39 1.059-3.945-.767-7.168a6.551 6.551 0 0 1-.366-5.852c.64-1.482 1.772-2.593 2.996-2.89 2.594-.629 5.626 1.482 6.356 4.427.147.593.238 1.241.348 1.908.365 2.481.748 5.037 3.817 6.593 4.256 2.167 4 10.945 3.982 11.316l-.036.389zm-17.061-.741h16.348c0-1.649-.201-8.556-3.598-10.279-3.398-1.722-3.836-4.611-4.201-7.149-.092-.648-.183-1.277-.329-1.833-.639-2.574-3.252-4.426-5.48-3.89-1.005.241-1.954 1.186-2.484 2.464-.713 1.685-.585 3.574.329 5.186 2.1 3.685.876 7.63.822 7.796l-.037.075c-1.827 3.26-1.516 6.63-1.37 7.63z"
      fill="#25265E"
    />
    <path
      d="M129.963 48.507c-3.398-7.52-5.919-23.984-5.955-24.15l.73-.112c.019.167 2.539 16.539 5.882 23.965l-.657.297z"
      fill="#25265E"
    />
    <path
      d="m128.78 26.16-2.912 7.912.685.259 2.912-7.913-.685-.259zM130.262 29.965l-3.36 8.611.68.273 3.36-8.611-.68-.273zM122.117 41.775l-.432.597 8.434 6.282.433-.597-8.435-6.282zM121.554 44.375l-.426.602 4.985 3.63.426-.602-4.985-3.63z"
      fill="#25265E"
    />
  </svg>
);

const CofirmationModal = (props) => {
  const renderHeader = () => {
    return (
      <div className="modal__content__header">
        <div
          className="modal__content__header--container flex justify-center"
          style={{
            background: "#F2F5F8",
            height: 60,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div style={{ marginBottom: -10 }}>
            <ModalHeaderImage />
          </div>
        </div>
      </div>
    );
  };

  const renderClose = () => {
    return (
      <div
        className="btn btn--sqr btn--sqr--modal"
        onClick={() => props.setDisplayModal(false)}
      >
        <CloseIcon className="t--sm" />
      </div>
    );
  };
  const renderBody = () => {
    return (
      <div className="flex flex-col item-start" style={{ marginTop: 65 }}>
        <div className="t--xl t--primary mt-1" style={{ fontSize: "24px" }}>
          {props.title}
        </div>

        <div className="modal__content__body">{props.body}</div>
      </div>
    );
  };

  const renderFooter = () => {
    return <div className="modal__content__footer p--0">{props.footer}</div>;
  };

  return (
    <div style={{overflow: 'visible'}} className="modal__background" data-testid="modal-continer">
      <div
        className="modal__container"
        style={{ width: props.width ? props.width : "680px" }}
      >
        <div className="modal__content modal__content--padded">
          {renderClose()}
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default CofirmationModal;
