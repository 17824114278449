export const validationDetails = [
    {
        key: "missing_fields",
        title: "Missing/Invalid Fields",
        description: "Your upload has some missing or invalid fields, which have been highlighted in red. Please correct these fields and re-upload your file.",
    }
]

export const getValidationDetails = (key) => {
    return validationDetails.find((detail) => detail.key === key);
}