import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import moment from "moment";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export const TableRowSubComponent = ({ row, showCards = true }) => {
  const historyLines = row.original.claim_history;

  const renderDifferences = (differences, not_considered) => {
    if (differences.length == 0)
      return (
        <div style={{ marginTop: 10 }}>
          <div className="flex notify__timeline p--8 t--sm" style={{ gap: 10, margin: 4 }}>
            <FiberManualRecordIcon style={{ fontSize: 12 }} />
            <div className="flex">No changes between submitted claims</div>
          </div>
        </div>
      );

    return (
      <div style={{ marginTop: 10 }}>
        {differences.map((diff, i) => {
          return (
            <div key={i} className="flex notify__timeline p--8 t--sm" style={{ gap: 10 }}>
              <FiberManualRecordIcon style={{ fontSize: 12 }} />
              <div className="flex">
                {diff.column_name} was changed from
                <div
                  className={`badge ${not_considered ? "badge--disabled" : ""}`}
                  style={{ margin: "0px 5px" }}
                >
                  {diff.previous_value ? diff.previous_value : <em>empty</em>}
                </div>{" "}
                to
                <div
                  className={`badge ${not_considered ? "badge--disabled" : ""}`}
                  style={{ margin: "0px 5px" }}
                >
                  {diff.current_value ? diff.current_value : <em>empty</em>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderHistoryLine = (history, lastItem, conforming) => {
    return (
      <TimelineItem key={history.created_at}>
        <TimelineSeparator>
          <TimelineDot style={{ background: "#3246D3" }} />
          {lastItem ? "" : <TimelineConnector style={{ background: "#3246D3" }} />}
        </TimelineSeparator>
        <TimelineContent>
          <div
            className="flex flex-col item-start"
            style={history.not_considered ? { color: "#AAAAAA" } : {}}
          >
            <div className="flex item-start" style={{ gap: 20 }}>
              <div className="t--sm t--bold t--left" style={{ width: `${100 / 4}%` }}>
                {history.date_submitted}
              </div>
              <div className="flex flex-col item-start t--left" style={{ width: `${100 / 4}%` }}>
                <div className="t--sm">{history.submission_name}</div>
              </div>
              <div className="flex flex-col item-start t--left" style={{ width: `${100 / 4}%` }}>
                <div className="t--sm">{history.submitted_by}</div>
              </div>
              {/* <div className="flex flex-col item-start t--left" style={{ width: `${100 / 4}%` }}>
                {history.patient_validation_codes.map((reason) => (
                  <div className={`badge ${history.not_considered ? "badge--disabled" : ""} mb-1`} key={reason.value}>
                    <div className="badge__indicator badge__indicator--red" />
                    {reason.label}
                  </div>
                ))}
              </div> */}
            </div>
            {!history.first && renderDifferences(history.differences, history.not_considered)}
            {renderMaxResubmissionMessage(history.final, conforming)}
          </div>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const renderMaxResubmissionMessage = (showWarning, conforming) => {
    if (showWarning) {
      return (
        <div style={{ marginTop: 10, color: "#25265e" }}>
          <div className="flex notify__timeline p--8 t--sm" style={{ gap: 10, margin: 4 }}>
            <div className="flex t--bold">
              <div className="indicator indicator--red mt-1" />
              {conforming
                ? "A conforming claim has already been submitted. Resubmissions passed this point are treated as duplicates and will not be considered."
                : "Maximum resubmissions reached. No claims considered after this point."}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p--8 tile table__subrow">
      <>
        <div className="p--8 tile mb-1">
          <div className="flex justify-between" style={{ gap: 20 }}>
            <div className="card card__item card__item--light w--100 p--8">
              <h3 className="t--md t--bold t--upcase flex mb-2">
                <div className="table__subrow  table__subrow__icon">
                  <ApartmentIcon color="primary" fontSize="small" />
                </div>
                Additional Claim Information
              </h3>
              <div className="grid" style={{ gridTemplateColumns: "repeat(3, 1fr)", gap: 10 }}>
                <div className="card p--8">
                  <div className="flex">
                    <div className="t--sm p--5 t--gray">Plan ID</div>
                    <div className="t--sm p--5 t--bold">{row.original.plan_id_code}</div>
                  </div>
                  <div className="flex">
                    <div className="t--sm p--5 t--gray">Plan Name</div>
                    <div className="t--sm p--5 t--bold">{row.original.plan_name}</div>
                  </div>
                  <div className="flex">
                    <div className="t--sm p--5 t--gray">Claim Number</div>
                    <div className="t--sm p--5 t--bold">
                      {" "}
                      {row.original.claim_number.substr(0, 15)}...{" "}
                    </div>
                  </div>
                </div>
                <div className="card p--8">
                  <div className="flex">
                    <div className="t--sm p--5 t--gray">Service Provider ID </div>
                    <div className="t--sm p--5 t--bold">{row.original.service_provider_id}</div>
                  </div>
                  <div className="flex item-start t--left">
                    <div className="t--sm p--5 t--gray">Address </div>
                    <div className="t--sm p--5 t--bold">{row.original.address}</div>
                  </div>
                  <div className="flex">
                    <div className="t--sm p--5 t--gray">HCPCS Code</div>
                    <div className="t--sm p--5 t--bold">{row.original.hcpcs_code}</div>
                  </div>
                </div>
                <div className="card p--8">
                  <div className="flex item-start">
                    <div className="t--sm p--5 t--gray">Prescriber </div>

                    <div className="flex flex-col t--left item-start">
                      <div className="t--sm p--5 t--bold">
                        {row.original.prescriber?.id} <br />
                        {row.original.prescriber.name} <br />
                        {row.original.prescriber.address}
                      </div>
                    </div>
                  </div>
                  <div className="flex t--left">
                    <div className="t--sm  p--5 t--gray">Prescription Type</div>
                    <div className="t--sm  p--5 t--bold">{row.original.prescription_type}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card card__item card__item--light w--100 p--8">
                <h3 className="t--md t--bold t--upcase flex mb-2">
                  <div className="table__subrow  table__subrow__icon">
                    <ApartmentIcon color="primary" fontSize="small" />
                  </div>
                  healthcare encounter information
                </h3>

                <div
                  className="grid"
                  style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: 10 }}
                >
                  <div className="card p--8">
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">
                        Billing Provider ID
                      </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.billing_service_provider_id}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">Date of Service </div>
                      <div className="t--sm p--5 t--bold ">
                        {moment(row.original.submission_date).format(
                          "MM/DD/YYYY"
                        )}
                      </div>
                    </div>

                    <div className="flex">
                      <div className="t--sm p--5 t--gray">HCPCS Code </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.hcpcs_code}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm p--5 t--gray">Diagnosis Code </div>
                      <div className="t--sm p--5 t--bold ">
                        {row.original.diagnosis_code}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="t--sm  p--5 t--gray">
                        Place of Service Code
                      </div>
                      <div className="t--sm  p--5 t--bold ">
                        {row.original.place_of_service_code}
                      </div>
                    </div>
                  </div>
                  <div className="card p--8">
                    <div className="flex item-start t--left">
                      <div className="t--sm p--5 t--gray">
                        Rendering Physician{" "}
                      </div>
                      <div className="flex flex-col item-start t--left">
                        <div className="t--sm p--5 t--bold ">
                          {row.original.rendering_physician.id} <br/>
                          {row.original.rendering_physician.name} <br/>
                          {row.original.rendering_physician.address}
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="t--sm p--5 t--gray t--left">
                        Description of HCPCS Code based on the other information
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <a className="t--sm">View more</a>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </>

      <div className="p--8 tile">
        <div className="flex justify-between mt-1">
          <div className="flex item-start">
            <div className="table__subrow  table__subrow__icon">
              <HistoryIcon color="primary" fontSize="small" />
            </div>
            <div className="flex flex-col w--auto item-start ml-1">
              <span className="t--md t--bold t--upcase">Claim History</span>
              <span className="t--sm t--blue">{historyLines.length} submissions</span>
            </div>
          </div>
          {/*<div className="btn btn--light">How to Repair Claims</div>*/}
        </div>

        <ThemeProvider theme={Theme}>
          <div
            className="flex justify-between p--8 notify__info notify__info__heading"
            style={{ gap: 20, padding: "8px 20px 8px 40px" }}
          >
            <div
              className="t--sm t--upcase t--bold flex justify-start"
              style={{ width: `${100 / 4}%` }}
            >
              submitted on
            </div>
            <div
              className="t--sm t--upcase t--bold flex justify-start"
              style={{ width: `${100 / 4}%` }}
            >
              submission name
            </div>
            <div
              className="t--sm t--upcase t--bold flex justify-start"
              style={{ width: `${100 / 4}%` }}
            >
              submitted by
            </div>
            <div
              className="t--sm t--upcase t--bold flex justify-start"
              style={{ width: `${100 / 4}%` }}
            >
              {/* Issues */}
            </div>
          </div>

          <Timeline class="mb-0">
            {historyLines.map((history) => {
              // not_considered field needs a refactor, the logic is hard to parse here.
              return renderHistoryLine(
                history,
                history.first,
                row.original.status.toLowerCase() == "conforming",
              );
            })}
          </Timeline>
        </ThemeProvider>
      </div>
    </div>
  );
};
