import React from "react";

const ToggleView = ({ viewOriginal, setViewOriginal }) => {
  return (
    <div className="toggle__container toggle__container--ml" data-testid="toggle-button">
      <input
        type="radio"
        className="toggle__input"
        id="radio-1"
        onChange={() => setViewOriginal(true)}
        checked={viewOriginal}
      />
      <label className="toggle__label" htmlFor="radio-1">
        Original File
      </label>
      <input
        type="radio"
        className="toggle__input"
        id="radio-2"
        onChange={() => setViewOriginal(false)}
        checked={!viewOriginal}
      />
      <label className="toggle__label" htmlFor="radio-2">
        Submission
      </label>

      <span className="toggle__glider"></span>
    </div>
  );
};

export default ToggleView;
