import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import RetailDesignationForm from "./RetailDesignationsForm";
import LeqembiDesignationForm from "./LeqembiDesignationsForm";
import LenvimaDesignationForm from "./LenvimaDesignationsForm";

import Logo from "~/images/Eisai.png";

const EisaiDesignations = (props) => {
  const manufacturer_name = "eisai";
  const vanity_manufacturer_name = "Eisai";
  const [retailHasSelectedSpecialtyPharmacy, setRetailHasSelectedSpecialtyPharmacy] = useState(false);
  const [retailSelectedSpecialtyPharmacy, setRetailSelectedSpecialtyPharmacy] = useState();
  const [manufacturerFormDetails, setManufacturerFormDetails] = useState({
    eisai: {},
  });

  useEffect(() => {
    props.setCompletedDesignations(isComplete());
  }, [manufacturerFormDetails]);

  const isComplete = () => {
    var completed = true;

    var openForms = _.map(manufacturerFormDetails, (manufacturer) => {
      return _.filter(manufacturer, (form) => {
        return form.open;
      });
    });

    // remove empty arrays and flatten
    var filteredForms = openForms.filter((e) => e.length).flat();

    if (filteredForms.length) {
      completed = _.every(filteredForms, ["completed", true]);
    }

    return completed;
  };

  const renderRetail = () => {
    return (
      <RetailDesignationForm
        entities={props.entities}
        kind={"retail"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
      />
    );
  };

  const renderLeqembi = () => {
    return (
      <LeqembiDesignationForm
        entities={props.entities}
        kind={"leqembi"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
      />
    );
  };

  const renderLenvima = () => {
    return (
      <LenvimaDesignationForm
        entities={props.entities}
        kind={"lenvima"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
      />
    );
  };

  return (
    <div>
      <div className="designation_form__section__header">
        <div className="designation_form__section__header__logo">
          <img src={Logo} />
        </div>
        <div className="designation_form__section__header__title">{vanity_manufacturer_name}</div>
      </div>
      {renderRetail()}
      {renderLeqembi()}
      {renderLenvima()}
    </div>
  );
};

export default EisaiDesignations;
