import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Gsk from "~/images/GSK.png";
import Exelixis from "~/images/Exelixis.png";
import EMDSerono from "~/images/EmdSerono.png";
import Pfizer from "~/images/Pfizer.png";
import Biogen from "~/images/Biogen.png";
import NovoNordisk from "~/images/NovoNordisk.png";
import Novartis from "~/images/Novartis.png";
import Sandoz from "~/images/Sandoz.png";
import Lilly from "~/images/Lilly.png";
import Takeda from "~/images/Takeda.png";

const SelectManufacturers = (props) => {
  const renderCheck = (manufacturer) => {
    if (props.selectedManufacturer == manufacturer) {
      return (
        <div className={"selector__option__radio selector__option__radio--selected"}>
          <i className="solid solid-budicon-check-ui"></i>
        </div>
      );
    } else {
      return <div className={"selector__option__radio"}></div>;
    }
  };

  const handleSelect = (manufacturer) => {
    if (manufacturer != props.selectedManufacturer) {
      props.setSelectedManufacturer(manufacturer);
    } else {
      props.setSelectedManufacturer(null);
    }
  };

  const renderOptions = () => {
    return (
      <div className="selector__container">
        <div
          className={
            props.selectedManufacturer == "biogen" ? "selector__option selector__option--selected" : "selector__option"
          }
          onClick={() => handleSelect("biogen")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Biogen} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Biogen</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("biogen")}
        </div>
        <div
          className={
            props.selectedManufacturer == "eli_lilly"
              ? "selector__option selector__option--selected"
              : "selector__option"
          }
          onClick={() => handleSelect("eli_lilly")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Lilly} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Eli Lilly</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("eli_lilly")}
        </div>
        <div
          className={
            props.selectedManufacturer == "exelixis"
              ? "selector__option selector__option--selected"
              : "selector__option"
          }
          onClick={() => handleSelect("exelixis")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Exelixis} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Exelixis</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("exelixis")}
        </div>
        <div
          className={
            props.selectedManufacturer == "emd_serono"
              ? "selector__option selector__option--selected"
              : "selector__option"
          }
          onClick={() => handleSelect("emd_serono")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={EMDSerono} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">EMD Serono</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("emd_serono")}
        </div>
        <div
          className={
            props.selectedManufacturer == "glaxosmithkline"
              ? "selector__option selector__option--selected"
              : "selector__option"
          }
          onClick={() => handleSelect("glaxosmithkline")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Gsk} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">GlaxoSmithKline</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("glaxosmithkline")}
        </div>
        <div
          className={
            props.selectedManufacturer == "novartis"
              ? "selector__option selector__option--selected"
              : "selector__option"
          }
          onClick={() => handleSelect("novartis")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Novartis} style={{ height: 30 }} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Novartis</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("novartis")}
        </div>
        <div
          className={
            props.selectedManufacturer == "novo_nordisk"
              ? "selector__option selector__option--selected"
              : "selector__option"
          }
          onClick={() => handleSelect("novo_nordisk")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={NovoNordisk} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Novo Nordisk</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("novo_nordisk")}
        </div>
        <div
          className={
            props.selectedManufacturer == "sandoz" ? "selector__option selector__option--selected" : "selector__option"
          }
          onClick={() => handleSelect("sandoz")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Sandoz} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Sandoz</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("sandoz")}
        </div>
        <div
          className={
            props.selectedManufacturer == "pfizer" ? "selector__option selector__option--selected" : "selector__option"
          }
          onClick={() => handleSelect("pfizer")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Pfizer} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Pfizer</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("pfizer")}
        </div>
        <div
          className={
            props.selectedManufacturer == "takeda" ? "selector__option selector__option--selected" : "selector__option"
          }
          onClick={() => handleSelect("takeda")}
        >
          <div className="selector__option__group">
            <div className="selector__option__logo">
              <img src={Takeda} />
            </div>
            <div className="selector__option__container">
              <div className="selector__option__container__title">Takeda</div>
              <div className="selector__option__container__subtitle">Wholly Owned Exemption</div>
            </div>
          </div>
          {renderCheck("takeda")}
        </div>
      </div>
    );
  };

  return renderOptions();
};

export default SelectManufacturers;
