export const withdrawClaimReq = async (claim_id) => {
  const url = `/api/claims/${claim_id}/withdraw`;

  const requestOptions = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    },
    body: JSON.stringify({ kind: "encounter" }),
  };

  const response = await fetch(url, requestOptions).then(handleResponse);

  return response;
};

export const restoreClaimReq = async (claim_id) => {
  const url = `/api/claims/${claim_id}/restore`;

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    },
    body: JSON.stringify({ kind: "encounter" }),
  };

  const response = await fetch(url, requestOptions).then(handleResponse);

  return response;
};

export const fetchHistory = async (url) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    },
  };

  const response = await fetch(url, requestOptions).then(handleResponse);

  return response;
};



function handleResponse(response) {
  return response.json().then((resObj) => {
    if (!response.ok) {
      const error = (resObj && resObj.message) || response.statusText;
      return Promise.reject(error);
    }

    return resObj;
  });
}
