
import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import inviteImage from "~/images/invite_or_find_illustration.png";
import { buttonClass } from "../utils/Buttons";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ModalUserInvite = (props) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [role, setRole] = useState("User");
  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const isValidEmail = () => {
    // emailRegex is adapted from URI::MailTo::EMAIL_REGEXP in Ruby
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return emailRegex.test(email);
  }

  const invalidEmailCharacters = () => {
    const invalidCharacterRegExp = /[()\[\];:",<>\\]/g;
    const matchSet = new Set(email.match(invalidCharacterRegExp));
    return Array.from(matchSet);
  }

  const isDisabled = () => {
    return submitting;
  }

  const handleSubmit = (event) => {
    if (isValidEmail()) {
      setHasError(false);
      setErrorText("");
      // Use submitting state variable to prevent double submits
      setSubmitting(true);
      return;
    }

    const invalidCharacters = invalidEmailCharacters();
    if (invalidCharacters.length > 0) {
      event.preventDefault();
      setHasError(true);
      setErrorText(`Email contains invalid characters - Please remove: ${invalidCharacters.join(', ')}`);
    } else {
      event.preventDefault();
      setHasError(true);
      setErrorText("Email is not valid - Please verify your input");
    }
  };

  const renderRoleOptions = () => {
    if (props.superAdmin) {
      return (
        <CustomTextField
          select
          label="Role"
          margin="normal"
          variant="outlined"
          id="role"
          name="user[role]"
          fullWidth
          required
          SelectProps={{
            native: true,
          }}
          value={role}
          onChange={(event) => {
            setRole(event.target.value);
          }}
        >
          <option key="SuperAdmin" value="super_admin">
            Super Admin
          </option>
        </CustomTextField>
      );
    } else {
      return (
        <CustomTextField
          select
          label="Role"
          margin="normal"
          variant="outlined"
          id="role"
          name="user[role]"
          fullWidth
          required
          SelectProps={{
            native: true,
          }}
          value={role}
          onChange={(event) => {
            setRole(event.target.value);
          }}
        >
          <option key="User" value="user">
            User
          </option>
          <option key="Admin" value="admin">
            Admin
          </option>
        </CustomTextField>
      );
    }
  };

  const renderModal = () => {
    if (displayModal) {
      return (
        <div className="modal__background">
          <div
            className="modal__body modal__body--no-side-padding"
            style={{ paddingTop: 30, paddingBottom: 30 }}
          >
            <div className="modal__body__header-container">
              <div className="modal__body__header-text-container">
                <div className="modal__body__title">
                  {props.superAdmin
                    ? "Invite a Super Admin"
                    : "Invite a team member"}
                </div>
                <div
                  className="modal__body__subtitle"
                  style={{ marginBottom: 30 }}
                >
                  Enter details below to invite a team member to your covered
                  entity account.
                </div>
              </div>
              <div className="modal__body__header-image">
                <img src={inviteImage} width={150} />
              </div>
            </div>
            <form
              method="POST"
              id="inviteUserForm"
              action={`/organization/${props.organization}/users`}
              onSubmit={handleSubmit}
            >
              <div
                className="modal__body__form_container"
                style={{ paddingTop: 34, paddingBottom: 50 }}
              >
                <div className="register__form__dual-row register__form__dual-row--pad-right">
                  <input
                    type="hidden"
                    name="user[organization_id]"
                    value={props.organization}
                  />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={props.authToken}
                  />
                  <CustomTextField
                    label="First Name"
                    margin="normal"
                    variant="outlined"
                    id="firstName"
                    name="user[first_name]"
                    autoFocus
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-left">
                  <CustomTextField
                    label="Last Name"
                    margin="normal"
                    variant="outlined"
                    id="lastName"
                    name="user[last_name]"
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-right">
                  <CustomTextField
                    label="Work Email Address"
                    margin="normal"
                    variant="outlined"
                    id="email"
                    name="user[email]"
                    error={hasError}
                    helperText={hasError ? errorText : null}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    fullWidth
                    required
                  />
                </div>
                <div className="register__form__dual-row register__form__dual-row--pad-left">
                  {renderRoleOptions()}
                </div>
              </div>
              <div className="modal__body__footer">
                <div
                  className="btn btn--white btn--outline"
                  onClick={() => {
                    setDisplayModal(false);
                  }}
                >
                  Cancel Invite
                </div>
                <button
                  type="submit"
                  className={buttonClass(isDisabled())}
                  disabled={isDisabled()}
                  style={{ flexGrow: 1, marginLeft: 30 }}
                >
                  Send Invite
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className="btn"
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        Invite New Users
      </div>
      {renderModal()}
    </div>
  );
};

export default ModalUserInvite;
