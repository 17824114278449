import _ from "lodash";

export const TableData = (attachment, viewOriginal, originalViewId) => {
  const getHeaders = (attachment) => {
    if (attachment?.length <= 0) {
      return [];
    }

    if (attachment?.length > 0) {
      if (viewOriginal && originalViewId) {
        const selectedAttachment = attachment.find(
          (attachment) => attachment.id === originalViewId,
        );

        return selectedAttachment.originalColumnNames;
      }

      return attachment[0].columnNames;
    }
  };

  const getData = (attachment) => {
    if (attachment?.length <= 0) {
      return [];
    }

    if (viewOriginal && !originalViewId) {
      return [];
    }

    if (viewOriginal) {
      const selectedAttachment = attachment.find((attachment) => attachment.id === originalViewId);
      return selectedAttachment.originalData;
    }

    return attachment?.flatMap((item) => {
      return item.formattedData;
    });
  };

  const getColumnMappingWarnings = (attachment) => {
    if (attachment?.length <= 0) {
      return {};
    }

    return attachment?.flatMap((item) => item.column_mapping_warnings);
  };

  const getMapping = (attachment) => {
    if (attachment?.length <= 0) {
      return {};
    }

    return attachment?.length > 0 && attachment[0].mapping;
  };

  return {
    headers: getHeaders(attachment),
    data: getData(attachment),
    columnMappingWarnings: getColumnMappingWarnings(attachment),
    mapping: getMapping(attachment),
  };
};
