import {
  isValidDateOfService,
  isValidDatePrescribed,
  isValidRXNumber,
  isValidNDC,
  isValidQuantity,
  isValidWholesalerInvoiceNumber,
  isValidPrescriberIDQualifier,
  isValidPrescriberID,
  isValidServiceProviderIDQualifier,
  isValidServiceProviderID,
  isValidContractedEntityID,
} from "./data_validations";
import { isRequiredField } from "../schemas/pharmacy_claim";

const isValidPharmacyClaimField = (value, column, row, mapping) => {
  if (["claim_conforms_flag", "formatted_rx_number"].includes(column)) {
    return true;
  }

  if (isRequiredField(column) && !value) {
    return false;
  }

  switch (column) {
    case "date_of_service":
      return isValidDateOfService(value, row[mapping["date_prescribed"]]);
    case "date_prescribed":
      return isValidDatePrescribed(value, row[mapping["date_of_service"]]);
    case "rx_number":
      return isValidRXNumber(value);
    case "ndc":
      return true; // skip validations for column we filter by
    case "quantity":
      return isValidQuantity(value);
    case "wholesaler_invoice_number":
      return isValidWholesalerInvoiceNumber(value);
    case "prescriber_id_qualifier":
      return isValidPrescriberIDQualifier(value);
    case "prescriber_id":
      return isValidPrescriberID(row[mapping["prescriber_id_qualifier"]], value);
    case "service_provider_id_qualifier":
      return isValidServiceProviderIDQualifier(value);
    case "service_provider_id":
      return isValidServiceProviderID(row[mapping["service_provider_id_qualifier"]], value);
    case "contracted_entity_id":
      return isValidContractedEntityID(value);
  }
};

export default isValidPharmacyClaimField;
