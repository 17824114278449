import React, { useState } from "react";
import WithdrawModal from "./library/withdraw_modal";

const WithdrawClaimModal = (props) => {
  const modalBody = () => {
    return (
      <>
        <div
          className="grid w--100 p--16 notify__info"
          style={{
            margin: 0,
            marginTop: 7,
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 10,
          }}
        >
          <div className="card p--16 w--100 flex flex-col item-start">
            <p className="t--sm t--gray">Claim ID</p>
            <p className="t--md t--500"> {props.row.original.short_id} </p>
            <p
              className="badge"
              style={{ margin: 0, marginTop: 10, padding: 6 }}
            >
              <div className={`badge__indicator badge__indicator--red`} />
              <span
                className="t--small"
                style={{ fontSize: 9, textTransform: "capitalize" }}
              >
                {props.row.original.quality}
              </span>
            </p>
          </div>
          <div className="card p--16 w--100 flex flex-col item-start">
            <p className="t--sm t--gray">Serv Prov ID</p>
            <p className="t--md t--500">
              {props.row.original.pharmacy.id}
            </p>
          </div>
          <div className="card p--16 w--100 flex flex-col item-start">
            <p className="t--sm t--gray">340B ID</p>
            <p className="t--md t--500"> {props.row.original.id_340b} </p>
          </div>
          <div className="card p--16 w--100 flex flex-col item-start">
            <p className="t--sm t--gray">NDC</p>
            <p className="t--md t--500"> {props.row.original.product_name} </p>
            <p className="t--sm t--gray">{props.row.original.ndc}</p>
          </div>
        </div>
      </>
    );
  };

  const modalFooter = () => {
    return (
      <div className="flex justify-end mt--16" style={{ gap: 20 }}>
        <button
          className="btn btn--small btn--small--light-outline"
          onClick={props.secondaryAction}
        >
          Cancel
        </button>
        <button className="btn btn--small" onClick={props.primaryAction}>
          {props.isDeleting ? (
            <div className="loading-indicator loading-indicator--no-margin"></div>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    );
  };

  if (props.displayModal) {
    return (
      <WithdrawModal
        title={props.title}
        subtitle={props.subtitle}
        footer={modalFooter()}
        body={modalBody()}
        showCloseIcon={true}
        primaryAction={props.primaryAction}
        secondaryAction={props.secondaryAction}
      />
    );
  }

  return null;
};

export default WithdrawClaimModal;
