import React from "react";

import tutorialPromptImg from "~/images/review_submission_illustration.png";

const ReviewSubmissionPrompt = ({ handleClose, goToMapping }) => {
  const handleGoToMapping = () => {
    handleClose();
    goToMapping();
  };

  return (
    <div className="modal__background modal__background--light">
      <div className="tutorial_modal__container">
        <div className="tutorial_modal__image-container tutorial_modal__image-container--no-padding-bottom">
          <img src={tutorialPromptImg} />
        </div>
        <div className="tutorial_modal__body">
          <h2 className="tutorial_modal__header">Review Your Submission</h2>
          <div className="tutorial_modal__description t--md t--500">
            <div className="tutorial_modal__description t--md t--500">
              Now that you’ve set up a column mapping, you must review your submission before you
              can submit.
            </div>
          </div>
          <div className="tutorial_modal__button-container tutorial_modal__button-container--flex-end">
            <div className="tutorial_modal__button-container__navigation">
              <button className="btn btn--white btn--light-border" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn" onClick={handleGoToMapping}>
                Go to Submission
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSubmissionPrompt;
