import React, { useState } from "react";

import MoreInfoIcon from "../../utils/MoreInfoIcon";

const TutorialModal = ({ handleClose, slides }) => {
  const [slide, setSlide] = useState(0);
  const currentSlide = slides[slide];

  const getImg = () => {
    return <img src={currentSlide.img} />;
  };

  const insertLineBreaks = (str) => {
    const sections = str.split("\n");
    const result = sections.reduce((arr, section, i) => {
      if (i !== sections.length - 1) {
        arr.push(section);
        arr.push(<br />);
        arr.push(<br />);

        return arr;
      }
      arr.push(section);
      return arr;
    }, []);

    return result;
  };

  const handleNextSlide = () => {
    if (slide === slides.length - 1) {
      return handleClose();
    }
    setSlide((slide) => slide + 1);
  };

  const handlePrevSlide = () => {
    if (slide === 0) {
      return;
    }

    setSlide((slide) => slide - 1);
  };

  const renderMoreInfoIcons = () => {
    if (currentSlide.tooltips && currentSlide.tooltips.length) {
      return currentSlide.tooltips.map((tooltip, i) => {
        return (
          <MoreInfoIcon
            key={i}
            anchorPosition={tooltip.anchorPosition}
            top={tooltip.top}
            left={tooltip.left}
            tooltipHeader={tooltip.header}
            tooltipContent={tooltip.content}
          />
        );
      });
    }
  };

  const renderImage = () => {
    return (
      <div className="tutorial_modal__image-container">
        {getImg()}
        {renderMoreInfoIcons()}
      </div>
    );
  };

  const renderBackButton = () => {
    if (slide > 0) {
      return (
        <button
          className="btn btn--white btn--light-border"
          disabled={slide === 0}
          onClick={handlePrevSlide}
        >
          Go Back
        </button>
      );
    }
  };

  const renderButtons = () => {
    return (
      <div className="tutorial_modal__button-container">
        <button className="btn btn--no-bg btn--no-border btn--blue-text" onClick={handleClose}>
          Skip Tutorial
        </button>
        <div className="tutorial_modal__button-container__navigation">
          {renderBackButton()}
          <button className="btn" onClick={handleNextSlide}>
            Next
          </button>
        </div>
      </div>
    );
  };

  const renderSlideIndicator = () => {
    return (
      <div className="tutorial_modal__slide-indicator">
        {slides.map((indicator, i) => {
          const getClassname = () => {
            if (i === slide) {
              return "tutorial_modal__slide-indicator__item--active";
            }
            return "";
          };

          return (
            <div
              key={i}
              className={`tutorial_modal__slide-indicator__item ${getClassname()}`}
              onClick={() => setSlide(i)}
            ></div>
          );
        })}
      </div>
    );
  };

  const renderDescription = () => {
    if (currentSlide.desc) {
      return (
        <div className="tutorial_modal__description t--md t--500">
          {insertLineBreaks(currentSlide.desc).map((el) => el)}
        </div>
      );
    }
  };

  const renderBody = () => {
    return (
      <div className="tutorial_modal__body">
        {renderSlideIndicator()}

        <h2 className="tutorial_modal__header">{currentSlide.title}</h2>
        {renderDescription()}
        {renderButtons()}
      </div>
    );
  };

  return (
    <div className="modal__background modal__background--light">
      <div className="tutorial_modal__container">
        {renderImage()}
        {renderBody()}
      </div>
    </div>
  );
};

export default TutorialModal;
