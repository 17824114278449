import React, { useState, useEffect, useRef } from "react";
import _, { filter } from "lodash";
import { DragDropContext } from "react-beautiful-dnd";

import { SaveAlt } from "@material-ui/icons";

import useVault from "../../hooks/useVault";
import { fetchWrapper } from "../../http/http";
import FileProcessor from "../../processors/file_processor";

import ColumnMappingNotice from "./column_mapping_notice";
import DataOutput from "./data_output";
import FieldMappings from "./field_mappings";
import Notice from "../../../Notice";
import TabBar from "../../../TabBar";
import DataInput from "./data_input";
import Tutorials from "./tutorials/tutorials_index";
import HelpDropdown from "./help_dropdown";

const tabs = [
  {
    label: "Submission",
    id: "submission",
  },
  {
    label: "Original File",
    id: "original_view",
  },
  {
    label: "Column Mappings",
    id: "mappings",
  },
];

const DataSubmission = (props) => {
  const [submissions, setSubmissions] = useState([]);
  // Should files be useState or useRef to prevent rerenders?
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef();
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const [scrollTo, setScrollTo] = useState(() => {});
  const [displayTabBarTags, setDisplayTabBarTags] = useState(false);
  const [displaySubmissionTutorialIntro, setDisplaySubmissionTutorialIntro] = useState(false);

  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [activeTab, setActiveTab] = useState("submission");
  const [fieldMappingGroups, setFieldMappingGroups] = useState(props.fieldMappings);
  const [editState, setEditState] = useState({
    id: null,
    position: null,
    name: "",
    mappings: {},
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const usedFields = Object.values(editState.mappings);

  const salt = useVault(props.organization, setNotice);

  const filterList = JSON.parse(props.filterList);

  useEffect(() => {
    if (fieldMappingGroups.length === 0) {
      setDisplayTabBarTags(true);
    } else {
      setDisplayTabBarTags(false);
    }

    /*
    Reprocesses all files when any FieldMapping is created/updated/deleted.
    Ideally we would like to only reprocess affected files.
    */
    async function reprocessFiles() {
      const newSubmissions = await Promise.all(
        submissions.map(async (submission) => {
          const file = files.find((file) => file.id === submission.file_id);

          const result = await FileProcessor({
            id: submission.id,
            file: file,
            kind: props.type,
            filterList: filterList,
            filterKey: props.filterKey,
            columnMappings: fieldMappingGroups,
            salt: salt,
          });

          return result;
        }),
      );

      setSubmissions(newSubmissions);
    }

    reprocessFiles();
  }, [fieldMappingGroups]);

  useEffect(() => {
    const setDefaultSelectedAttachment = () => {
      if (submissions.length && !selectedAttachmentId) {
        setSelectedAttachmentId(submissions[0].id);
      }
    };

    setDefaultSelectedAttachment();
  }, []);

  const getNextId = (collection) => {
    const lastItem = _.maxBy(collection, "id");
    if (lastItem) {
      return lastItem.id + 1;
    }

    return 1;
  };

  // Reads file, adds object to state, and updates position of state.selected file to the new file
  const handleFile = async (file) => {
    const fileObj = { id: getNextId(files), data: file };

    // should we hold files in useState or useRef to prevent unnecessary rerenders?
    setFiles((files) => [...files, fileObj]);
    const newSubmissionId = getNextId(submissions);

    const result = await FileProcessor({
      id: newSubmissionId,
      file: fileObj,
      kind: props.type,
      filterList: filterList,
      filterKey: props.filterKey,
      columnMappings: fieldMappingGroups,
      salt: salt,
    });

    setSubmissions((prev) => [...prev, result]);
    setSelectedAttachmentId(result.id);
  };

  const getSelectedMapping = (id) => {
    return _.find(fieldMappingGroups, ["id", id]);
  };

  // Reads file, adds object to state, and updates position of state.selected file to the new file
  const handleSelectMapping = async (submissionId, fileId, mappingId) => {
    const file = files.find((file) => file.id === fileId);

    if (!file) {
      return;
    }

    const result = await FileProcessor({
      id: submissionId,
      file: file,
      kind: props.type,
      filterList: filterList,
      filterKey: props.filterKey,
      columnMappings: fieldMappingGroups,
      salt: salt,
      selectedMapping: getSelectedMapping(mappingId),
    });

    const newSubmissions = submissions.map((submission) => {
      if (submission.id === submissionId) {
        return result;
      }

      return submission;
    });

    setSubmissions(newSubmissions);
  };

  const createFieldMapping = async (mapping) => {
    setIsSaving(true);
    try {
      await fetchWrapper
        .createFieldMapping(props.organization, props.type, mapping)
        .then((res) => setFieldMappingGroups(res.mappings))
        .catch((error) =>
          setNotice({
            kind: "error",
            open: true,
            message: "Oh no - it looks like something went wrong",
          }),
        );
    } catch (error) {
      console.error({ error });
    } finally {
      setIsSaving(false);
    }
  };

  const updateFieldMapping = async (mapping) => {
    setIsSaving(true);
    try {
      await fetchWrapper
        .updateFieldMapping(props.organization, props.type, mapping)
        .then((res) => setFieldMappingGroups(res.mappings))
        .catch((error) =>
          setNotice({
            kind: "error",
            open: true,
            message: "Oh no - it looks like something went wrong",
          }),
        );
    } catch (error) {
      console.error({ error });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteFieldMappingGroup = async (id) => {
    setIsDeleting(true);

    try {
      await fetchWrapper
        .deleteFieldMappings(props.organization, props.type, id)
        .then((res) => setFieldMappingGroups(res.mappings))
        .catch((error) =>
          setNotice({
            kind: "error",
            open: true,
            message: "Oh no - it looks like something went wrong",
          }),
        );
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeleting(false);
    }
  };

  const renderFileNotice = () => {
    if (submissions.length === 0) {
      return (
        <div className="notify__banner" style={{ marginBottom: 20 }}>
          <div className="notify__banner__icon">
            <i className="solid solid-budicon-notification"> </i>
          </div>
          <div className="notify__banner__notice__title">
            <strong>Attach a file - </strong>
          </div>
          <div className="notify__banner__notice">
            In order to update or create column mappings you must attach a data file.
          </div>
        </div>
      );
    }
  };

  const submissionView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        <DataOutput
          viewOriginal={false}
          salt={salt}
          organizationID={props.organization}
          data={submissions}
          type={props.type}
          mappings={fieldMappingGroups}
          attachedCount={1}
          displayTabBarTags={setTabBarTag}
          setScrollTo={setScrollTo}
          headers={props.headers}
        />
      </div>
    );
  };

  const originalView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        <DataOutput
          viewOriginal={true}
          salt={salt}
          organizationID={props.organization}
          data={submissions}
          type={props.type}
          mappings={fieldMappingGroups}
          attachedCount={1}
          displayTabBarTags={setTabBarTag}
          setScrollTo={setScrollTo}
          headers={props.headers}
          originalViewId={selectedAttachmentId}
          setOriginalViewId={setSelectedAttachmentId}
        />
      </div>
    );
  };

  const mappingView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        {renderFileNotice()}
        <FieldMappings
          organization={props.organization}
          createFieldMapping={createFieldMapping}
          deleteFieldMappingGroup={deleteFieldMappingGroup}
          updateFieldMapping={updateFieldMapping}
          mappings={fieldMappingGroups}
          setMappings={setFieldMappingGroups}
          columnDetails={props.columnDetails}
          editState={editState}
          setEditState={setEditState}
          isDeleting={isDeleting}
          isSaving={isSaving}
          notRequired={props.notRequired}
        />
      </div>
    );
  };

  const renderTabViews = () => {
    switch (activeTab) {
      case "submission":
        return submissionView(activeTab);
      case "original_view":
        return originalView(activeTab);
      case "mappings":
        return mappingView(activeTab);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const dragItemDetails = result.draggableId.split("#");

    var dropZoneDetails = destination.droppableId.split("#");

    if (dropZoneDetails[0] === "existing") {
      setEditState({
        ...editState,
        mappings: {
          ...editState.mappings,
          [dropZoneDetails[1]]: dragItemDetails[1],
        },
      });
    }
  };

  const setTabBarTag = (display) => {
    setDisplayTabBarTags(display);
  };

  const handleRemoveFile = (submission_id, file_id) => {
    const newSubmissions = submissions.filter((submission) => submission.id !== submission_id);

    if (submission_id === selectedAttachmentId) {
      const newId = newSubmissions.length ? newSubmissions[0].id : null;
      setSelectedAttachmentId(newId);
    }

    setSubmissions(newSubmissions);
    setFiles((files) => files.filter((file) => file.id !== file_id));
    fileInputRef.current.value = "";
  };

  const handleSelectAttachment = (submission_id) => {
    setSelectedAttachmentId(submission_id);
  };

  const renderHeaderButtons = () => {
    return (
      <div className="flex w--auto gap--8">
        {/* TODO: Add NDC List download functionality to this (↓) button  */}
        {/* <div className="filterbox__container filterbox__container--sm">
          <SaveAlt style={{ fontSize: 16 }} />
          <span className="t--500">NDC List</span>
        </div> */}
        <HelpDropdown setDisplaySubmissionTutorialIntro={setDisplaySubmissionTutorialIntro} />
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="content__container">
          <div className="page-details__container">
            <div className="page-details__container__info">
              <div className="pill pill--info pill--info--blue mb-2">{`${
                props.type == "encounter" ? "Pharmacy" : props.type
              } ${props.rebate ? "Eligibility Data" : "Claims"}`}</div>
              <div className="flex justify-between">
                <div className="page-details__title">{`Submit 340B ${
                  props.type == "encounter" ? "Pharmacy" : _.startCase(_.toLower(props.type))
                } ${props.rebate ? "Eligibility" : "Claims"} Data`}</div>
                {renderHeaderButtons()}
              </div>
              <div className="breadcrumbs__container">
                <div className="breadcrumbs__crumb">{`${
                  props.rebate ? "Eligibility" : "Claims"
                } Data >`}</div>
                <div className="breadcrumbs__crumb breadcrumbs__crumb__active">
                  New {props.type == "encounter" ? "Pharmacy Eligibility" : props.type} Submission
                </div>
              </div>
            </div>
          </div>
          <Notice details={notice} />
          <ColumnMappingNotice isVisible={!fieldMappingGroups.length} />
          <TabBar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            displayTagsFor={"mappings"}
            displayTabBarTags={displayTabBarTags}
            fileCount={files.length}
          />
          {renderTabViews()}
        </div>
        <div className="draw__container">
          <DataInput
            files={files}
            organization={props.organization}
            filterList={filterList}
            type={props.type}
            rebate={props.rebate}
            submissionPath={props.submissionPath}
            usedFields={usedFields}
            fieldMappingGroups={fieldMappingGroups}
            requiredShape={props.requiredShape}
            submissionData={submissions}
            handleFile={handleFile}
            handleRemoveFile={handleRemoveFile}
            ref={fileInputRef}
            mappingTabSelected={activeTab == "mappings"}
            selectedAttachmentId={selectedAttachmentId}
            handleSelectAttachment={handleSelectAttachment}
            headers={props.headers}
            handleSelectMapping={handleSelectMapping}
            mappings={fieldMappingGroups}
          />
        </div>
      </DragDropContext>
      <Tutorials
        mappings={fieldMappingGroups}
        setActiveTab={setActiveTab}
        displaySubmissionTutorialIntro={displaySubmissionTutorialIntro}
        setDisplaySubmissionTutorialIntro={setDisplaySubmissionTutorialIntro}
      />
    </div>
  );
};

export default DataSubmission;
