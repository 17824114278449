import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Illustration } from "../../images/svgs";


const UploadCofirmationModal = (props) => {
  const renderHeader = () => {
    return (
      <div className="modal__content__header flex item-start">
        <div className="flex flex-col item-start">
          <h3 className="t--lg"> {props.title} </h3>
          <p className="t--md t--gray">{props.subtitle}</p>
        </div>
        <div>
          <Illustration />
        </div>
      </div>
    );
  };

  const renderClose = () => {
    return (
      <div
        className="btn btn--sqr btn--sqr--modal"
        onClick={() => props.setDisplayModal(false)}
      >
        <CloseIcon className="t--sm" />
      </div>
    );
  };
  const renderBody = () => {
    return (
      <div className="flex flex-col item-start">
        <div className="modal__content__body">{props.body}</div>
      </div>
    );
  };

  const renderFooter = () => {
    return <div className="modal__content__footer p--0">{props.footer}</div>;
  };

  return (
    <div className="modal__background" data-testid="modal-continer">
      <div
        className="modal__container"
        style={{ width: props.width ? props.width : "680px" }}
      >
        <div className="modal__content modal__content--padded">
          {renderClose()}
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

export default UploadCofirmationModal;
