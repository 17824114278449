import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import {
  ExpandMore,
  GetApp,
  ChevronRight,
  UnfoldMore,
  Gavel,
  ExitToApp,
  Restore,
} from "@material-ui/icons";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import TableLoadingSkeleton from "../TableLoadingSkeleton";
import { TableRowSubComponent } from "./TableRowSubComponent";
import { IssuesToolTip } from "../../IssuesTooltip";
import { getIndicatorName } from "../utils";
import ConfirmationModal from "../../modals/ConfirmationModal";
import ReactSelect from "react-select";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import { Button } from "@material-ui/core";
import WithdrawClaimModal from "../../modals/WithdrawModal";
import { withdrawClaimReq, restoreClaimReq } from "./http";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "8px 0 8px",
  }),
  control: (base) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DEE7EE",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#ffffff",
    zIndex: 9999,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    backgroundColor: "#ffffff",
  }),
};

export default function ClaimsTable(props) {
  const tableContainerRef = useRef();

  const [sorting, setSorting] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [modalRow, setModalRow] = useState(null);
  const [row, setRow] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayRestoreModal, setDisplayRestoreModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openModal = (row) => {
    setModalRow(row);
    setSelectedOption(row.original.disputable[0]);
  }

  const openWithdrawModal = (row) => {
    setRow(row);
    setDisplayModal(true);
  }

  const openRestoreModal = (row) => {
    setRow(row);
    setDisplayRestoreModal(true);
  }

  const withdrawClaim = async (claim_id) => {
    try {
      setIsDeleting(true);
      await withdrawClaimReq(claim_id);
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeleting(false);
      setDisplayModal(false);
      props.refresh();
    }
  };

  const restoreClaim = async (claim_id) => {
    try {
      setIsLoading(true);
      await restoreClaimReq(claim_id);
    } catch (error) {
      console.error({ error });
    } finally {
      setIsLoading(false);
      setDisplayRestoreModal(false);
      props.refresh();
    }
  };


  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("expander", {
      enableSorting: false,
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <div
            className="btn btn--outline btn--align-end btn--white"
            {...{
              onClick: row.getToggleExpandedHandler(),
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              margin: 0,
              width: 30,
              height: 30,
            }}
          >
            <i
              className="solid solid-budicon-chevron-bottom"
              style={{
                fontWeight: 800,
                marginRight: 0,
                transform: row.getIsExpanded() ? `rotate(180deg)` : 0,
              }}
            />
          </div>
        ) : (
          ""
        );
      },
    }),

    columnHelper.accessor((row) => row.short_id, {
      header: "ESP ID",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.id_340b, {
      header: "340B ID",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.pharmacy, {
      header: "Pharmacy",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue().id}</div>,
    }),
    columnHelper.accessor((row) => row.product_name, {
      header: "Ndc",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="flex flex-col item-start">
            <div className="t--sm t--500 t--left">
              {getValue()}
            </div>
            <div className="t--sm">{row.original.ndc}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.quantity, {
      header: "Qty",
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.quality, {
      header: "Result",
      enableSorting: false,
      cell: ({ getValue }) => {
        return (
          <div className="badge">
            <div
              className={`badge__indicator ${
                getValue().toLowerCase() === "conforming"
                  ? "badge__indicator--green"
                  : "badge__indicator--red"
              }`}
            />
            {getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.status, {
      header: "Status",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="badge">
            <div
              className={`badge__indicator badge__indicator--${getIndicatorName(
                getValue()
              )}`}
            />
            {row.original.display_status}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.issues, {
      header: "Issues",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        if (_.size(getValue()) > 0) {
          return <IssuesToolTip row={row} getValue={getValue} />;
        }
        return "--";
      },
    }),
    columnHelper.accessor((row) => row.date_submitted, {
      header: "Submitted",
      cell: ({ renderValue, row }) => (
        <>
          <TableButtons
            row={row}
            hoveredRow={hoveredRow}
            openRestoreModal={openRestoreModal}
            openWithdrawModal={openWithdrawModal}
            openModal={openModal}
          />
          <div className="table__text__stack">
            <div className="t--sm t--500">{row.original.submitted_by}</div>
            <div className="t--sm t--subtitle">{renderValue()}</div>
          </div>
        </>
      ),
    }),
  ];

  const claims = useMemo(() => props.claims, [props.claims]);

  const table = useReactTable({
    data: claims,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    initialState: { pagination: { pageSize: 50 } },
    getPaginationRowModel: getPaginationRowModel(),
  });

  const renderPaginationControls = () => {
    return (
      <div
        className="flex__container"
        style={{ justifyContent: "flex-end", marginTop: 10 }}
      >
        <button
          className="btn btn--small"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Prev
        </button>
        <button
          className="btn btn--small"
          style={{ marginLeft: 10 }}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    );
  };

  const ModalDropDown = ({ row }) => {
    return (
      <div className="draw__item" style={{overflow: 'visible'}}>
        <div
          className="draw__item__title draw__item__title--mb-sm t--md"
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          Open a dispute for this issue
        </div>
          <ReactSelect
            id="issue"
            label="Issue"
            placeholder="Select Issue"
            name="issue"
            options={row.original.disputable}
            value={selectedOption}
            styles={customSelectStyles}
            onChange={(option) => setSelectedOption(option)}
          />

      </div>
    );
  };

  const renderRows = () => {
    if (props.isLoading) return <TableLoadingSkeleton rows={20} columns={10} />;

    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm" onMouseEnter={() => setHoveredRow(row.id)} onMouseLeave={() => setHoveredRow(null)}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>
                <TableRowSubComponent row={row} />
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <>
      <div ref={tableContainerRef}>
        <table className="primary">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sm">
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getCanSort() && (
                      <span
                        style={{
                          fontSize: 12,
                          display: "inline-flex",
                          verticalAlign: "top",
                        }}
                        onClick={() => header.column.toggleSorting()}
                      >
                        <UnfoldMore fontSize="inherit" color="inherit" />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>

        {renderPaginationControls()}
      </div>

      <ConfirmationModal
        body={
          <ModalDropDown
            row={modalRow}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        }
        displayModal={!(modalRow === null || modalRow === false)}
        setDisplayModal={setModalRow}
        setModalState={setModalRow}
        primaryAction={() => props.openDispute(modalRow.original.claim_id, selectedOption)}
        primaryLabel="Submit"
        secondaryAction={() => {
          setModalRow(null);
        }}
        secondaryLabel="Cancel"
        isDeleting={props.isLoading}
      />

      <WithdrawClaimModal
        displayModal={displayModal}
        primaryAction={() => withdrawClaim(row.original.claim_id)}
        title="Withdraw Claim"
        subtitle="By withdrawing this claim, it will no longer count towards your total claim counts or be associated with any outstanding reason codes."
        row={row}
        isDeleting={isDeleting}
        secondaryAction={() => setDisplayModal(false)}
      />

      <WithdrawClaimModal
        displayModal={displayRestoreModal}
        primaryAction={() => restoreClaim(row.original.claim_id)}
        title="Restore Claim"
        subtitle="By restoring this claim, it will once again count towards your total claim counts and be associcated with any outstanding reason codes."
        row={row}
        isDeleting={isLoading}
        secondaryAction={() => setDisplayRestoreModal(false)}
      />
    </>
  );
}

const TableButtons = ({ row, hoveredRow, openRestoreModal, openWithdrawModal, openModal }) => {
  if (hoveredRow !== row.id) return null;
  return (
    <div className="table__hover-buttons">
      <div className="flex__container" style={{gap: 5}} >
        {row.original.status === "withdrawn" ? (
          <ButtonWithTooltip
            buttonContent={<Restore fontSize="small"  />}
            buttonClasses="btn btn--sqr"
            tooltipText="Restore Claim"
            disabled={row.original.issues.length === 0}
            action={() => openRestoreModal(row)}
          />
        ) : (
          <ButtonWithTooltip
            buttonContent={<ExitToApp fontSize="small"  />}
            buttonClasses="btn btn--sqr"
            tooltipText="Withdraw Claim"
            disabled={row.original.issues.length === 0}
            action={() => openWithdrawModal(row)}
          />
        )}
        <ButtonWithTooltip
          buttonContent={<Gavel fontSize="small"  />}
          buttonClasses="btn btn--sqr"
          tooltipText="Open Dispute"
          disabled={row.original.disputable.length == 0}
          action={() => openModal(row)}
        />
      </div>
    </div>
  );
}