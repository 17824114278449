export const getIndicatorName = (name) => {
  var status = {
    paid: "green",
    expired: "red",
    "attempts exceeded": "red",
    withdrawn: "grey",
  };

  if (name.includes("days to conform")) {
    return "orange";
  }

  return status[name];
};