import React from "react";

const MoreInfoIcon = ({ top, left, tooltipHeader, tooltipContent, anchorPosition }) => {
  const getPosition = () => {
    const positions = anchorPosition.split("-");
    return {
      [positions[0]]: "16px",
      [positions[1]]: "-20px",
    };
  };

  const positionStyles = getPosition();

  return (
    <div
      className="tt__container"
      style={{ position: "absolute", top: top, left: left, cursor: "pointer" }}
    >
      <div className="tutorial_modal__info-icon"></div>
      <div
        className="tt"
        style={{
          padding: 16,
          width: 220,
          borderRadius: 8,
          marginTop: 16,
          marginLeft: 10,
          top: "unset",
          left: "unset",
          right: "unset",
          bottom: "unset",
          ...positionStyles,
        }}
      >
        <div className="tt__title t--500" style={{ marginBottom: 8 }}>
          {tooltipHeader}
        </div>
        <div className="tt__subtitle t--primary">{tooltipContent}</div>
      </div>
    </div>
  );
};

export default MoreInfoIcon;
