import React, { useEffect, useRef } from "react";

const Overlay = ({ display, parentRef }) => {
  if (!display) return null;

  const overlayRef = useRef();

  const updateOverlayPosition = () => {
    if (overlayRef.current && parentRef.current) {
      overlayRef.current.style.left = `${parentRef.current.scrollLeft}px`;
      overlayRef.current.style.top = `${parentRef.current.scrolltop}px`;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      updateOverlayPosition();
    };

    updateOverlayPosition();
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [parentRef]);

  return (
    <div className="overlay-loading" ref={overlayRef}>
      <div className="overlay-loading__contents">
        <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin" />
      </div>
    </div>
  );
};

export default Overlay;
