import React, { useState, useEffect } from "react";
import SecondaryModal from "./library/secondary_modal";
import { render } from "@testing-library/react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const SubmissionsModal = (props) => {
  const [displayModal, setDisplayModal] = useState(false);

  const getClassName = () => {
    if (props.buttonStyle === "primary")
      return "btn btn--ib btn--small btn--white btn--default-align";
    return "btn";
  };

  const renderButtonIcon = () => {
    if (props.buttonStyle !== "primary")
      return <i className="solid solid-budicon-doc-attachment" />;

    return null;
  };

  const modalBody = () => {
    return (
      <div className="item--flex item--flex-col pt-3 pb-3">
        {props.items.map((item) => (
          <a href={item.link}>
            <div
              className="link--card p-3 mb-0"
              key={item.title}
              style={props.style ?? {}}
            >
              {item.badge && (
                <div className="pill pill--info pill--info--blue pill--info--top-right">
                  {item.badge}
                </div>
              )}
              <div className="flex justify-between">
                <h3 className="t--lg t--primary">{item.heading}</h3>
              </div>
              <p className="t--sm t--subtitle mb-0">{item.content}</p>
            </div>
          </a>
        ))}
      </div>
    );
  };

  const modalFooter = () => {
    return <></>;
  };

  const modalBillboard = () => {
    return (
      <div>
        <div className="draw__submission_card--half-cir-shape-1 half-cir-shap-1--lg half-cir-shap--lg" />
        <div className="draw__submission_card--half-cir-shape-2 half-cir-shap-2--lg half-cir-shap--lg" />
        <div className="draw__submission_card--half-cir-shape-3 half-cir-shap-3--lg half-cir-shap--lg" />
        <div className="draw__submission_card--half-cir-shape-4 half-cir-shap-4--lg half-cir-shap--lg" />
        <div className="draw__submission_card--half-cir-shape-5 half-cir-shap-5--lg half-cir-shap--lg" />
      </div>
    );
  };

  const renderModal = () => {
    if (displayModal) {
      return (
        <SecondaryModal
          title={"Start a Submission"}
          subtitle={"Choose the type of submission you would like to make"}
          footer={modalFooter()}
          body={modalBody()}
          billboard={modalBillboard()}
          width="700px"
          showCloseIcon={true}
          setDisplayModal={setDisplayModal}
        />
      );
    }
  };

  return (
    <>
      <div
        className={getClassName()}
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        {renderButtonIcon()}
        Start a Submission
      </div>
      {renderModal()}
    </>
  );
};

export default SubmissionsModal;
