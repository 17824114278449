// Reads attached Claim Files and returns data object

import XLSX from "xlsx";
import { selectMapping, getMapping } from "./data_processing";
import Attachment from "../schemas/attachment";

const FileProcessor = async ({
  id,
  file,
  data = null,
  kind,
  filterList,
  filterKey,
  columnMappings,
  salt,
  selectedMapping = null,
}) => {
  var data = data;
  if (!data) {
    const ab = await file.data.arrayBuffer();
    const wb = XLSX.read(ab);
    const ws = wb.Sheets[wb.SheetNames[0]];

    data = XLSX.utils.sheet_to_json(ws, {
      raw: false,
      blankrows: false,
      defval: "",
    });
  }

  const mapping = selectedMapping || selectMapping(columnMappings, data);

  return new Attachment(id, file, kind, data, mapping, filterList, filterKey, salt);
};

export default FileProcessor;
