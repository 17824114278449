import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import RetailDesignationForm from "./RetailDesignationsForm";
import EntyvioDesignationForm from "./EntyvioDesignationsForm";
import IclusigDesignationForm from "./IclusigDesignationsForm";
import AlunbrigDesignationForm from "./AlunbrigDesignationsForm";
import FruzaqlaDesignationForm from "./FruzaqlaDesignationsForm";
import NinlaroDesignationForm from "./NinlaroDesignationsForm";

import Takeda from "~/images/Takeda.png";

const TakedaDesignations = (props) => {
  const manufacturer_name = "takeda";
  const vanity_manufacturer_name = "Takeda";
  const [retailHasSelectedSpecialtyPharmacy, setRetailHasSelectedSpecialtyPharmacy] = useState(false);
  const [retailSelectedSpecialtyPharmacy, setRetailSelectedSpecialtyPharmacy] = useState();
  const [manufacturerFormDetails, setManufacturerFormDetails] = useState({
    takeda: {},
  });

  useEffect(() => {
    props.setCompletedDesignations(isComplete());
  }, [manufacturerFormDetails]);

  const isComplete = () => {
    var completed = true;

    var openForms = _.map(manufacturerFormDetails, (manufacturer) => {
      return _.filter(manufacturer, (form) => {
        return form.open;
      });
    });

    // remove empty arrays and flatten
    var filteredForms = openForms.filter((e) => e.length).flat();

    if (filteredForms.length) {
      completed = _.every(filteredForms, ["completed", true]);
    }

    return completed;
  };

  const renderRetail = () => {
    return (
      <RetailDesignationForm
        entities={props.entities}
        kind={"retail"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
      />
    );
  };

  const renderSpecialty = () => {
    return (
      <EntyvioDesignationForm
        entities={props.entities}
        kind={"entyvio_pen"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("entyvio_pen"))}
      />
    );
  };

  const renderIclusig = () => {
    return (
      <IclusigDesignationForm
        entities={props.entities}
        kind={"iclusig"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("iclusig"))}
      />
    );
  };

  const renderAlunbrig = () => {
    return (
      <AlunbrigDesignationForm
        entities={props.entities}
        kind={"alunbrig"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("alunbrig"))}
      />
    );
  };

  const renderFruzaqla = () => {
    return (
      <FruzaqlaDesignationForm
        entities={props.entities}
        kind={"fruzaqla"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("fruzaqla"))}
      />
    );
  };

  const renderNinlaro = () => {
    return (
      <NinlaroDesignationForm
        entities={props.entities}
        kind={"ninlaro"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("ninlaro"))}
      />
    );
  };

  return (
    <div>
      <div className="designation_form__section__header">
        <div className="designation_form__section__header__logo">
          <img src={Takeda} />
        </div>
        <div className="designation_form__section__header__title">{vanity_manufacturer_name}</div>
      </div>
      {renderRetail()}
      {renderSpecialty()}
      {renderIclusig()}
      {renderAlunbrig()}
      {renderFruzaqla()}
      {renderNinlaro()}
    </div>
  );
};

export default TakedaDesignations;
